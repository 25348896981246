.main-title {
	position: relative;
	margin: 0;
	margin-bottom: 1.5rem;
	padding-left: 12px;

	&::before {
		content: '';
		position: absolute;
		top: 0.1em;
		left: 0;
		width: 0.375rem;
		height: 1.45em;
		background-color: $dark;
		transform: translateX(-50%);
	}

	&.color-subtitle::before {
		background-color: $gray-400;
	}

	.form_error {
		margin-left: 0.5rem;
	}

	.indent-box & {
		&::before {
			background-color: $gray-400;
		}
	}
}

.badge {
	position: relative;
	display: inline-block;
	margin-left: 0.5rem;
	padding: 0.25rem 0.625rem;
	font-size: 0.875rem;
	line-height: inherit;
	color: $white;
	background-color: $gray-500;

	@include bp-lg {
		top: calc(0.75em - 1rem);
		font-size: 1rem;
	}

	&--outline {
		padding: 0.2rem 0.625rem;
		font-size: remy(12);
		font-weight: 400;
		color: $gray-200;
		background-color: transparent;
		border: 1.5px solid $gray-200;
		border-radius: 12px;

		@include bp-md {
			font-size: remy(14);
		}
	}
}

.title {
	&-deco-blockafter {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: calc(100% + 0.75rem);
			left: 50%;
			width: remy(40);
			height: 0.25rem;
			background-color: $primary;
			transform: translateX(-50%);
		}

		&--clr-dark {
			&::after {
				background-color: $dark;
			}
		}
	}
}

.fz-span {
	font-size: remy(12);

	@include bp-md {
		font-size: remy(16);
	}
}

.fw-medium {
	font-weight: 500;
}

.normal-title {
	@include bp-lg {
		font-size: 1.125rem;
	}
}
