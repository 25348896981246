.toast {
	$root: &;

	position: fixed;
	z-index: -1;
	inset: 0;
	display: block;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: 0.3s opacity, 0.3s z-index 0s;
	pointer-events: none;

	// open / close
	&.is-active {
		z-index: 9999;
		opacity: 1;
		transition: 0.3s opacity;
		pointer-events: all;

		#{$root}_inner {
			transform: translate(-50%, -50%) translateZ(0) scale(1);
		}
	}

	// Inner
	&_inner {
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;
		padding: 1.5rem 2rem;
		width: calc(100% - 80px);
		max-width: 23rem;
		text-align: center;
		background-color: $white;
		border-radius: 0.125rem;
		transform: translate(-50%, -50%) translateZ(0) scale(0.95);
		transition: transform $ms-fast;
	}

	// Cont
	&_icon {
		display: inline-flex;
		font-size: 2.5rem;

		@include bp-md {
			font-size: 3.75rem;
		}
	}

	&_title {
		margin-top: 1rem;
	}

	&_note {
		color: $gray-500;
	}

	// Overlay
	&_overlay {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($black, 0.6);
	}
}
