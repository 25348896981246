.embed {
	position: relative;
	z-index: 1;
	display: block;
	overflow: hidden;
	padding: 0;
	width: 100%;
	background-color: inherit;

	&::before {
		content: '';
		position: relative;
		z-index: 1;
		display: block;
		padding-top: 56.25%;
		width: 100%;
	}

	&_txt {
		position: absolute;
		z-index: 10;
		bottom: 0;
		left: 0;
		padding: 8px;
		width: 100%;
		font-size: remy(14);
		line-height: (16 / 12);
	}

	&_media {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border: 0;
	}

	iframe {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}

	&.mask {
		&::after {
			content: '';
			position: absolute;
			z-index: 5;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(180deg, rgba(#3c3a3a, 0) 60%, rgba($black, 0.54) 100%);
			opacity: 0;
			transition: 0.45s cubic-bezier(0.39, 0.575, 0.565, 1);
			transition-property: transform, opacity;
			pointer-events: none;
		}
	}

	&.hover {
		cursor: pointer;

		.embed_media {
			transition: transform 0.45s cubic-bezier(0.39, 0.575, 0.565, 1);
		}

		&:hover {
			&.mask {
				&::after {
					opacity: 1;
				}
			}

			.embed_media {
				transform: scale(1.1);
			}
		}
	}

	@include bp-md {
		&_txt {
			padding: 16px;
			font-size: 16px;
		}
	}
}

.picture {
	.img {
		display: block;
		width: 100%;
	}
}
