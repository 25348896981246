@mixin font-icon {
	@font-face {
		font-family: "icon";
		src: url('fonts/icons/icon.eot');
		src: url('fonts/icons/icon.eot?#iefix') format('embedded-opentype'),
		url('fonts/icons/icon.woff2') format('woff2'),
		url('fonts/icons/icon.woff') format('woff'),
		url('fonts/icons/icon.ttf') format('truetype'),
		url('fonts/icons/icon.svg#icon') format('svg');
		font-display: block;
		unicode-range: U+EA01-EA29;
	}

	.be-icon {
		font-family: sans-serif;
		line-height: 1;
	}

	.be-icon:before {
		@include font-icon-style;
	}
	
	/* Glyphs list */
	
	.be-icon-alert:before {
		content: '\ea01';
	}
	
	.be-icon-arrow-next:before {
		content: '\ea02';
	}
	
	.be-icon-arrow-prev:before {
		content: '\ea03';
	}
	
	.be-icon-asterisk:before {
		content: '\ea04';
	}
	
	.be-icon-book:before {
		content: '\ea05';
	}
	
	.be-icon-calc:before {
		content: '\ea06';
	}
	
	.be-icon-calendar:before {
		content: '\ea07';
	}
	
	.be-icon-chat:before {
		content: '\ea08';
	}
	
	.be-icon-checkbox:before {
		content: '\ea09';
	}
	
	.be-icon-checked-circle:before {
		content: '\ea0a';
	}
	
	.be-icon-checked:before {
		content: '\ea0b';
	}
	
	.be-icon-close:before {
		content: '\ea0c';
	}
	
	.be-icon-doc:before {
		content: '\ea0d';
	}
	
	.be-icon-document:before {
		content: '\ea0e';
	}
	
	.be-icon-down:before {
		content: '\ea0f';
	}
	
	.be-icon-download:before {
		content: '\ea10';
	}
	
	.be-icon-ellipsis:before {
		content: '\ea11';
	}
	
	.be-icon-eye-close:before {
		content: '\ea12';
	}
	
	.be-icon-eye-open:before {
		content: '\ea13';
	}
	
	.be-icon-filter:before {
		content: '\ea14';
	}
	
	.be-icon-language:before {
		content: '\ea15';
	}
	
	.be-icon-link:before {
		content: '\ea16';
	}
	
	.be-icon-lock-success:before {
		content: '\ea17';
	}
	
	.be-icon-lock:before {
		content: '\ea18';
	}
	
	.be-icon-login:before {
		content: '\ea19';
	}
	
	.be-icon-member-verified:before {
		content: '\ea1a';
	}
	
	.be-icon-minus:before {
		content: '\ea1b';
	}
	
	.be-icon-next-arrow:before {
		content: '\ea1c';
	}
	
	.be-icon-next:before {
		content: '\ea1d';
	}
	
	.be-icon-overtime:before {
		content: '\ea1e';
	}
	
	.be-icon-pdf:before {
		content: '\ea1f';
	}
	
	.be-icon-plus:before {
		content: '\ea20';
	}
	
	.be-icon-prev:before {
		content: '\ea21';
	}
	
	.be-icon-register:before {
		content: '\ea22';
	}
	
	.be-icon-repatriation:before {
		content: '\ea23';
	}
	
	.be-icon-retire:before {
		content: '\ea24';
	}
	
	.be-icon-tool:before {
		content: '\ea25';
	}
	
	.be-icon-up:before {
		content: '\ea26';
	}
	
	.be-icon-vacation:before {
		content: '\ea27';
	}
	
	.be-icon-vote:before {
		content: '\ea28';
	}
	
	.be-icon-xls:before {
		content: '\ea29';
	}
	
}

// <i>:before Style
@mixin font-icon-style {
	font-family: "icon";
	
	// Font smoothing. That was taken from TWBS
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-style: normal;
	font-weight: normal;

	// For safety - reset parent styles, that can break glyph codes
	font-variant: normal;
	text-transform: none;
	text-decoration: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	// only necessary if not using the private unicode range (firstGlyph option)
	speak: none;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	// margin-right: .2em;
	text-align: center;

	// fix buttons height, for twitter bootstrap
	line-height: 1em;

	// Animation center compensation - margins should be symmetric
	// remove if not needed
	// margin-left: .2em;

	// you can be more comfortable with increased icons size
	// font-size: 120%;

	// Uncomment for 3D effect
	// text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}

// @include font-icon;

// SASS變數使用範例
// .icon {
//     &:before{
// 		@include font-icon-add(SVG檔案名稱);
//     }
// }

// 內頁使用之Style
%font-icon-style {
	@include font-icon-style;
}


$font-icon-alert: '\ea01';

$font-icon-arrow-next: '\ea02';

$font-icon-arrow-prev: '\ea03';

$font-icon-asterisk: '\ea04';

$font-icon-book: '\ea05';

$font-icon-calc: '\ea06';

$font-icon-calendar: '\ea07';

$font-icon-chat: '\ea08';

$font-icon-checkbox: '\ea09';

$font-icon-checked-circle: '\ea0a';

$font-icon-checked: '\ea0b';

$font-icon-close: '\ea0c';

$font-icon-doc: '\ea0d';

$font-icon-document: '\ea0e';

$font-icon-down: '\ea0f';

$font-icon-download: '\ea10';

$font-icon-ellipsis: '\ea11';

$font-icon-eye-close: '\ea12';

$font-icon-eye-open: '\ea13';

$font-icon-filter: '\ea14';

$font-icon-language: '\ea15';

$font-icon-link: '\ea16';

$font-icon-lock-success: '\ea17';

$font-icon-lock: '\ea18';

$font-icon-login: '\ea19';

$font-icon-member-verified: '\ea1a';

$font-icon-minus: '\ea1b';

$font-icon-next-arrow: '\ea1c';

$font-icon-next: '\ea1d';

$font-icon-overtime: '\ea1e';

$font-icon-pdf: '\ea1f';

$font-icon-plus: '\ea20';

$font-icon-prev: '\ea21';

$font-icon-register: '\ea22';

$font-icon-repatriation: '\ea23';

$font-icon-retire: '\ea24';

$font-icon-tool: '\ea25';

$font-icon-up: '\ea26';

$font-icon-vacation: '\ea27';

$font-icon-vote: '\ea28';

$font-icon-xls: '\ea29';


// 可以帶入變數
@mixin font-icon-add($icon: '', $extendStyle: true) {
	
	@if $icon == alert {
		content: '\ea01';
	}
	
	@if $icon == arrow-next {
		content: '\ea02';
	}
	
	@if $icon == arrow-prev {
		content: '\ea03';
	}
	
	@if $icon == asterisk {
		content: '\ea04';
	}
	
	@if $icon == book {
		content: '\ea05';
	}
	
	@if $icon == calc {
		content: '\ea06';
	}
	
	@if $icon == calendar {
		content: '\ea07';
	}
	
	@if $icon == chat {
		content: '\ea08';
	}
	
	@if $icon == checkbox {
		content: '\ea09';
	}
	
	@if $icon == checked-circle {
		content: '\ea0a';
	}
	
	@if $icon == checked {
		content: '\ea0b';
	}
	
	@if $icon == close {
		content: '\ea0c';
	}
	
	@if $icon == doc {
		content: '\ea0d';
	}
	
	@if $icon == document {
		content: '\ea0e';
	}
	
	@if $icon == down {
		content: '\ea0f';
	}
	
	@if $icon == download {
		content: '\ea10';
	}
	
	@if $icon == ellipsis {
		content: '\ea11';
	}
	
	@if $icon == eye-close {
		content: '\ea12';
	}
	
	@if $icon == eye-open {
		content: '\ea13';
	}
	
	@if $icon == filter {
		content: '\ea14';
	}
	
	@if $icon == language {
		content: '\ea15';
	}
	
	@if $icon == link {
		content: '\ea16';
	}
	
	@if $icon == lock-success {
		content: '\ea17';
	}
	
	@if $icon == lock {
		content: '\ea18';
	}
	
	@if $icon == login {
		content: '\ea19';
	}
	
	@if $icon == member-verified {
		content: '\ea1a';
	}
	
	@if $icon == minus {
		content: '\ea1b';
	}
	
	@if $icon == next-arrow {
		content: '\ea1c';
	}
	
	@if $icon == next {
		content: '\ea1d';
	}
	
	@if $icon == overtime {
		content: '\ea1e';
	}
	
	@if $icon == pdf {
		content: '\ea1f';
	}
	
	@if $icon == plus {
		content: '\ea20';
	}
	
	@if $icon == prev {
		content: '\ea21';
	}
	
	@if $icon == register {
		content: '\ea22';
	}
	
	@if $icon == repatriation {
		content: '\ea23';
	}
	
	@if $icon == retire {
		content: '\ea24';
	}
	
	@if $icon == tool {
		content: '\ea25';
	}
	
	@if $icon == up {
		content: '\ea26';
	}
	
	@if $icon == vacation {
		content: '\ea27';
	}
	
	@if $icon == vote {
		content: '\ea28';
	}
	
	@if $icon == xls {
		content: '\ea29';
	}
	

	@if $extendStyle == true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}