.pagination {
	&-nav {
		margin-top: remy(36);
	}

	&-ul {
		align-items: center;
		justify-content: center;
		padding: 0;
	}

	&-item {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 remy(3);
		padding: remy(4) remy(8);
		width: remy(30);
		height: remy(30);
		border: remy(2) solid transparent;
		transition: $ms-fast;
		cursor: pointer;

		@include bp-md {
			margin: 0 remy(5);
			width: remy(40);
			height: remy(40);
		}

		&:hover {
			border: remy(2) solid $gray-500;
			border-radius: remy(2);

			.pagination-link {
				color: $gray-400;
			}

			&.next,
			&.previous {
				border: remy(2) solid transparent;
			}
		}

		&.next,
		&.previous {
			.pagination-link {
				color: $dark;
			}
		}

		&.next {
			margin-left: remy(5);

			@include bp-md {
				margin-left: remy(22);
			}
		}

		&.previous {
			margin-right: remy(5);

			@include bp-md {
				margin-right: remy(22);
			}
		}

		&.current {
			position: relative;
			background: $dark;
			border-radius: remy(2);
			pointer-events: none;

			&::before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				width: remy(20);
				height: remy(2);
				transform: translateX(-50%);
			}

			.pagination-link {
				color: $white;
			}
		}

		&.disabled {
			pointer-events: none;
			background: none;

			.pagination-link {
				opacity: 0.5;
			}
		}

		&.ellipsis {
			pointer-events: none;
		}

		.be-icon {
			font-size: remy(30);

			@include bp-md {
				font-size: remy(40);
			}
		}

		.be-icon-ellipsis {
			color: $gray-500;
		}
	}

	&-link {
		font-size: remy(18);
		font-family: 'Noto Serif HK', serif;
		font-weight: 600;
		color: $gray-400;
		transition: 0.5s;

		@include bp-md {
			font-size: remy(22);
		}
	}
}
