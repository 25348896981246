// CSS3
$vendors: -webkit-, -moz-, -ms-, '';

@mixin appearance($value: none) {
	$vendors: '-webkit-', '';

	@include _prefix(appearance, $value, $vendors);
}

@mixin display($value: block) {
	$vendors: '';

	@if $value == grid or $value == inline-grid {
		$vendors: -ms-, '';
	}

	@include _prefix(display, $value, $vendors, value);
}

@mixin mask($value: none) {
	$vendors: -webkit-, '';

	@include _prefix(mask, $value, $vendors);
}

@mixin placeholder() {
	::placeholder {
		@content;
	}
}

@mixin text-align-last($value: auto) {
	$vendors: -moz-, '';

	@include _prefix(text-align-last, $value, $vendors);
}

@mixin text-combine-upright($value: auto) {
	$vendors: -webkit-, -ms-, '';

	@include _prefix(text-combine-upright, $value, $vendors);
}

@mixin text-emphasis-position($value: over right) {
	$vendors: -webkit-, '';

	@include _prefix(text-emphasis-position, $value, $vendors);
}

@mixin text-fill-color($value: transparent) {
	$vendors: -webkit-;

	@include _prefix(text-fill-color, $value, $vendors);
}

@mixin text-orientation($value: mixed) {
	$vendors: -webkit-, '';

	@include _prefix(text-orientation, $value, $vendors);
}

@mixin tab-size($value: auto) {
	$vendors: -moz-, '';

	@include _prefix(tab-size, $value, $vendors);
}

// ------------------
// Mixins Global
@mixin _prefix($prop, $values, $vendors, $pos: prop) {
	@each $prefix in $vendors {
		@if $pos == prop {
			#{$prefix}#{$prop}: $values;
		} @else if $pos == value {
			#{$prop}: #{$prefix}#{$values};
		}
	}
}

@mixin align($direction: h, $value: left) {
	@if $direction == h {
		text-align: $value;
	} @else if $direction == v {
		vertical-align: $value;
	}
}

@mixin visible($bool: true) {
	@if $bool == true {
		visibility: visible !important;
	} @else if $bool == false {
		visibility: hidden !important;
	}
}

@mixin pull($direction: left) {
	@if $direction == left {
		float: left;
	} @else if $direction == right {
		float: right;
	}
}

@mixin placeholder-color($color) {
	&::placeholder {
		color: $color;
	}
}

// ------------------

// Box
@mixin center-block {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

@mixin center-both {
	position: absolute;
	top: 50%;
	left: 50%;

	@include transform(translate(-50.1%, -50.1%));
}

@mixin size($width, $height: $width) {
	width: $width;
	height: $height;
}

@mixin max-size($width, $height: $width) {
	max-width: $width;
	max-height: $height;
}

@mixin min-size($width, $height: $width) {
	min-width: $width;
	min-height: $height;
}

@mixin width-range($min, $max) {
	min-width: $min;
	max-width: $max;
}

@mixin circle($size) {
	@include size($size);
	@include border-radius(50%);
}

@mixin paddings($top: 0, $right: $top, $bottom: $top, $left: $right) {
	@if  $top != null and $right != null and $bottom != null and $left != null {
		$v: if($top == $bottom, true, false);
		$h: if($left == $right, true, false);

		@if  $v and $h and $top == $right {
			padding: $top;
		} @else if $v and $h {
			padding: $top $right;
		} @else if not $v and $h {
			padding: $top $right $bottom;
		} @else {
			padding: $top $right $bottom $left;
		}
	} @else {
		@if  $top != null {
			padding-top: $top;
		}

		@if  $right != null {
			padding-right: $right;
		}

		@if  $bottom != null {
			padding-bottom: $bottom;
		}

		@if  $left != null {
			padding-left: $left;
		}
	}
}

@mixin margins($top: 0, $right: $top, $bottom: $top, $left: $right) {
	@if  $top != null and $right != null and $bottom != null and $left != null {
		$v: if($top == $bottom, true, false);
		$h: if($left == $right, true, false);

		@if  $v and $h and $top == $right {
			margin: $top;
		} @else if $v and $h {
			margin: $top $right;
		} @else if not $v and $h {
			margin: $top $right $bottom;
		} @else {
			margin: $top $right $bottom $left;
		}
	} @else {
		@if  $top != null {
			margin-top: $top;
		}

		@if  $right != null {
			margin-right: $right;
		}

		@if  $bottom != null {
			margin-bottom: $bottom;
		}

		@if  $left != null {
			margin-left: $left;
		}
	}
}

// ------------------

// Typography
@mixin selection($text-color: $white, $background-color: $primary) {
	@at-root {
		#{&}::-moz-selection {
			color: $text-color;
			background-color: $background-color;
			@content;
		}

		#{&}::selection {
			color: $text-color;
			background-color: $background-color;
			@content;
		}
	}
}

// @mixin font-by-language($lang: en) {
// 	$langs: (en, zh, ja, ko);

// 	@if index($langs, $lang) {
// 		@at-root {
// 			#{&}:lang(#{$lang}) {
// 			}
// 		}
// 	}
// }

@mixin hide-text {
	overflow: hidden;
	color: transparent;
	background-color: transparent;
	border: none;
	text-shadow: none;
}

@mixin text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin font-smoothing {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizelegibility;
}

// ------------------

// Breakpoints
// ------------------
@mixin bp($bp: xs) {
	@if  $bp == xs {
		@media (max-width: $screen-xs-max) {
			@content;
		}
	} @else {
		@media (min-width: map-get($breakpoints, $bp)) {
			@content;
		}
	}
}

@mixin bp-xs {
	@include bp(xs) {
		@content;
	}
}

@mixin bp-sm {
	@include bp(sm) {
		@content;
	}
}

@mixin bp-md {
	@include bp(md) {
		@content;
	}
}

@mixin bp-lg {
	@media (min-width: map-get($breakpoints, 'lg')) {
		@content;
	}
}

@mixin bp-xl {
	@include bp(xl) {
		@content;
	}
}

@mixin bp-max {
	@include bp(max) {
		@content;
	}
}

// ------------------

// Background
@mixin full-background-image($position: center, $sizing: cover, $color: black(0.05)) {
	background: {
		repeat: no-repeat;
		position: $position;
		color: $color;
		size: $sizing;
	}
}

@mixin responsive-image($height: percentage(9 / 16), $position: center, $sizing: cover, $color: black(0.05)) {
	padding-top: $height;

	@include fullBgImage($position, $sizing, $color);
}

// ------------------

// Border Radius
@mixin border-top-radius($value: 0) {
	border-top-left-radius: $value;
	border-top-right-radius: $value;
}

@mixin border-bottom-radius($value: 0) {
	border-bottom-left-radius: $value;
	border-bottom-right-radius: $value;
}

@mixin border-left-radius($value: 0) {
	border-top-left-radius: $value;
	border-bottom-left-radius: $value;
}

@mixin border-right-radius($value: 0) {
	border-top-right-radius: $value;
	border-bottom-right-radius: $value;
}

@mixin border-backslash-radius($value: 0) {
	border-top-left-radius: $value;
	border-bottom-right-radius: $value;
}

@mixin border-slash-radius($value: 0) {
	border-top-right-radius: $value;
	border-bottom-left-radius: $value;
}

// ------------------

// ------------------

// Status
@mixin hover {
	&:hover,
	&.is\:hover {
		@content;
	}
}

@mixin focus {
	&:focus,
	&.is\:focus {
		@content;
	}
}

@mixin focus\:hover {
	&:focus,
	&.is\:focus,
	&:focus:hover,
	&.is\:focus:hover {
		@content;
	}
}

@mixin active {
	&:active,
	&.is\:on {
		@content;
	}
}

@mixin active\:hover {
	&:active,
	&.is\:on,
	&:active:hover,
	&.is\:on:hover {
		@content;
	}
}

@mixin hover-focus {
	&:hover,
	&:focus,
	&.is\:focus {
		@content;
	}
}

@mixin hover-focus\:hover {
	&:hover,
	&:focus,
	&.is\:focus,
	&:focus:hover,
	&.is\:focus:hover {
		@content;
	}
}

@mixin hover-active {
	&:hover,
	&:active,
	&.is\:on {
		@content;
	}
}

@mixin hover-active\:hover {
	&:hover,
	&:active,
	&.is\:on,
	&:active:hover,
	&.is\:on:hover {
		@content;
	}
}

@mixin focus-active {
	&:focus,
	&.is\:focus,
	&:active,
	&.is\:on {
		@content;
	}
}

@mixin hover-focus-active {
	&:hover,
	&:focus,
	&.is\:focus,
	&:active,
	&.is\:on {
		@content;
	}
}

@mixin disable {
	&[disabled],
	&.is\:off {
		@content;
	}
}

// ------------------

// Others
@mixin opacity($opacity) {
	$opacity-ie: $opacity * 100;

	opacity: $opacity;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity-ie})';
	filter: alpha(opacity=$opacity-ie);
}

@mixin resize($value) {
	overflow: auto;
	resize: $value;
}

@mixin text-glow($color: light, $extend: true, $opacity: $opacity-lv5) {
	@if  $extend == true {
		@extend %text-glow-#{$color};
	} @else {
		text-shadow: 0 0 remy(6) rgba($color, $opacity);
	}
}

@mixin position($method: absolute, $top: 0, $left: 0, $bottom: null, $right: null) {
	position: $method;
	top: if($bottom != null, auto, $top);
	left: if($right != null, auto, $left);

	@if  $bottom != null {
		bottom: $bottom;
	}

	@if  $right != null {
		right: $right;
	}
}

@mixin first($t: false, $r: false, $b: false, $l: false) {
	&:first-child {
		@if $t {
			margin-top: 0;
		}

		@if $r {
			margin-right: 0;
		}

		@if $b {
			margin-bottom: 0;
		}

		@if $l {
			margin-left: 0;
		}

		@content;
	}
}

@mixin last($t: false, $r: false, $b: false, $l: false) {
	&:last-child {
		@if $t {
			margin-top: 0;
		}

		@if $r {
			margin-right: 0;
		}

		@if $b {
			margin-bottom: 0;
		}

		@if $l {
			margin-left: 0;
		}

		@content;
	}
}

@mixin head-tail {
	&:first-child,
	&:last-child {
		@content;
	}
}

@mixin only {
	&:only-child {
		@content;
	}
}

@mixin before($content: null) {
	&::before {
		@if $content {
			content: $content;
		}
		@content;
	}
}

@mixin after($content: null) {
	&::after {
		@if $content {
			content: $content;
		}
		@content;
	}
}

@mixin before-after($content: null) {
	&::before,
	&::after {
		@if $content {
			content: $content;
		}
		@content;
	}
}

@mixin empty {
	&:empty {
		@content;
	}
}

@mixin head-tail-clear($direction: ttb) {
	@if $direction == ttb {
		@include first($t: true);
		@include last($b: true);
	} @else if btt {
		@include first($b: true);
		@include last($t: true);
	} @else if ltr {
		@include first($l: true);
		@include last($r: true);
	} @else if rtl {
		@include first($r: true);
		@include last($l: true);
	}
}

@mixin nth($eq: 1) {
	&:nth-child(#{$eq}) {
		@content;
	}
}

// ------------------

// Extension
@mixin clearfix {
	@include after('') {
		display: table;
		clear: both;
	}
}

@mixin disabled {
	background: $white;

	// opacity: $opacity-lv3;
	cursor: default;
	pointer-events: none;

	// opacity: $opacity-lv3;
	// background: $white;
}

@mixin u-display-maker($bp: '') {
	$bp: if(str-length($bp) > 0, '\\@' + $bp, $bp);

	.u {
		$u: &;
		$dpy: #{$u}-dsp#{$bp};

		@at-root {
			table#{$dpy} {
				display: table !important;
			}

			thead#{$dpy} {
				display: table-header-group !important;
			}

			tbody#{$dpy} {
				display: table-row-group !important;
			}

			tfoot#{$dpy} {
				display: table-footer-group !important;
			}

			caption#{$dpy} {
				display: table-caption !important;
			}

			tr#{$dpy} {
				display: table-row !important;
			}

			th#{$dpy},
			td#{$dpy} {
				display: table-cell !important;
			}

			colgroup#{$dpy} {
				display: table-column-group !important;
			}

			col#{$dpy} {
				display: table-column !important;
			}

			li#{$dpy} {
				display: list-item !important;
			}

			#{$dpy} {
				display: block !important;

				&\:it {
					display: inline-table !important;
				}

				&\:t {
					display: table !important;
				}

				&\:tc {
					display: table-cell !important;
				}

				&\:tr {
					display: table-row !important;
				}

				&\:i {
					display: inline !important;
				}

				&\:ib {
					display: inline-block !important;
				}

				&\:if {
					display: inline-flex !important;
				}

				&\:f {
					display: flex !important;
				}

				&\:ig {
					display: inline-grid !important;
				}

				&\:g {
					display: grid !important;
				}

				&\:b {
					display: block !important;
				}

				&\:n {
					display: none !important;
				}
			}
		}
	}
}

// ------------------

// Align
@mixin u-align-maker($bp: '') {
	$bp: if(str-length($bp) > 0, '\\@' + $bp, $bp);

	.u {
		$u: &;
		$name: #{$u}-align#{$bp};
		$values: (
			l: left,
			c: center,
			r: right,
		) !default;

		@at-root {
			@each $key, $value in $values {
				#{$name}\:#{$key} {
					text-align: $value;
				}
			}
		}
	}
}

// ------------------

// Flex Mixins
@mixin flex-maker($bp: '') {
	$bp: if(str-length($bp) > 0, '\\@' + $bp, $bp);

	.container {
		max-width: map-get($container-max-widths-percent, $bp);
	}

	.f-col {
		&#{$bp} {
			flex-basis: 0;
		}

		&#{$bp}\:auto {
			flex-basis: auto;
		}

		@for $i from 1 through $grid-columns {
			$r: percentage($i / $grid-columns);

			&#{$bp}\:#{$i} {
				flex-basis: $r;
				max-width: $r;
			}
		}
	}

	.f-offset#{$bp} {
		@for $i from 0 through $grid-columns {
			$r: percentage($i / $grid-columns);

			&\:#{$i} {
				margin-left: $r;
			}
		}
	}

	.f-order#{$bp} {
		@for $i from -1 through ($grid-columns + 1) {
			&\:#{$i} {
				order: $i;
			}
		}
	}

	.f-flex#{$bp} {
		&\:fluid {
			flex-grow: 1;
			flex-shrink: 1;
		}

		&\:fixed {
			flex-grow: 0;
			flex-shrink: 0;
		}
	}

	.f-direction#{$bp} {
		&\:r {
			flex-direction: row !important;
		}

		&\:rr {
			flex-direction: row-reverse !important;
		}

		&\:c {
			flex-direction: column !important;
		}

		&\:cr {
			flex-direction: column-reverse !important;
		}
	}

	.f-wrap#{$bp} {
		&\:nw {
			flex-wrap: nowrap !important;
		}

		&\:w {
			flex-wrap: wrap !important;
		}

		&\:r {
			flex-wrap: revert !important;
		}

		&\:wr {
			flex-wrap: wrap-reverse !important;
		}
	}

	.f-row {
		&.f-nogap#{$bp} {
			@include margins(null, 0);

			> [class*='f-col'] {
				@include paddings(null, 0);
			}
		}
	}

	[class*='f-col'] {
		&.f-nogap#{$bp} {
			@include paddings(null, 0);
		}
	}

	.f-align#{$bp} {
		&\:l {
			justify-content: flex-start !important;
		}

		&\:c {
			justify-content: center !important;
		}

		&\:r {
			justify-content: flex-end !important;
		}

		&\:j {
			justify-content: space-between !important;
		}

		&\:a {
			justify-content: space-around !important;
		}

		&\:e {
			justify-content: space-evenly !important;
		}

		&\:t {
			align-items: flex-start !important;
		}

		&\:m {
			align-items: center !important;
		}

		&\:b {
			align-items: flex-end !important;
		}

		&\:s {
			align-items: stretch !important;
		}
	}

	.f-col-align#{$bp} {
		&\:t {
			align-self: flex-start !important;
		}

		&\:m {
			align-self: center !important;
		}

		&\:b {
			align-self: flex-end !important;
		}

		&\:s {
			align-self: stretch !important;
		}
	}
}

@mixin u-text-wrap-maker($bp: '') {
	$bp: if(str-length($bp) > 0, '\\@' + $bp, $bp);

	.u {
		$u: &;
		$name: #{$u}-text-wrap#{$bp};
		$values: (
			nm: normal,
			nw: nowrap,
			p: pre,
			pl: pre-line,
			pw: pre-wrap,
		) !default;

		@at-root {
			@each $key, $value in $values {
				#{$name}\:#{$key} {
					white-space: $value;
				}
			}
		}
	}
}

@mixin object-fit($fit: cover, $pos: center) {
	width: 100%;
	height: 100%;
	object-fit: $fit;
	object-position: $pos;
}

// ------------------

// Headline
@mixin SerifFont {
	font-family: 'Noto Serif HK', serif;
}

@mixin body1 {
	font-size: remy(16);
	font-weight: 400;
	line-height: (24 / 16);
}

@mixin body2 {
	font-size: remy(14);
	font-weight: 400;
	line-height: (20 / 14);
}

// ------------------

// Animation
@mixin set-animate($animateEl) {
	@each $class in $animateEl {
		$i: index($animateEl, $class);
		$i: $i;

		#{$class} {
			opacity: 0;
			transform: translateY(30px);
			transition-duration: 1s;
			transition-property: opacity, transform;
			transition-delay: #{$i * $ms-faster};
			will-change: transform;
		}
	}
}

@mixin set-animated($animateEl) {
	@each $class in $animateEl {
		#{$class} {
			opacity: 1;
			transform: none;
		}
	}
}

// ----------------------------------------

// Custom Scroll Bar
$scrollbar-thumb-color: $primary; // 操作顏色
$scrollbar-track-color: $gray-40; // 滾軸背景
$scrollbar-width: 7px; // 寬度(瀏覽器預設為15px)
$scrollbar-border-radius: 4px; // 圓角，Firefox不支援

@mixin custom-scroll-bar($thumb-color: $scrollbar-thumb-color, $track-color: $scrollbar-track-color) {
	// For Chrome, Opera, Safari, Android, iOS
	&::-webkit-scrollbar {
		width: $scrollbar-width;
		height: $scrollbar-width;
	}

	&::-webkit-scrollbar-track {
		background: $track-color;
	}

	&::-webkit-scrollbar-thumb {
		background: $thumb-color;
		border-radius: $scrollbar-border-radius;

		&:hover {
			background: darken($thumb-color, 10%);
		}
	}

	&::-ms-scrollbar {
		width: $scrollbar-width;
	}

	@if & {
		// For Firefox (版本必須64+)
		// 如果是整頁<body>要使用，必須將Class增加於<html>標籤上
		// Ref: https://stackoverflow.com/a/6165489/11240898
		// 顏色：操作顏色(Thumb) 滾軸背景(Track)
		scrollbar-color: $thumb-color $track-color;

		// 寬度只有 auto | thin | none 選項
		// auto為15px，thin為6px，none為不顯示
		scrollbar-width: thin;
	}
}

// ----------------------------------------
