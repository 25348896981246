%btn {
	position: relative;
	z-index: 1;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: auto;
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
	text-decoration: none;
	letter-spacing: 0.03em;
	white-space: nowrap;
	border: 1px solid transparent;
	outline: none;
	text-shadow: none;
	transition: $ms-fast ease-out;
	box-sizing: border-box;
	user-select: none;
	touch-action: manipulation;
	vertical-align: middle;
	background-image: none;
	cursor: pointer;
	transition-property: color, background-color, border-color, box-shadow;

	@include bp-lg {
		font-size: 1.125rem;
	}

	&[disabled] {
		color: $gray-200;
		border-color: $gray-100;
	}
}

.icon-append,
.icon-prepend {
	font-size: 24px;
	color: inherit;
}

.btn,
.btn-outline {
	@extend %btn;

	--icon-size: 20px;

	padding: 0.6875rem 1.5rem;
	min-width: 6.25rem;
	min-height: 2.5rem;
	font-size: 1rem;
	line-height: calc(24 / 18);
	border-radius: 0.125rem;

	@include bp-md {
		min-width: 8.75rem;
		min-height: 2.75rem;
		font-size: 1.125rem;
	}

	&.w-full {
		width: 100%;
	}

	&.sz-lg {
		min-width: 8.75rem;

		@include bp-md {
			min-width: 13.75rem;
		}
	}
}

.btn {
	color: $white;
	background-color: $gray-500;

	@include bp-lg {
		&:hover {
			background-color: $dark;
		}
	}

	&.clr-primary {
		color: $white;
		background-color: $primary;
		border-color: $primary;

		@include bp-lg {
			&:hover {
				background-color: $primary-dark;
				border-color: $primary-dark;
			}
		}

		&[disabled] {
			background-color: $gray-200;
			border-color: $gray-200;
		}
	}

	&.clr-dark {
		color: $white;
		background-color: $dark;
		border-color: $dark;

		@include bp-lg {
			&:hover {
				background-color: $dark-dark;
				border-color: $dark-dark;
			}
		}

		&[disabled] {
			background-color: $gray-200;
			border-color: $gray-200;
		}
	}
}

// --------------------------------------------------------

.btn-outline {
	@extend %btn;

	color: $dark;
	background: $white;
	border-color: $gray-500;

	@include bp-lg {
		&:hover {
			color: $dark-dark;
			background: $gray-40;
			border-color: $dark-dark;
		}
	}

	&.clr-white {
		color: $white;
		background: transparent;
		border-color: $white;

		@include bp-lg {
			&:hover {
				color: $dark;
				background: $white;
				border-color: $white;
			}
		}
	}
}

.btn-text {
	@extend %btn;

	padding: 0.75rem 1.25rem;
	color: initial;
	background: transparent;
	border: 0.0625rem solid transparent;

	@include bp-lg {
		&:hover {
			color: $primary;
		}
	}
}

.btn-icon {
	@extend %btn;

	background: transparent;

	&[disabled] {
		color: $gray-400;
		background: transparent;
		border-color: transparent;
	}

	@include bp-lg {
		&:hover {
			color: $primary;
		}
	}
}

// --------------------------------------------------------

// group
.btn-group {
	display: flex;
	justify-content: center;

	// grid-template-columns: repeat(2, 1fr);
	gap: 0.75rem;

	@include bp-lg {
		gap: 1.5rem;
	}

	[class*='btn'] {
		flex: 1;
		max-width: 13.75rem;
	}
}

// --------------------------------------------------------
// with icon
.icon-left {
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	text-align: center;

	.be-icon {
		flex-shrink: 0;
		margin-right: 0.625rem;
		font-size: 1.5rem;
	}

	.btn_txt {
		flex: 1;
	}
}

.icon-right {
	.be-icon {
		flex-shrink: 0;
		margin-left: 0.625rem;
		font-size: 1.5rem;
	}

	.btn_txt {
		flex: 1;
	}
}

// --------------------------------------------------------

.text-link {
	text-decoration: underline;
	color: $dark;

	@include hover {
		text-decoration: underline;
	}
}

.link-space {
	margin: 0 3px;
}

// --------------------------------------------------------
// btn group
@include bp-lg {
	.w-limit-460 {
		margin: 0 auto;
		max-width: remy(460);
	}
}
