.number-list {
	padding-left: 1rem;

	.highlight {
		color: $primary;
	}
}

.list-note {
	margin: 0;
	margin-bottom: 1.5rem;
	padding: 0;
	list-style-type: none;
	color: $gray-400;

	&_item {
		display: flex;
		align-items: baseline;
		justify-content: flex-start;
		margin-bottom: 0.25rem;

		&:last-child {
			margin-bottom: 1.5rem;
		}

		.be-icon {
			margin-right: 0.25rem;
		}
	}
}

.list-dot {
	margin: 0;
	margin-bottom: 1.5rem;
	padding: 0;
	list-style-type: none;
	color: $dark;

	&_item {
		display: flex;
		align-items: baseline;
		justify-content: flex-start;
		margin-bottom: 0.25rem;

		&:last-child {
			margin-bottom: 1.5rem;
		}

		.be-icon {
			margin-right: 0.25rem;
		}
	}
}

// 消息
.news {
	$this: &;

	&_link {
		@include bp-md {
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		@include bp-lg {
			&:hover {
				.be-icon {
					transform: translateX(0.625rem);
				}
			}
		}
	}

	&-list {
		@extend %list-reset;

		&.is-locked {
			pointer-events: none;
			color: inherit !important;

			.news_title {
				.be-icon {
					display: none;
				}
			}
		}
	}

	&-list_item {
		padding: 1rem 0.625rem;
		border-bottom: 0.0625rem solid $gray-200;

		@include bp-lg {
			padding: 1.5rem 1rem;
		}

		&.page-list {
			#{$this}_title {
				position: relative;
				align-items: flex-start;
				flex-direction: column;
				font-size: remy(16);

				@include bp-md {
					align-items: center;
					justify-content: flex-start;
					flex-direction: row;
					font-size: remy(18);
				}

				.be-icon {
					position: absolute;
					bottom: remy(-5);
					right: 0;

					@include bp-md {
						position: initial;
						margin-left: remy(24);
					}
				}
			}

			#{$this}_date {
				margin-top: remy(8);
				margin-right: auto;
				font-size: remy(14);
				color: $gray-400;

				@include bp-md {
					margin-top: 0;
					margin-right: initial;
					margin-left: auto;
					padding-left: remy(24);
				}
			}
		}
	}

	&_tag {
		--clip-height: 0.75rem;

		display: inline-block;
		margin-bottom: 0.75rem;
		padding: 0.25rem 1.125rem 0.25rem 0.375rem;
		min-width: 7rem;
		font-size: 0.875rem;
		line-height: calc(20 / 14);
		color: $white;
		background-color: $dark;
		clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--clip-height)), calc(100% - var(--clip-height)) 100%, 0 100%);

		&.highlight {
			background-color: $primary;
		}

		@include bp-md {
			margin-bottom: 0;
			margin-right: 1.5rem;
		}

		@include bp-lg {
			min-width: 8.125rem;
		}
	}

	&_title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0;
		width: 100%;

		.be-icon {
			margin-left: 0.625rem;
			font-size: 1.75rem;
			transition: $ms-fast all;

			@include bp-xl {
				font-size: 2rem;
			}
		}
	}
}

// 消息
.file {
	$this: &;

	&_list {
		@extend %list-reset;
	}

	&_list_item {
		padding: 1rem 0.625rem;
		border-bottom: 0.0625rem solid $gray-200;

		@include bp-lg {
			padding: 0.625rem 1rem;
		}
	}

	&_info {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;

		[class*='btn'] {
			margin-top: 0.625rem;
			width: 100%;

			@include bp-md {
				margin-top: 0;
				width: auto;
			}
		}

		@include bp-md {
			align-items: center;
			flex-wrap: nowrap;
		}
	}

	&_type {
		display: flex;
		margin-right: 0.5rem;
		font-size: 2.625rem;

		@include bp-md {
			margin-right: 1.5rem;
			font-size: 3.75rem;
		}
	}

	&_title {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-direction: column;
		margin: 0;
		width: calc(100% - 3.125rem);
		font-size: 1rem;

		@include bp-md {
			align-items: center;
			flex-direction: row;
			margin-right: 1.5rem;
			font-size: 1.125rem;
		}
	}

	&_date {
		margin-top: 0.5rem;
		font-size: remy(14);
		color: $gray-400;

		@include bp-md {
			margin-top: 0;
			margin-right: initial;
			margin-left: auto;
			padding-left: remy(24);
		}
	}
}

// filter button
.filter {
	&-form {
		--filter-mx: #{remy(12)};
		--filter-my: #{remy(6)};

		margin: 0 calc(var(--filter-my) * -1) calc(var(--filter-mx) * -1);

		@include bp-md {
			--filter-mx: #{remy(12)};
			--filter-my: var(--filter-mx);
		}
	}

	&-btn {
		display: inline-flex;
		align-items: flex-end;
		margin: 0 var(--filter-my) var(--filter-mx);
		padding: remy(8) remy(12);
		font-size: remy(16);
		font-weight: 400;
		color: $gray-400;
		background-color: $white;
		border: remy(1) solid $gray-500;
		border-radius: remy(2);

		@include bp-lg {
			font-size: remy(18);

			&:hover {
				color: $white;
				background-color: $dark;
				transition: $ms-fast ease-out;
				transition-property: color, background-color, border-color, box-shadow;
			}
		}

		.be-icon {
			display: none;
			margin-left: remy(8);
			font-size: remy(24);
			color: $white;
		}

		&.is-active {
			color: $white;
			background-color: $dark;

			.be-icon {
				display: inline-block;
			}
		}
	}
}

// mark
.mark {
	--clip-height: 0.875rem;

	position: absolute;
	z-index: 2;
	left: remy(-8);
	display: inline-block;
	margin-bottom: 0.75rem;
	padding: 0.25rem 1.125rem 0.25rem 0.375rem;
	min-width: 6rem;
	font-size: 0.75rem;
	color: $dark;
	background-color: $white;
	clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--clip-height)), calc(100% - var(--clip-height)) 100%, 0 100%);

	&.primary {
		background-color: $primary;
	}

	&.dark {
		background-color: $dark;
	}

	&.highlight {
		background-color: $primary;
	}

	@include bp-md {
		margin-bottom: 0;
	}

	@include bp-lg {
		min-width: 8.125rem;
		font-size: 0.875rem;
	}
}
