.tab {
	$root: &;

	&-box {
		--tab-margin: #{remy(12)};

		position: relative;
		background-color: $white;
	}

	&_list {
		display: flex;
		align-items: center;
		overflow-x: auto;
		margin: 0;
		padding: 0;
		list-style: none;

		@include bp-lg {
			padding-top: 1.5rem;
		}
	}

	&_item {
		position: relative;
		margin: 0 calc(var(--tab-margin) * 0.5);
		white-space: nowrap;

		@include bp-md {
			margin: 0 var(--tab-margin);
		}

		&:first-of-type {
			margin-left: auto;
		}

		&:last-of-type {
			margin-right: auto;
		}
	}

	&_a {
		display: inline-block;
		padding: remy(8) remy(10) remy(14);
		font-size: remy(18);
		text-align: center;
		color: $gray-500;

		@include bp-md {
			padding: remy(8) remy(24) remy(14);
			min-width: remy(156);
		}

		&.is-active {
			position: relative;
			font-weight: 500;
			color: $dark;

			&::before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				width: remy(80);
				height: remy(6);
				background: $dark;
				transform: translateX(-50%);
			}
		}
	}

	&_panel {
		display: none;

		&.is-active {
			display: block;
		}
	}

	// Style
	&\:line {
		#{$root}_item {
			&:not(:last-child) {
				&::after {
					content: '';
					position: absolute;
					top: .625rem;
					right: 0;
					display: block;
					width: .0625rem;
					height: 1.75rem;
					background-color: $gray-200;
				}
			}
		}
	}
}
