.infoitem {
	$root: &;

	--clr-white: #{$white};
	--clr-dark: #{$dark};

	padding: 1.5rem 1rem;
	color: var(--clr-white);
	background-color: var(--clr-dark);
	border: solid 1px var(--clr-dark);
	box-shadow: 2px 2px 20px 0 rgba($dark, 0);

	.be-icon {
		display: flex;
		margin-bottom: 0.5rem;
		font-size: 2.5rem;
		color: inherit;

		@include bp-xl {
			margin-bottom: 0.75rem;
			font-size: 3.75rem;
		}
	}

	&_title {
		position: relative;
		font-weight: 600;
		color: inherit;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: calc(100% + 0.5rem);
			left: -2rem;
			display: block;
			height: 0.625rem;
			background-color: var(--clr-white);

			@include bp-xl {
				top: calc(100% + 1.5rem);
				height: 1.125rem;
			}
		}

		&::after {
			width: 7.875rem;
			transition: $ms-faster cubic-bezier(0.25, 1, 0.5, 1);
		}

		&::before {
			width: 0;
			transition: $ms cubic-bezier(0.25, 1, 0.5, 1);
			transition-delay: 0.6s;
		}
	}

	&_info {
		overflow: hidden;
		margin: 1.625rem 0 0;

		@include bp-xl {
			margin-top: 4.125rem;
			max-height: 0;
			font-size: 1.375rem;
		}
	}
}
