.breadcrumb {
	position: relative;
	z-index: 5;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 0.875rem;
	color: $white;

	&_item {
		position: relative;
		display: flex;

		&:not(:first-child) {
			margin-left: 0.5rem;

			&::before {
				content: '-';
				margin-right: 0.5rem;
			}
		}

		&:last-of-type {
			display: block;
		}
	}

	&_link {
		text-decoration: underline;
		white-space: nowrap;

		@include hover {
			text-decoration: underline;
			color: $dark;
		}
	}

	// Clr
	&.clr-dark {
		color: $dark;
	}
}
