// Containers
// ------------------
%container {
	position: relative;
	z-index: 1;
	margin-right: auto;
	margin-left: auto;
	width: 100%;

	@include paddings(null, remy(map-get($container-gutters, xs)));

	@include bp-lg {
		@include paddings(null, remy(map-get($container-gutters, lg) * 1));
	}

	@include bp-xl {
		@include paddings(null, remy(map-get($container-gutters, xl) * 1));
	}
}

.container {
	max-width: map-get($container-max-widths, 'xs');

	@extend %container;

	&-fluid {
		@extend %container;
	}
}

// Rows and Columns
// ------------------
.f-row,
[class*='f-col'] {
	position: relative;
	z-index: 1;
	box-sizing: border-box;
}

.f-row {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;

	@include margins(null, remy(-(map-get($grid-gutters, xs)) * 0.5));

	> hr,
	> .divider {
		flex: 1 0 100%;
		max-width: 100%;
	}

	> [class*='f-col'] {
		@include paddings(null, remy(map-get($grid-gutters, xs) * 0.5));
	}

	// @each $key in $grid-gutter-list {
	// 	@media (min-width: map-get($breakpoints, $key)) {
	// 		@include margins(null, remy(-(map-get($grid-gutters, $key)) * 0.5));

	// 		> [class*='f-col'] {
	// 			@include paddings(null, remy(map-get($grid-gutters, $key) * 0.5));
	// 		}
	// 	}
	// }
}

[class*='f-col'] {
	position: relative;
	z-index: 1;
	flex: 1 0 100%;
	order: 0;
	max-width: 100%;
}

@each $key, $value in $breakpoints {
	@if  $key == 'xs' {
		@include flex-maker;
	} @else {
		/* stylelint-disable-next-line media-query-no-invalid */
		@media (min-width: $value) {
			@include flex-maker($key);
		}
	}
}
