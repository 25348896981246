// Global
// ------------------

// Float Clear
%clear {
	clear: both;
}

%clearfix {
	@include clearfix;
}

// IFs
%empty {
	@include empty {
		display: none !important;
		clear: both;
	}
}

%last-child-for-vertical {
	&:last-child {
		margin-bottom: 0;
	}
}

%last-child-for-horizontal {
	&:last-child {
		margin-right: 0;
	}
}

%disabled {
	@include disabled;
}

// Transition
%ts {
	transition: $ms-faster $easeOut;
}

%ts-faster {
	transition: $ms-fastest $easeOut;
}

%ts-fastest {
	transition: $ms-snappy $easeOut;
}

%ts-slow {
	transition: $ms-fast $easeOut;
}

%text-ellipsis {
	@include text-truncate;
}

// Flex
%flex-left {
	justify-content: flex-start;
}

%flex-center {
	justify-content: center;
}

%flex-right {
	justify-content: flex-end;
}

%flex-justify {
	justify-content: space-between;
}

%flex-around {
	justify-content: space-around;
}

%flex-top {
	align-items: flex-start;
}

%flex-middle {
	align-items: center;
}

%flex-bottom {
	align-items: flex-end;
}

// Box Modules
%box-module {
	font-size: inherit;
	line-height: 1.5;
	border-style: solid;
	border-width: 1px;
	transition: $ms-fastest $easeOutQuint;
	vertical-align: top;
}

%triangle-base {
	display: inline-block;
	width: 0;
	height: 0;
}

%btn-text-shadow-light {
	text-shadow: 0 1px 1px rgba($white, $opacity-lv3);
}

%btn-text-shadow-dark {
	text-shadow: 0 -1px 1px rgba($black, $opacity-lv7);
}

%full-img {
	background: {
		repeat: no-repeat;
		position: center;
		size: cover;
	}
}

%list-reset {
	margin: 0;
	padding: 0;
	list-style: none;
}

%btn-reset {
	padding: 0;
	background: transparent;
	border: 0;
}

%unite-inline {
	display: inline-flex;
}

%unite-block {
	display: flex;
}

// Glow
%text-glow-dark {
	text-shadow: 0 0 4px rgba($black, $opacity-lv6);
}

%text-glow-light {
	text-shadow: 0 0 4px rgba($white, $opacity-lv5);
}

// Shadow
%box-shadow-md {
	box-shadow: 0 remy(8) remy(18) remy(-6) rgba($shadow, $opacity-lv6);
}

%box-shadow-sm {
	box-shadow: 0 remy(6) remy(10) remy(-4) rgba($shadow, $opacity-lv7);
}

%box-shadow-lg {
	box-shadow: 0 remy(16) remy(48) remy(-8) rgba($shadow, $opacity-lv6);
}

%box-shadow-none {
	box-shadow: none;
}

// Glow
%box-glow-md {
	box-shadow: 0 0 remy(18) rgba($white, $opacity-lv3);
}

%box-glow-sm {
	box-shadow: 0 0 remy(10) rgba($white, $opacity-lv3);
}

%box-glow-lg {
	box-shadow: 0 0 remy(40) rgba($white, $opacity-lv3);
}
