.color-white {
	color: $white;
}

.color-dark {
	color: $dark;
}

.color-primary {
	color: $primary;
}

.color-gray-40 {
	color: $gray-40;
}

.color-gray-500 {
	color: $gray-500;
}

.clr-dark {
	color: $dark;
}

.clr-gray400 {
	color: $gray-400;
}

// ------------------
.bg-gray-40 {
	background-color: $gray-40;
}

.bg-gray-500 {
	background-color: $gray-500;
}
