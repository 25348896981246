.cookie {
	&_box {
		position: fixed;
		z-index: 1000;
		bottom: 0;
		left: 0;
		padding: 0.9375rem 0;
		width: 100%;
		color: $white;
		background-color: $dark;
		opacity: 0;
		transform: translateY(100%);
		transition: $ms-slow;
		pointer-events: none;

		@include bp-md {
			padding: 1.5rem 0;
		}

		.cookie_btn {
			margin-top: 0.625rem;
			font-size: 0.875rem;

			@include bp-md {
				margin-top: 0;
				margin-left: 0.625rem;
				font-size: 1rem;
			}

			@include bp-xl {
				font-size: 1.125rem;
			}
		}

		.link {
			margin: 0 0.1875rem;
		}

		&.is-show {
			opacity: 1;
			transform: translateY(0%);
			pointer-events: all;
		}
	}

	&_info {
		@include bp-md {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
		}
	}

	&_title {
		margin: 0;
		font-size: 0.875rem;

		@include bp-md {
			font-size: 1rem;
		}

		@include bp-xl {
			font-size: 1.125rem;
		}
	}
}
