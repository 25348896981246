.main-banner {
	position: relative;
	z-index: 2;
	padding: 1.25rem 0;
	height: 9.375rem;
	background-color: $gray-40;

	@include bp-lg {
		height: 12.5rem;
	}

	&::before {
		padding: 0;
	}
}

// --------------------------------------------------------

.wave-mask {
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($primary, 0.85);

	&::before,
	&::after {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		display: block;
		width: 14.0625rem;
		height: 100%;
		background-position: top center;
		background-repeat: no-repeat;
		background-size: 100% auto;

		@include bp-lg {
			width: 28.125rem;
		}
	}

	&::before {
		left: 0;
		background-image: url("img/common/wave-left.svg");
	}

	&::after {
		right: 0;
		background-image: url("img/common/wave-right.svg");
	}
}

// --------------------------------------------------------
