// Step 表單用
.progress-step {
	$root: &;

	position: relative;
	display: flex;
	align-items: center;
	width: calc(100% - 60px);
	height: 8px;
	background-color: $gray-40;
	border-radius: 2px;

	&_wrap {
		width: 100%;
	}

	&_progress {
		width: 0;
		height: 100%;
		background-color: $primary;
		border-radius: 2px;
	}

	&_num {
		position: absolute;
		top: 50%;
		right: -3.75rem;
		transform: translateY(-50%);
	}

	&_labels {
		position: relative;
		margin-top: 1.25rem;
		width: 100%;
		width: calc(100% - 60px);
	}

	&_label {
		position: absolute;
		color: $gray-200;

		&.is-active {
			color: $dark;
		}
	}
}

// --------------------------------------------------------

.progress {
	$root: &;

	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	width: 100%;

	@include bp-md {
		flex-direction: row;
	}

	&_progress {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 0.5rem;
		background-color: $gray-40;
		border-radius: 0.125rem;
	}

	&_bar {
		display: block;
		height: 100%;
		background-color: $gray-400;
	}

	&_info {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 0.5rem;
		width: 100%;

		@include bp-md {
			margin-top: 0;
			padding-right: 2rem;
			padding-left: 0.75rem;
			width: auto;
		}
	}

	&_num {
		min-width: 6.25rem;
		font-size: 0.875rem;
		text-align: right;
		white-space: nowrap;
		color: $gray-400;

		@include bp-md {
			min-width: 9.375rem;
			font-size: 1.125rem;
		}
	}

	&_icon {
		display: none;
		font-size: 1.25rem;
		color: $primary;

		@include bp-md {
			position: absolute;
			top: 50%;
			right: 0;
			font-size: 1.5rem;
			transform: translateY(-50%);
		}
	}

	&.is-selected {
		#{$root}_bar {
			background-color: $primary;
		}

		#{$root}_num {
			font-weight: 500;
			color: $dark;
		}

		#{$root}_icon {
			display: block;
		}
	}
}

// --------------------------------------------------------
.step {
	flex-shrink: 0;

	&_wrap {
		display: flex;
		justify-content: center;
		flex-wrap: nowrap;
	}

	&_linebetween {
		content: '';
		position: relative;
		margin: 0 remy(8);
		width: 100%;
		max-width: 194px;

		@include bp-lg {
			margin: 0 remy(24);
		}

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			display: block;
			width: 100%;
			height: 2px;
			background-color: $gray-40;
		}

		&.active::before {
			background-color: $dark;
		}
	}

	&_num {
		margin: 0 auto remy(8);
		padding: 0.2rem 0.45rem 2px;
		width: fit-content;
		font-size: 1.125rem;
		border: 0.0625rem solid $dark;
		border-radius: 0.125rem;

		@include bp-md {
			font-size: 1.375rem;
		}
	}

	&_title {
		color: $gray-200;

		@include bp-md {
			font-size: 1.125rem;
		}
	}

	&.is-active {
		.step {
			&_num {
				@include SerifFont;

				color: $white;
				background-color: $dark;
			}

			&_title {
				color: $dark;
			}
		}
	}
}
