.chart {
	&_wrap {
		margin-bottom: 3rem;

		&.set-border {
			padding: 1.25rem 2.5rem;
			border: 0.0625rem solid $gray-200;

			@include bp-xl {
				padding-right: 5.9375rem;
				padding-left: 5.9375rem;
			}
		}

		&.is-searching {
			.point-active {
				pointer-events: all;
				opacity: 1;
			}
		}

		&[data-range] {
			.droppoint-chart_alert {
				display: block;
			}

			.point_info {
				display: none;
			}

			.point-active {
				opacity: 0;
			}
		}

		&[data-range="min"] {
			.point-active {
				pointer-events: all;
				left: -3.0625rem !important;
				opacity: 1;
			}
		}

		&[data-range="max"] {
			.point-active {
				left: calc(100% + 3.0625rem) !important;
				opacity: 1;
			}
		}
	}

	&_box {
		height: 28.125rem;
	}

	&_title {
		margin: 0 0 1.5rem;
		font-size: 1.5rem;

		.small {
			display: block;
			margin-top: .5rem;
			font-size: 1.125rem;
		}
	}
}

.legend {
	display: flex;
	align-items: center;
	justify-content: center;
	list-style-type: none;
	margin: 0;
	margin-top: 1.5rem;
	padding: 0;

	&_item {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: .75rem;

		@media (min-width: 1200px) {
			font-size: .875rem;
		}

		@include bp-xl {
			font-size: 1rem;
		}
	}

	&_color {
		display: block;
		margin-top: 0.125rem;
		margin-right: 0.5rem;
		width: 1rem;
		height: 1rem;
	}

	&_label {
		margin-right: 0.5rem;
		font-size: inherit;
	}

	&_count {
		font-size: inherit;
		font-weight: 500;
	}

	&_percent {
		margin-left: 2px;
		font-size: inherit;
		color: $gray-400;
	}
}

// ----------------------------------------------
// doughnut Chart
.doughnut-chart {
	&_wrap {
		display: flex;
		align-items: stretch;
		justify-content: center;

		.legend {
			align-items: flex-start;
			flex-direction: column;
			margin-right: auto;
			margin-left: auto;

			// max-width: 140px;

			&_item:not(:last-child) {
				margin-bottom: .5rem;
			}
		}
	}

	&_item {
		display: flex;
		align-items: stretch;
		justify-content: center;
		flex-direction: column;
		padding: 0 0.375rem;
		width: 20%;
		text-align: center;
	}

	&_box {
		position: relative;
		margin: auto auto 0;
		max-width: 9.375rem;
		height: 9.375rem;
	}

	&_sum {
		position: absolute;
		top: 50%;
		left: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1rem;
		width: 8.125rem;
		height: 8.125rem;
		background-color: $white;
		border: 0.0625rem solid $dark;
		border-radius: 7.5rem;
		transform: translate(-50%, -50%);

		&::after {
			content: '';
			position: absolute;
			display: block;
			width: calc(100% - 2rem);
			height: calc(100% - 2rem);
			border: 0.0625rem solid $gray-200;
			border-radius: 50%;
		}
	}

	&_title {
		margin: 0 auto 0.75rem;
		padding: 0 0.625rem;
		min-height: 3rem;

		.small {
			display: block;
		}
	}
}

// ----------------------------------------------
// droppoint Chart
.droppoint-chart {
	$root: &;

	position: relative;
	margin: 7.5rem 6.125rem 2.5rem;

	&_point {
		position: absolute;
		top: 50%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		transform: translateY(-50%);
	}

	&_line {
		left: 0;
		width: 100%;
		height: .5rem;
		background: linear-gradient(90deg, #D9D9D9 0%, #464646 104.6%);

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			display: block;
			width: 6.125rem;
			height: 3px;
			transform: translateY(-50%);
		}

		&::before {
			right: 100%;
			background: url("img/p-l.svg") no-repeat left center;
			background-size: cover;
		}

		&::after {
			left: 100%;
			background: url("img/p-r.svg") no-repeat right center;
			background-size: cover;
		}
	}

	&_search {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		margin: 0 auto 1.5rem;
		max-width: 37.5rem;

		.form_grp {
			margin: 0;
			margin-right: 1.25rem;
			width: 27.5rem;
		}
	}

	&_alert {
		position: absolute;
		bottom: 100%;
		left: 50%;
		display: none;
		margin-bottom: 55px;
		padding: .5rem .625rem;
		text-align: center;
		color: $primary;
		background-color: rgba($primary, 0.1);
		transform: translateX(-50%);
	}

	.point {
		&:not(.point-active) {
			position: absolute;
			top: 0;
			text-align: center;
			transform: translate(0%, -50%);

			&:nth-child(2) {
				left: 0%;

				.point_title::after {
					left: 0%;
					transform: none;
				}
			}

			&:nth-child(3) {
				left: 25%;
				transform: translate(-50%, -50%);
			}

			&:nth-child(4) {
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&:nth-child(5) {
				left: 75%;
				transform: translate(-50%, -50%);
			}

			&:nth-child(6) {
				right: 0;
			}
		}

		&:last-child {
			text-align: right;

			.point_title::after {
				left: 100%;
				transform: none;
			}
		}

		&_title {
			position: relative;
			margin-bottom: 2.375rem;

			&::after {
				content: '';
				position: absolute;
				top: calc(100% + .75rem);
				left: 50%;
				display: block;
				width: .125rem;
				height: .875rem;
				background-color: $dark;
				box-shadow: 2px 2px 30px 0 rgba(70, 70, 70, 4%);
				transform: translateX(-50%);
			}
		}

		&-active {
			position: absolute;
			z-index: 2;
			top: 50%;
			left: 0;
			opacity: 0;
			transform: translateY(-50%);

			// transition: $ms;
			pointer-events: none;

			&::after {
				content: '';
				position: relative;
				display: block;
				width: 1rem;
				height: 1rem;
				background-color: $primary;
				border-radius: 1rem;

				// box-shadow: 0 0 0 .4375rem rgba($primary, 0.15);
				transform: translateX(-50%);
				animation: 1.5s wave infinite cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
			}

			.point_info {
				position: absolute;
				z-index: 1;
				bottom: calc(100% + 2.8125rem);
				left: 0;
				padding: .25rem .625rem;
				color: $white;
				background-color: $primary;
				transform: translateX(-50%);

				&::after {
					content: '';
					position: absolute;
					z-index: 0;
					top: 100%;
					left: 50%;
					display: block;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 7px 7px 0;
					border-color: $primary transparent transparent transparent;
					transform: translateX(-50%);
				}
			}

			.point_title {
				margin-bottom: 0;
				font-weight: 700;
				text-transform: uppercase;

				&::after {
					content: none;
				}
			}
		}
	}
}


@keyframes wave {
	0% {
		box-shadow: 0 0 0 0 rgba($primary, 0.8);
	}

	100% {
		box-shadow: 0 0 0 7px rgba($primary, 0);
	}
}
