.tooltip {
	$this: &;

	position: relative;

	&_txt {
		position: absolute;
		z-index: 1;
		top: 100%;
		left: calc(50% + 6px);
		margin: 0;
		padding: 5px 10px;
		width: 120px;
		font-size: .875rem;
		text-align: center;
		white-space: initial;
		color: #fff;
		background-color: $dark-dark;
		border-radius: 6px;
		opacity: 0;
		transform: translate(-50%, -5px);
		transition: $ms-fast all;
		pointer-events: none;

		&::after {
			content: "";
			position: absolute;
			bottom: 100%;
			left: 50%;
			margin-left: -5px;
			border-style: solid;
			border-width: 5px;
			border-color: transparent transparent $dark-dark;
		}
	}

	&:hover #{$this}_txt {
		opacity: 1;
		transform: translate(-50%, 0);
	}
}
