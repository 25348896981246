.cont-loading {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100%;

	&_block {
		height: 31.25rem;
	}

	&_icon {
		width: 3.75rem;
		@include bp-md {
			width: 6.25rem;
		}
	}

	&_title {
		margin-top: 0.5rem;
	}
}
