@import 'core/variables';
@import 'core/functions';
@import 'core/mixins';
@import 'extension';

// --------------------------------------------------------
// 共用的內容編輯器樣式

.html-editor {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		position: relative;
		margin-bottom: rem-calc(15);
		padding-top: rem-calc(10);
	}

	h1 {
		font-size: 1.5em;

		@include bp-md {
			font-size: 3em;
		}

		&.h4 {
			font-size: 1.5625em !important;
		}
	}

	h2 {
		font-size: 1.75em;
	}

	h3 {
		font-size: 1.5em;
	}

	h4 {
		font-size: 1.25em;
	}

	h5 {
		font-size: 1em;
	}

	h6 {
		font-size: 1em;
	}

	strong {
		font-weight: 500;
	}

	p {
		margin: 0 0 remy(12);
		font-size: 100%;
		line-height: 150%;
	}

	a {
		text-decoration: underline;
	}

	iframe {
		margin-bottom: rem-calc(20);
		max-width: 100%;
	}

	.table-scroll {
		overflow-x: auto;
		
		table {
			min-width: 500px;
			white-space: nowrap;
		}
	}

	table {
		word-break: break-all;

		tr {
			&:not(:last-of-type) {
				border-bottom: 1px solid $gray-200;
			}
		}

		th {
			font-weight: 400;
			background-color: $gray-40;
		}

		th,
		td {
			padding: remy(23) remy(12);

			&:not(:last-of-type) {
				border-right: 1px solid $gray-200;
			}
		}
	}

	ul, ol {
		margin-left: calc(1em + 4px);
		padding: 0;

		li {
			margin: 0;
		}
	}

	ul {
		list-style: none;

		> li {
			position: relative;

			&::before {
				content: '';
				position: absolute;
				top: 1px;
				left: calc(-1em - 4px);
				display: inline-block;
				width: 1em;
				height: 1.6em;
				background: url('img/common/dot.svg') no-repeat center;
				background-size: 1em;
			}
		}
	}

	li {
		width: 100%;
		line-height: 160%;
	}

	a {
		color: $primary;
	}

	p,
	option,
	span,
	button,
	a,
	li,
	input {
		font-size: inherit;
		font-weight: inherit;
	}

	tbody,
	tfoot,
	thead {
		background-color: white;
	}

	tbody tr:nth-child(even) {
		background-color: white;
	}

	// 排除的項目
	&--exception {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			&::before,
			&::after {
				content: none;
			}
		}

		img {
			max-width: 100%;
		}

		&::before,
		&::after {
			content: none !important;
		}
	}
}
