// 全站共用的可放在這
// 針對頁面修正的可放在該頁面的 scss
[lang*='ja'] {
	font-family: 'Noto Sans JP', sans-serif;

	.serif-lang-title {
		@include SerifFont;
	}

	// Header
	.header_nav_link {
		@include bp-lg {
			padding-right: 0.625rem;
			padding-left: 0.625rem;
		}

		@media (min-width: 75rem) {
			padding-right: 1.25rem;
			padding-left: 1.25rem;
		}
	}

	// Footer
	.footer {
		.info_title {
			min-width: 6.25rem;
		}
	}
}

[lang*='zh'] {
	font-family: 'Noto Sans TC', serif;

	.serif-lang-title,
	.serif-title {
		@include SerifFont;
	}
}
