.form {
	$root: &;

	--state-color: #{$dark};
	--icon-padding: 1rem;

	// grid
	.f-row {
		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}

	// Wrap
	&_grp {
		margin-bottom: 1rem;

		&.icon-right {
			--icon-padding: 3.125rem;

			[class*='btn'] {
				position: absolute;
				z-index: 2;
				top: 0;
				right: 0;
				padding: 0.6875rem 1rem;
			}

			.be-icon {
				margin-left: 0;
			}
		}
	}

	&.bigger-grp {
		#{$root}_grp {
			margin-bottom: 1.5rem;
		}
	}

	&_opt {
		position: relative;
	}

	// 元件
	&_label {
		display: block;
		margin-bottom: 0.75rem;
		font-weight: 500;
		line-height: (26 / 18);

		@include bp-xl {
			font-size: 1.125rem;
		}
	}

	&_readonly {
		margin: 0;

		// padding: 0.6875rem 0;
	}

	&_note {
		display: flex;
		align-items: baseline;
		justify-content: flex-start;
		margin-top: 0.5rem;
		margin-bottom: 0;
		font-size: 1rem;
		color: $gray-400;

		.be-icon {
			// margin-right: 0.5rem;
			font-size: 1.25rem;
		}

		.checkbox {
			&_title {
				color: $dark;
			}

			&_label::after {
				content: none;
			}

			&_checkmark {
				margin-top: 4px;
			}
		}
	}

	&_error {
		display: none;
		align-items: baseline;
		justify-content: flex-start;
		margin-top: 0.5rem;
		margin-bottom: 0;
		font-size: 1rem;
		color: var(--state-color);

		.be-icon {
			margin-right: 0.5rem;
			font-size: 1.25rem;
		}
	}

	&_valid {
		align-items: baseline;
		justify-content: flex-start;
		margin-top: 0.5rem;
		margin-bottom: 0;
		font-size: 1rem;
		color: $dark;

		.be-icon {
			margin-right: 0.5rem;
			font-size: 1.25rem;
		}
	}

	&_ctrl {
		&_item {
			&:not(:first-child) {
				margin-top: 2rem;
			}
		}

		.txt {
			margin: 0 0 0.75rem;
		}
	}

	// 狀態
	.is-danger {
		--state-color: #{$primary};

		.radio_label,
		.checkbox_label {
			--state-color: #{$primary};
		}

		#{$root}_error {
			display: inline-flex;
		}
	}

	// Type
	&[data-type='general'] {
		.form_grp {
			&:not(:last-child) {
				margin-bottom: 2rem;
			}
		}
	}

	.gap-big {
		--gap-big: 0.75;

		@include bp-lg {
			--gap-big: 3.125;
		}

		margin-right: calc(var(--gap-big) * -1rem);
		margin-left: calc(var(--gap-big) * -1rem);

		> .form_grp {
			padding-right: calc(var(--gap-big) * 1rem);
			padding-left: calc(var(--gap-big) * 1rem);
		}
	}
}

.flex_head {
	.checkbox_label {
		margin-bottom: 1.5rem;
	}

	@include bp-md {
		display: flex;
		justify-content: space-between;
	}
}

.flex_input-btn {
	.form-input {
		margin-bottom: 0.625rem;
		width: 100%;

		@include bp-md {
			margin-bottom: 0;
		}
	}

	@include bp-md {
		display: flex;
		flex-wrap: nowrap;
		gap: 0.625rem 1.25rem;
	}
}

// -------------------------------------------

%defaultStyle {
	padding: 0.6875rem 1rem;
	padding-right: var(--icon-padding);
	width: 100%;
	background-color: $white;
	border: 0.0625rem solid var(--state-color);
	border-radius: 0.125rem;
	transition: $ms-fastest all;

	&::placeholder {
		color: $gray-400;
	}

	&[disabled] {
		color: $gray-200;
		border-color: $gray-100;

		+ .be-icon {
			--state-color: #{$gray-200};
		}

		&::placeholder {
			color: $gray-200;
		}
	}

	@include focus {
		box-shadow: 0 0 0 3px rgba($dark, 0.05);
	}
}

%underlineStyle {
	padding: 0.6875rem 0;
	padding-right: var(--icon-padding);
	border: 0;
	border-bottom: 0.0625rem solid var(--state-color);
	border-radius: 0;
	transition: $ms-fast;

	&::placeholder {
		color: $gray-400;
	}

	@include focus {
		box-shadow: 0 1px 0 0 $dark;
	}
}

// -------------------------------------------

// Input
.form-input {
	@extend %defaultStyle;
}

[type="number"] {
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	-moz-appearance: textfield;
}

// -------------------------------------------

// Select
.form-select {
	@include appearance(none);
	@extend %defaultStyle;

	~ .be-icon {
		pointer-events: none;
		position: absolute;
		z-index: 2;
		top: 50%;
		right: 1rem;
		font-size: 1.5rem;
		color: var(--state-color);
		transform: translateY(-50%);
	}

	&.sty-underline {
		~ .be-icon {
			right: 0;
		}
	}
}

// Select Language
.select {
	padding-right: 1.875rem;
	background-color: transparent;
	border: 0;

	&-box {
		position: relative;

		.be-icon {
			position: absolute;
			z-index: 1;
			top: 50%;
			font-size: 1.5rem;
			transform: translateY(-50%);
			pointer-events: none;

			&:first-child {
				left: 0;

				+ .select {
					padding-left: 2rem;
				}
			}

			&:last-child {
				right: 0;
			}
		}
	}
}

// -------------------------------------------

// Textarea
.form-textarea {
	@extend %defaultStyle;
}

// -------------------------------------------

// Radio
.form-radio {
	position: absolute;
	width: 0;
	height: 0;
	opacity: 0;
	cursor: pointer;

	&[disabled] {
		+ .radio_label {
			--state-color: #{$gray-100};

			cursor: default;
			color: $gray-200;

			.radio_checkmark {
				border-color: var(--state-color);
			}

			&::after {
				background-color: var(--state-color);
			}
		}
	}

	&:not([disabled]) {
		&:not(:checked) + .radio_label {
			color: $gray-400;

			&::after {
				background-color: $gray-40;
			}
		}
	}

	&:checked + .radio_label {
		--state-color: #{$dark};

		.radio_checkmark {
			&::after {
				opacity: 1;
				transform: translate(-50%, -50%) scale(1);
			}
		}
	}
}

.radio {
	&_grp {
		position: relative;

		.form-radio:not(:checked) + .radio_label {
			.form-input {
				pointer-events: none;
				border-color: $gray-200;

				&::placeholder {
					color: $gray-200;
				}
			}
		}
	}

	&_label {
		--state-color: #{$gray-40};

		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 0.6875rem 0;
	}

	&_checkmark {
		position: relative;
		display: inline-block;
		margin: 3px;
		width: 1.125rem;
		height: 1.125rem;
		background-color: $white;
		border: 0.0625rem solid $dark;
		border-radius: 50%;
		flex-shrink: 0;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			width: 0.5rem;
			height: 0.5rem;
			background-color: $primary;
			border-radius: 50%;
			opacity: 0;
			transform: translate(-50%, -50%) scale(0.5);
			transition: $ms-fastest all;
		}
	}

	&_title {
		margin-left: 0.5rem;
	}
}

// -------------------------------------------

// Checkbox
.form-checkbox {
	position: absolute;
	width: 0;
	height: 0;
	opacity: 0;
	cursor: pointer;

	&[disabled] {
		+ .checkbox_label {
			--state-color: #{$gray-100};

			cursor: default;
			color: $gray-200;

			.checkbox_checkmark {
				border-color: var(--state-color);
			}

			&::after {
				background-color: var(--state-color);
			}
		}
	}

	&:not([disabled]) {
		&:not(:checked) + .checkbox_label {
			color: $gray-400;

			&::after {
				background-color: $gray-40;
			}
		}
	}

	&:checked + .checkbox_label {
		--state-color: #{$dark};

		.checkbox_checkmark {
			.be-icon {
				opacity: 1;
				transform: translate(-50%, -50%) scale(1);
			}
		}
	}
}

.checkbox {
	&_grp {
		position: relative;
	}

	&_label {
		--state-color: #{$gray-40};

		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 0.6875rem 0;
	}

	&_checkmark {
		position: relative;
		display: inline-block;
		margin: 3px;
		width: 1.125rem;
		height: 1.125rem;
		background-color: $white;
		border: 0.0625rem solid $dark;
		border-radius: 0.125rem;
		flex-shrink: 0;

		.be-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			font-size: 1.25rem;
			color: $primary;
			opacity: 0;
			transform: translate(-50%, -50%) scale(0.5);
			transition: $ms-fastest all;
		}
	}

	&_title {
		margin-left: 0.5rem;
	}
}

// -------------------------------------------
.sty-underline {
	@extend %underlineStyle;
}

// -------------------------------------------
@for $i from 1 through 8 {
	.gap-#{$i} {
		gap: #{remy($i * 8)};
	}
	.gap-row-#{$i} {
		row-gap: #{remy($i * 8)};
	}
	.gap-col-#{$i} {
		column-gap: #{remy($i * 8)};
	}
}

// ----------------------------------------------

.input-wrap_percentage {
	position: relative;

	&::after {
		content: url('../img/common/percent.svg');
		position: absolute;
		top: 50%;
		right: 0;
		display: block;
		width: 25px;
		height: 25px;
		transform: translateY(-50%);
	}

	& > .form-input {
		padding-right: 33px;
	}
}

// checkbox 裡面的 input
.checkbox-input {
	.form-input {
		max-width: 11.6875rem;
	}
}

// radio 裡面的 input
.radio-input {
	margin: 0 12px;
	max-width: 11.75rem;

	&_grp {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
	}

	&_radio {
		.radio_label {
			align-items: center;
		}
	}
}

// ----------------------------------------------
table {
	th,
	td {
		.checkbox_label {
			align-items: center;
		}
	}

	.th,
	.td {
		&-border-l {
			position: relative;

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: -0.75px;
				width: 1.5px;
				height: calc(100% - 32px);
				background-color: $gray-40;
				transform: translateY(-50%);
			}
		}
	}

	&.first-row-left {
		tbody {
			td {
				font-size: 1rem;

				&:first-child {
					padding-left: 1.5rem;
					text-align: left;
				}
			}
		}

		tr:first-child {
			th:first-child {
				text-align: left;
			}
		}
	}

	&.table {
		th {
			padding: 12px;
			font-size: .875rem;
			font-weight: 400;
			background-color: $gray-40;

			@include bp-lg {
				padding-top: 36px;
				padding-bottom: 36px;
				font-size: 1.125rem;
			}

			&:not(:last-child) {
				border-right: 1px solid $gray-200;
			}
		}

		tbody {
			tr {
				&:not(:last-child) {
					td {
						border-bottom: 1px solid $gray-200;
					}
				}
			}

			td {
				padding: 12px;
				font-size: .875rem;

				@include bp-lg {
					font-size: 1rem;
				}

				&:not(:last-child) {
					border-right: 1px solid $gray-200;
				}
			}

			td:not(:first-child) {
				font-weight: bold;
			}
		}
	}

	&.table-fix {
		table-layout: fixed;
	}
}

// response------------------------
.response-icon {
	margin: 0 auto;
	max-width: 18.75rem;
}
