.header {
	$root: &;

	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100vw;
	background: $white;
	box-shadow: 2px 2px 20px 0 rgba($dark, 0.15);

	// Top
	&_top {
		position: relative;
		z-index: 2;
		display: none;
		background-color: $gray-40;

		@include bp-lg {
			display: block;
		}

		.header_inner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-left: auto;
			padding-left: 4.625rem;
			max-width: 120rem;

			@include bp-lg {
				margin: 0 auto;
				padding-right: 1.5rem;
				max-width: 106.75rem;
				transition: $ms-faster ease;
			}
		}
	}

	&_function {
		display: flex;
		align-items: center;
		justify-content: center;

		.btn {
			padding: 0.75rem 1.25rem;
			min-width: 0;
			min-height: 0;
			line-height: 1.44;
		}

		.select {
			font-size: 1.125rem;
		}
	}

	.space {
		@include bp-lg {
			min-width: 6.75rem;
		}
	}

	// Bottom
	&_bottom {
		position: relative;
		z-index: 1;

		.header_inner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0.5rem 1.5rem;

			@include bp-lg {
				margin: 0 auto;
				padding-top: 0;
				padding-bottom: 0;
				max-width: 106.75rem;
			}
		}

		// is-open
		&.is-open {
			#{$root}_switch {
				&::before {
					top: 50%;
					transform: translateY(-50%) rotate(-45deg);
				}

				&::after {
					top: 50%;
					transform: translateY(-50%) rotate(45deg);
				}
			}

			#{$root}_expand {
				opacity: 1;
				pointer-events: all;

				&_inner {
					transform: translateX(0);
				}
			}
		}
	}

	&_logo {
		@include bp-lg {
			display: block;
			width: 10rem;
			max-height: 2.5rem;
			transition: $ms-faster ease;
			flex-shrink: 0;
		}

		@include bp-xl {
			width: 13.75rem;
		}
	}

	// Nav
	&_nav {
		@extend %list-reset;

		margin-bottom: 4.5rem;

		@include bp-lg {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin: 0;
		}

		&_item {
			position: relative;

			&:not(:last-child) {
				@include bp-xl {
					margin-right: 2.5rem;
				}
			}

			@include bp-lg {
				&:hover {
					.header_nav_link:not(.is-locked) {
						.txt::after {
							width: 2rem;
						}
					}

					.header_subnav {
						opacity: 1;
						transform: translate(-50%, 0);
						pointer-events: all;
					}
				}
			}

			// mobile open
			&.is-active {
				.header_nav_link {
					> .be-icon {
						transform: rotate(180deg);
					}
				}

				.header_subnav {
					margin-bottom: 1rem;
					max-height: 31.25rem;
				}
			}
		}

		&_link {
			cursor: pointer;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0.625rem 0.75rem;
			font-size: 1.125rem;
			border-bottom: solid 0.0625rem $gray-200;

			@include bp-lg {
				padding: 1.6875rem 1.25rem;
				border: 0;
				transition: $ms-faster ease;
			}

			.txt {
				display: flex;
				align-items: center;
				justify-content: flex-start;

				@include bp-lg {
					position: relative;
	
					&::after {
						content: '';
						position: absolute;
						bottom: -0.4375rem;
						right: 0;
						display: block;
						width: 0;
						height: 0.125rem;
						background-color: $primary;
						transition: $ms-faster ease-out;
					}
				}
			}

			.be-icon {
				font-size: 1.5rem;

				@include bp-lg {
					display: none;
				}
			}
		}
	}

	&_subnav {
		@extend %list-reset;

		overflow: hidden;
		max-height: 0;
		transition: $ms-fast all;

		@include bp-lg {
			position: absolute;
			top: 100%;
			left: 50%;
			overflow: auto;
			width: 11.875rem;
			max-height: none;
			background-color: $white;
			opacity: 0;
			box-shadow: 0 2px 5px 0 rgba($dark, 0.1);
			transform: translate(-50%, -1.25rem);
			pointer-events: none;
		}

		&_link {
			display: flex;
			align-items: center;
			padding: 0.625rem 0.75rem;
			color: $gray-500;

			@include bp-lg {
				padding: 0.875rem 1.625rem;
			}

			.be-icon {
				display: inline-flex;
				margin-right: .5rem;
				font-size: 1.5rem;
			}
		}
	}

	// mfunction
	&_mfunction {
		margin-top: auto;
		text-align: center;

		@include bp-lg {
			display: none;
		}
	}

	.login {
		[class*="btn"] {
			.be-icon {
				margin-right: 0;
				margin-left: 0.5rem;
			}
		}
	}

	.guide {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 1.5rem;

		&_link {
			margin-right: 1rem;
			padding-right: 1rem;
			border-right: 0.0625rem solid $dark;
		}
	}

	.language {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		@include bp-lg {
			margin-right: 1.5rem;
			font-size: 1.125rem;
			transition: $ms-faster ease-out;

			&:hover {
				color: $primary;
			}
		}

		.select {
			@include bp-lg {
				padding-right: 0;
			}
		}

		&_select {
			@include appearance(none);
		}

		@include bp-lg {
			margin-top: 0;
		}
	}

	// Expend
	&_expand {
		--hd-height: 3.0625rem;

		position: fixed;
		z-index: 1000;
		top: var(--hd-height);
		left: 0;
		width: 100vw;
		height: calc(100vh - var(--hd-height));
		background-color: rgba($black, 0.8);
		opacity: 0;
		transition: $ms-faster opacity;
		pointer-events: none;

		@include bp-lg {
			position: relative;
			top: auto;
			height: auto;
			background-color: transparent;
			opacity: 1;
			pointer-events: all;
		}

		&_inner {
			display: flex;
			align-items: initial;
			justify-content: flex-start;
			flex-direction: column;
			overflow-x: hidden;
			overflow-y: auto;
			margin-left: auto;
			padding: 1.5rem;
			max-width: 100vw;
			height: 100%;
			background: url("img/common/nav-bg.svg") left bottom no-repeat $gray-40;
			background-size: contain;
			transform: translateX(1.875rem);
			transition: $ms-faster transform;

			@include bp-sm {
				max-width: 23.4375rem;
			}

			@include bp-lg {
				overflow: visible;
				padding: 0;
				max-width: none;
				background: transparent;
				transform: none;
			}
		}
	}

	&_switch {
		--switch-padding: 0.5rem;

		position: relative;
		padding: 0.375rem;
		width: 1.5rem;
		height: 1.5rem;
		background-color: transparent;
		border: 0;

		&::before,
		&::after {
			content: '';
			position: absolute;
			left: 0;
			display: block;
			width: 0.875rem;
			height: 0.125rem;
			background-color: $dark;
			transition: $ms-faster all;
		}

		&::before {
			top: var(--switch-padding);
			left: 0.375rem;
		}

		&::after {
			top: calc(var(--switch-padding) * 2);
			left: 0.375rem;
		}

		@include bp-lg {
			display: none;
		}
	}

	.userinfo {
		display: block;
		margin-bottom: 2.25rem;
		padding: 0.625rem;
		border-bottom: solid 0.0625rem $gray-200;

		@include bp-lg {
			display: none;
		}

		// Head
		&_head {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&_title {
			font-size: 1.125rem;
		}

		.be-icon {
			font-size: 1.5rem;
			color: $primary;
		}

		// Body
		&_body {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-top: 0.5rem;
		}

		&_level {
			margin-right: 0.5rem;
			font-size: 0.875rem;
			color: $gray-500;

			@include bp-lg {
				margin-bottom: 0.5rem;
				font-size: 1rem;
				color: $dark;
			}
		}

		&_period {
			font-size: 0.75rem;
			color: $gray-200;

			@include bp-lg {
				font-size: 0.875rem;
			}
		}

		// Nav
		&_nav {
			@extend %list-reset;

			&_link {
				display: block;
				padding: 0.625rem;
				padding: 0.875rem 1.625rem;
				font-size: 1rem;

				&:not(:hover) {
					color: $gray-500;
				}

				&.highlight {
					font-weight: 500;
					color: $primary;
				}
			}
		}

		// Box
		&_box {
			position: relative;
			z-index: 5;

			@include bp-lg {
				transition: $ms-faster ease;

				&:hover {
					color: $primary;

					.userinfo_panel {
						opacity: 1;
						transform: translate(0%, 0);
						pointer-events: all;
					}
				}
			}
		}

		// Panel
		&_panel {
			position: absolute;
			z-index: 1;
			top: 100%;
			right: 0;
			width: 13.5rem;
			text-align: left;
			background-color: $white;
			opacity: 0;
			box-shadow: 0 2px 5px 0 rgba($dark, 0.1);
			transform: translate(0%, -1.25rem);
			transition: $ms-faster ease-out;
			pointer-events: none;

			.userinfo_body {
				position: relative;
				display: block;
				margin: 0;
				padding: 0.875rem 2rem;
				background-color: $gray-40;

				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: -0.25rem;
					display: block;
					width: 9.375rem;
					height: 0.25rem;
					background-color: $primary;
					clip-path: polygon(0 0, 100% 0, 100% calc(100% - 0.25rem), calc(100% - 0.25rem) 100%, 0 100%);
				}
			}
		}
	}

	// --------------------------------------------------------
	// collapse
	&.is-collapse {
		@include bp-lg {
			.header_top {
				.header_inner {
					padding-left: 3.75rem;
				}
			}

			.header_logo {
				width: 10rem;
			}

			.header_nav_link {
				padding-top: 0.75rem;
				padding-bottom: 0.75rem;
			}

			.userinfo_box {
				padding-top: 0.25rem;
				padding-bottom: 0.25rem;
			}
		}
	}
}
