@use 'sass:map';

// --------------------------------------------------------
// Padding/Margin Helper
// --------------------------------------------------------
// 為 8 的倍數

// margin + padding
// ex:
//    .p-1, .pl-1, .px-1, .py-1
//    .m-1, .ml-1, .mx-1, .my-1, mx-n1
//    .mb-sm-1, .pt-lg-2
// @mixin spacer($class, $css, $breakpoint:"", $offset:"") {
@mixin spacerContent($class, $css, $value, $size, $offset: '', $breakKey: '') {
	@if $offset == 'offset' {
		$offset: 'n';
		$value: -#{$value};
	}

	@if $value != '-auto' and $value != '-0' {
		.#{$class}#{$breakKey}-#{$offset}#{$size} {
			#{$css}: #{$value} !important;
		}
		.#{$class}t#{$breakKey}-#{$offset}#{$size} {
			#{$css}-top: #{$value} !important;
		}
		.#{$class}b#{$breakKey}-#{$offset}#{$size} {
			#{$css}-bottom: #{$value} !important;
		}
		.#{$class}l#{$breakKey}-#{$offset}#{$size} {
			#{$css}-left: #{$value} !important;
		}
		.#{$class}r#{$breakKey}-#{$offset}#{$size} {
			#{$css}-right: #{$value} !important;
		}
		.#{$class}x#{$breakKey}-#{$offset}#{$size} {
			#{$css}-left: #{$value} !important;
			#{$css}-right: #{$value} !important;
		}
		.#{$class}y#{$breakKey}-#{$offset}#{$size} {
			#{$css}-top: #{$value} !important;
			#{$css}-bottom: #{$value} !important;
		}
	}
}

@mixin spacer($class, $css, $offset: '') {
	$newSpacings: $spacers !default;

	@if $offset != 'offset' {
		$newSpacings: map-merge(
			(
				'auto': 'auto',
				0: 0,
			),
			$spacers
		);
	}

	@each $size, $value in $newSpacings {
		@include spacerContent($class, $css, $value, $size);

		@if $class == 'm' {
			@include spacerContent($class, $css, $value, $size, 'offset');
		}
	}

	// 	@each $breakKey, $breakpoint in $breakpoints {
	// 		@media (mix-width: $breakpoint) {
	// 			@include spacerContent($class, $css, $value, $size, '', $breakKey);
	// 		}
	// 	}
}

// mt-1 ~ mt-6
@include spacer('m', 'margin');
@include spacer('p', 'padding');

// --------------------------------------------------------
