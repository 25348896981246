// Text
// -----------
.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-underline {
	text-decoration: underline;

	&:hover {
		text-decoration: underline;
	}
}

// shadow
.box-shadow {
	box-shadow: $box-shadow;
}
