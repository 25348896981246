.footer {
	@include body2;

	&_top {
		// Top
		background-color: $gray-40;

		.container {
			padding-top: 2.25rem;
			padding-bottom: 2.25rem;

			@include bp-md {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
	}

	&_brand {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 1.5rem;

		@include bp-md {
			margin-bottom: 0;
		}

		&_title {
			@include bp-md {
				display: none;
			}
		}

		&_logo {
			@include bp-xl {
				width: 13.75rem;
			}
		}
	}

	&_content {
		@include bp-md {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
		}
	}

	&_info {
		&:not(:last-child) {
			margin-bottom: 1rem;
			padding-bottom: 1rem;
			border-bottom: 0.0625rem solid $gray-200;

			@include bp-md {
				margin-bottom: 0;
				margin-right: 3.125rem;
				padding-bottom: 0;
				border: 0;
			}

			@include bp-xl {
				margin-right: 12.5rem;
			}
		}
	}

	&_info_item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		line-height: 1.4;

		&:not(:last-child) {
			margin-bottom: 0.75rem;
		}

		@include bp-md {
			justify-content: flex-start;
		}
	}

	.info_title {
		margin: 0;
		color: $gray-500;
	}

	.info_text {
		@include bp-md {
			margin-left: 0.75rem;
		}
	}

	// Bottom
	&_bottom {
		padding-top: 2.25rem;
		padding-bottom: 2.25rem;
		line-height: 1.4;
		color: $gray-40;
		background-color: $dark;

		@include bp-md {
			padding-top: 0.875rem;
			padding-bottom: 0.875rem;
		}
	}

	&_copyright {
		display: flex;
		flex-direction: column-reverse;

		@include bp-md {
			align-items: center;
			justify-content: space-between;
			flex-direction: row;
		}
	}

	&_terms {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		margin-bottom: 1.5rem;
		padding-bottom: 1.5rem;
		border-bottom: 0.0625rem solid $gray-500;

		@include bp-md {
			flex-direction: row;
			margin: 0;
			padding: 0;
			border: 0;
		}
	}

	&_terms_item {
		--pos-right: 2rem;

		position: relative;
		color: inherit;

		@include bp-xl {
			--pos-right: 3rem;
		}

		&:not(:last-child) {
			margin-bottom: 0.5rem;

			@include bp-md {
				margin-bottom: 0;
				margin-right: var(--pos-right);

				&::after {
					content: '';
					position: absolute;
					top: 50%;
					right: calc(var(--pos-right) / 2 * -1);
					display: block;
					width: 0.0625rem;
					height: 100%;
					background-color: $gray-40;
					transform: translateY(-50%);
				}
			}
		}
	}
}
