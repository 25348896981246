.card-article {
	display: flex;
	flex-direction: column;
	height: 100%;

	.embed {
		&::before {
			padding-top: percentage(200 / 300);
		}
	}

	.card_txt {
		position: relative;
		z-index: 1;
		flex: 1;
		margin: -1.3125rem auto 0;
		padding: 1rem;
		width: calc(100% - 1.25rem);
		background-color: $white;
		box-shadow: 2px 2px 30px 0 rgba($dark, 0.04);

		@include bp-xl {
			margin-top: -3.25rem;
			width: calc(100% - 2.25rem);
		}
	}

	.mark {
		top: 0;
		left: 0;
		color: $white;
	}

	&.hover {
		@include bp-lg {
			.embed_media {
				transition: transform $ms-fast cubic-bezier(0.39, 0.575, 0.565, 1);
			}

			&:hover {
				&.mask {
					&::after {
						opacity: 1;
					}
				}

				.embed_media {
					transform: scale(1.1);
				}
			}
		}
	}
}

// icon 的白色卡片
.card-info {
	$this: '.card';

	display: flex;
	flex-direction: column;
	padding: 1rem;
	background-color: $white;
	box-shadow: 2px 2px 30px 0 rgba($dark, 0.04);

	@include bp-md {
		padding: 2.25rem 1.25rem;
	}

	#{$this}_icon {
		display: flex;
		margin-bottom: 0.75rem;
		font-size: 3rem;
	}

	#{$this}_title {
		margin: 0 0 0.75rem;
		font-size: 1.125rem;
	}

	#{$this}_info {
		margin: 0 0 2.25rem;
	}

	#{$this}_btn {
		margin-top: auto;
	}
}

// 灰色 head 卡片
.card-result {
	$this: '.card';

	border: .0625rem solid $gray-200;

	// Head
	#{$this}_head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: .75rem 1.5rem;
		background-color: $gray-40;
	}

	#{$this}_head_title {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 0;
		line-height: (26 / 18);

		@include bp-xl {
			font-size: 1.125rem;
		}
	}

	#{$this}_head_note {
		margin-left: .75rem;
		padding-left: .75rem;
		border-left: 1px solid $dark;
		flex-shrink: 0;
	}

	// Body
	#{$this}_body {
		padding: 0 1.5rem;
	}

	.list {
		@extend %list-reset;
	}

	.list_item {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: .75rem 0;
		border-bottom: 1px solid $gray-40;

		.txt:last-child {
			margin-left: 1rem;
			min-width: 3rem;
			flex-shrink: 0;
			text-align: right;
		}
	}
}

// ----------------------------------------
.row-cards {
	margin: 0;
	padding: 0;
	list-style: none;

	&_li {
		display: flex;
		flex-direction: column;
		padding: remy(36) remy(16);

		@include bp-md {
			flex-direction: row;
		}

		&:not(:last-of-type) {
			border-bottom: remy(1) solid $gray-200;
		}
	}

	&_img {
		margin-bottom: remy(10);
		width: 100%;

		@include bp-md {
			margin-bottom: 0;
			margin-right: remy(24);
			width: percentage(300 / 908);
		}

		&.object-fit {
			&::before {
				padding-top: percentage(200 / 300);
			}
		}
	}

	&_info {
		display: flex;
		flex-direction: column;
		width: 100%;

		@include bp-md {
			width: percentage(584 / 908);
		}
	}

	&_title {
		margin: 0 0 remy(12);
		font-size: remy(18);
		font-weight: 500;
	}

	&_txt {
		margin: 0 0 remy(24);
	}

	&_bottom {
		align-items: flex-start;
		flex-direction: column;
		margin-top: auto;

		@include bp-md {
			align-items: flex-end;
			justify-content: space-between;
			flex-direction: row;
		}
	}

	&_annotation {
		margin: 0 0 remy(10);
		font-size: 0.875rem;
		color: $gray-400;

		@include bp-md {
			margin: 0 remy(10) 0 0;
		}
	}

	.btn-outline {
		width: 100%;

		@include bp-md {
			width: auto;
		}
	}
}

// ----------------------------------------
.swiper-cards {
	&.swiper-container {
		overflow: initial;
	}

	.swiper-wrapper {
		padding-bottom: remy(20);
	}

	.swiper-slide {
		height: auto;
	}

	&.has-mark {
		.swiper-slide {
			padding-top: remy(14);
			padding-left: remy(8);
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		display: none;

		@include bp-lg {
			display: flex;
		}
	}
}
