/* stylelint-disable no-invalid-position-at-import-rule */
/*
    $h: height,
    $lh: line-height,
    $bw: border-width,
    $fs: font-size
*/
[disabled],
.is\:off {
  background: #fff;
  cursor: default;
  pointer-events: none;
}

.header_nav, .header_subnav, .header .userinfo_nav, .card-result .list, .news-list, .file_list {
  margin: 0;
  padding: 0;
  list-style: none;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-family: 'Noto Sans TC', serif;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  text-size-adjust: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

:root {
  -moz-tab-size: 4;
  tab-size: 4;
  --vi-primary: #d93d28;
  --vi-secondary: #0f4c81;
  --vi-light: #f8f8f8;
  --vi-dark: #464646;
  --is-success: #69b076;
  --is-warning: #fc7220;
  --is-danger: #cd3b3b;
}

@viewport {
  width: device-width;
}

body {
  margin: 0;
}

[tabindex='-1']:focus {
  outline: 0;
}

blockquote,
caption,
dd,
div,
dt,
h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
th,
td {
  word-wrap: break-word;
  color: inherit;
}

.firefox pre {
  white-space: pre-line !important;
  -moz-hyphens: auto;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

mark,
output {
  display: inline-block;
}

summary {
  display: block;
  cursor: pointer;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: baseline;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

figure {
  margin: 0 0 1rem;
}

iframe {
  border: none;
}

xmp,
plaintext,
listing {
  font-family: inherit;
  white-space: normal;
}

[hidden],
template {
  display: none;
}

fieldset {
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

ol,
ul,
dl {
  margin-top: 0.625em;
  margin-bottom: 1.25em;
}

ol:first-child,
ul:first-child,
dl:first-child {
  margin-top: 0;
}

ol:last-child,
ul:last-child,
dl:last-child {
  margin-bottom: 0;
}

a {
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:active, a:hover, a:focus {
  text-decoration: none;
  outline: 0;
}

abbr,
acronym {
  font-variant: normal;
  cursor: help;
}

abbr[title],
acronym[title] {
  border-bottom: 0;
}

address,
cite,
dfn,
i,
u {
  font-style: normal;
  text-decoration: none;
}

[lang^='zh'] em {
  font-style: normal;
}

blockquote,
q {
  quotes: none;
}

hr {
  height: 0;
}

pre {
  overflow: auto;
}

b,
strong {
  font-weight: 600;
}

small {
  font-size: 87.5%;
}

sup,
sub {
  font-size: 75%;
  line-height: 0;
}

table {
  width: 100%;
  text-align: left;
  color: inherit;
  border-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  font: inherit;
}

table,
td,
th {
  table-layout: auto;
}

td,
th {
  padding: 0;
  outline: 0;
  vertical-align: inherit;
}

caption,
center,
th,
td {
  text-align: inherit;
}

label {
  display: inline-block;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font: inherit;
}

button,
input {
  overflow: visible;
}

input,
select {
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  outline: 0;
  -webkit-appearance: button;
  appearance: button;
  cursor: pointer;
}

button:-moz-focusring,
input[type='button']:-moz-focusring,
input[type='reset']:-moz-focusring,
input[type='submit']:-moz-focusring {
  outline: 0;
}

/* stylelint-disable-next-line no-duplicate-selectors */
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input {
  line-height: normal;
}

input[type='checkbox'], input[type='radio'] {
  padding: 0;
}

input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

input[type='search'] {
  -webkit-appearance: textfield;
  appearance: textfield;
}

input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

input[type='date'], input[type='time'], input[type='datetime-local'], input[type='month'] {
  -webkit-appearance: listbox;
  appearance: listbox;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
  appearance: button;
}

legend {
  padding: 0;
  border: none;
}

textarea {
  overflow: auto;
  resize: vertical;
}

:focus {
  outline: 0;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-underline {
  text-decoration: underline;
}

.text-underline:hover {
  text-decoration: underline;
}

.box-shadow {
  box-shadow: -2px 0 20px 0 rgba(70, 70, 70, 0.06);
}

@font-face {
  font-family: "icon";
  src: url("fonts/icons/icon.eot");
  src: url("fonts/icons/icon.eot?#iefix") format("embedded-opentype"), url("fonts/icons/icon.woff2") format("woff2"), url("fonts/icons/icon.woff") format("woff"), url("fonts/icons/icon.ttf") format("truetype"), url("fonts/icons/icon.svg#icon") format("svg");
  font-display: block;
  unicode-range: U+EA01-EA29;
}

.be-icon {
  font-family: sans-serif;
  line-height: 1;
}

.be-icon:before {
  font-family: "icon";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  line-height: 1em;
}

/* Glyphs list */
.be-icon-alert:before {
  content: '\ea01';
}

.be-icon-arrow-next:before {
  content: '\ea02';
}

.be-icon-arrow-prev:before {
  content: '\ea03';
}

.be-icon-asterisk:before {
  content: '\ea04';
}

.be-icon-book:before {
  content: '\ea05';
}

.be-icon-calc:before {
  content: '\ea06';
}

.be-icon-calendar:before {
  content: '\ea07';
}

.be-icon-chat:before {
  content: '\ea08';
}

.be-icon-checkbox:before {
  content: '\ea09';
}

.be-icon-checked-circle:before {
  content: '\ea0a';
}

.be-icon-checked:before {
  content: '\ea0b';
}

.be-icon-close:before {
  content: '\ea0c';
}

.be-icon-doc:before {
  content: '\ea0d';
}

.be-icon-document:before {
  content: '\ea0e';
}

.be-icon-down:before {
  content: '\ea0f';
}

.be-icon-download:before {
  content: '\ea10';
}

.be-icon-ellipsis:before {
  content: '\ea11';
}

.be-icon-eye-close:before {
  content: '\ea12';
}

.be-icon-eye-open:before {
  content: '\ea13';
}

.be-icon-filter:before {
  content: '\ea14';
}

.be-icon-language:before {
  content: '\ea15';
}

.be-icon-link:before {
  content: '\ea16';
}

.be-icon-lock-success:before {
  content: '\ea17';
}

.be-icon-lock:before {
  content: '\ea18';
}

.be-icon-login:before {
  content: '\ea19';
}

.be-icon-member-verified:before {
  content: '\ea1a';
}

.be-icon-minus:before {
  content: '\ea1b';
}

.be-icon-next-arrow:before {
  content: '\ea1c';
}

.be-icon-next:before {
  content: '\ea1d';
}

.be-icon-overtime:before {
  content: '\ea1e';
}

.be-icon-pdf:before {
  content: '\ea1f';
}

.be-icon-plus:before {
  content: '\ea20';
}

.be-icon-prev:before {
  content: '\ea21';
}

.be-icon-register:before {
  content: '\ea22';
}

.be-icon-repatriation:before {
  content: '\ea23';
}

.be-icon-retire:before {
  content: '\ea24';
}

.be-icon-tool:before {
  content: '\ea25';
}

.be-icon-up:before {
  content: '\ea26';
}

.be-icon-vacation:before {
  content: '\ea27';
}

.be-icon-vote:before {
  content: '\ea28';
}

.be-icon-xls:before {
  content: '\ea29';
}

.container, .container-fluid {
  position: relative;
  z-index: 1;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

@media (min-width: 1024px) {
  .container, .container-fluid {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
}

@media (min-width: 1440px) {
  .container, .container-fluid {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
}

.container {
  max-width: 100%;
}

.f-row,
[class*='f-col'] {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

.f-row {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.f-row > hr,
.f-row > .divider {
  flex: 1 0 100%;
  max-width: 100%;
}

.f-row > [class*='f-col'] {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

[class*='f-col'] {
  position: relative;
  z-index: 1;
  flex: 1 0 100%;
  order: 0;
  max-width: 100%;
}

.f-col {
  flex-basis: 0;
}

.f-col\:auto {
  flex-basis: auto;
}

.f-col\:1 {
  flex-basis: 8.33333%;
  max-width: 8.33333%;
}

.f-col\:2 {
  flex-basis: 16.66667%;
  max-width: 16.66667%;
}

.f-col\:3 {
  flex-basis: 25%;
  max-width: 25%;
}

.f-col\:4 {
  flex-basis: 33.33333%;
  max-width: 33.33333%;
}

.f-col\:5 {
  flex-basis: 41.66667%;
  max-width: 41.66667%;
}

.f-col\:6 {
  flex-basis: 50%;
  max-width: 50%;
}

.f-col\:7 {
  flex-basis: 58.33333%;
  max-width: 58.33333%;
}

.f-col\:8 {
  flex-basis: 66.66667%;
  max-width: 66.66667%;
}

.f-col\:9 {
  flex-basis: 75%;
  max-width: 75%;
}

.f-col\:10 {
  flex-basis: 83.33333%;
  max-width: 83.33333%;
}

.f-col\:11 {
  flex-basis: 91.66667%;
  max-width: 91.66667%;
}

.f-col\:12 {
  flex-basis: 100%;
  max-width: 100%;
}

.f-offset\:0 {
  margin-left: 0%;
}

.f-offset\:1 {
  margin-left: 8.33333%;
}

.f-offset\:2 {
  margin-left: 16.66667%;
}

.f-offset\:3 {
  margin-left: 25%;
}

.f-offset\:4 {
  margin-left: 33.33333%;
}

.f-offset\:5 {
  margin-left: 41.66667%;
}

.f-offset\:6 {
  margin-left: 50%;
}

.f-offset\:7 {
  margin-left: 58.33333%;
}

.f-offset\:8 {
  margin-left: 66.66667%;
}

.f-offset\:9 {
  margin-left: 75%;
}

.f-offset\:10 {
  margin-left: 83.33333%;
}

.f-offset\:11 {
  margin-left: 91.66667%;
}

.f-offset\:12 {
  margin-left: 100%;
}

.f-order\:-1 {
  order: -1;
}

.f-order\:0 {
  order: 0;
}

.f-order\:1 {
  order: 1;
}

.f-order\:2 {
  order: 2;
}

.f-order\:3 {
  order: 3;
}

.f-order\:4 {
  order: 4;
}

.f-order\:5 {
  order: 5;
}

.f-order\:6 {
  order: 6;
}

.f-order\:7 {
  order: 7;
}

.f-order\:8 {
  order: 8;
}

.f-order\:9 {
  order: 9;
}

.f-order\:10 {
  order: 10;
}

.f-order\:11 {
  order: 11;
}

.f-order\:12 {
  order: 12;
}

.f-order\:13 {
  order: 13;
}

.f-flex\:fluid {
  flex-grow: 1;
  flex-shrink: 1;
}

.f-flex\:fixed {
  flex-grow: 0;
  flex-shrink: 0;
}

.f-direction\:r {
  flex-direction: row !important;
}

.f-direction\:rr {
  flex-direction: row-reverse !important;
}

.f-direction\:c {
  flex-direction: column !important;
}

.f-direction\:cr {
  flex-direction: column-reverse !important;
}

.f-wrap\:nw {
  flex-wrap: nowrap !important;
}

.f-wrap\:w {
  flex-wrap: wrap !important;
}

.f-wrap\:r {
  flex-wrap: revert !important;
}

.f-wrap\:wr {
  flex-wrap: wrap-reverse !important;
}

.f-row.f-nogap {
  margin-right: 0;
  margin-left: 0;
}

.f-row.f-nogap > [class*='f-col'] {
  padding-right: 0;
  padding-left: 0;
}

[class*='f-col'].f-nogap {
  padding-right: 0;
  padding-left: 0;
}

.f-align\:l {
  justify-content: flex-start !important;
}

.f-align\:c {
  justify-content: center !important;
}

.f-align\:r {
  justify-content: flex-end !important;
}

.f-align\:j {
  justify-content: space-between !important;
}

.f-align\:a {
  justify-content: space-around !important;
}

.f-align\:e {
  justify-content: space-evenly !important;
}

.f-align\:t {
  align-items: flex-start !important;
}

.f-align\:m {
  align-items: center !important;
}

.f-align\:b {
  align-items: flex-end !important;
}

.f-align\:s {
  align-items: stretch !important;
}

.f-col-align\:t {
  align-self: flex-start !important;
}

.f-col-align\:m {
  align-self: center !important;
}

.f-col-align\:b {
  align-self: flex-end !important;
}

.f-col-align\:s {
  align-self: stretch !important;
}

/* stylelint-disable-next-line media-query-no-invalid */
@media (min-width: 576px) {
  .f-col\@sm {
    flex-basis: 0;
  }
  .f-col\@sm\:auto {
    flex-basis: auto;
  }
  .f-col\@sm\:1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .f-col\@sm\:2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .f-col\@sm\:3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .f-col\@sm\:4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .f-col\@sm\:5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .f-col\@sm\:6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .f-col\@sm\:7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .f-col\@sm\:8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .f-col\@sm\:9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .f-col\@sm\:10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .f-col\@sm\:11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .f-col\@sm\:12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .f-offset\@sm\:0 {
    margin-left: 0%;
  }
  .f-offset\@sm\:1 {
    margin-left: 8.33333%;
  }
  .f-offset\@sm\:2 {
    margin-left: 16.66667%;
  }
  .f-offset\@sm\:3 {
    margin-left: 25%;
  }
  .f-offset\@sm\:4 {
    margin-left: 33.33333%;
  }
  .f-offset\@sm\:5 {
    margin-left: 41.66667%;
  }
  .f-offset\@sm\:6 {
    margin-left: 50%;
  }
  .f-offset\@sm\:7 {
    margin-left: 58.33333%;
  }
  .f-offset\@sm\:8 {
    margin-left: 66.66667%;
  }
  .f-offset\@sm\:9 {
    margin-left: 75%;
  }
  .f-offset\@sm\:10 {
    margin-left: 83.33333%;
  }
  .f-offset\@sm\:11 {
    margin-left: 91.66667%;
  }
  .f-offset\@sm\:12 {
    margin-left: 100%;
  }
  .f-order\@sm\:-1 {
    order: -1;
  }
  .f-order\@sm\:0 {
    order: 0;
  }
  .f-order\@sm\:1 {
    order: 1;
  }
  .f-order\@sm\:2 {
    order: 2;
  }
  .f-order\@sm\:3 {
    order: 3;
  }
  .f-order\@sm\:4 {
    order: 4;
  }
  .f-order\@sm\:5 {
    order: 5;
  }
  .f-order\@sm\:6 {
    order: 6;
  }
  .f-order\@sm\:7 {
    order: 7;
  }
  .f-order\@sm\:8 {
    order: 8;
  }
  .f-order\@sm\:9 {
    order: 9;
  }
  .f-order\@sm\:10 {
    order: 10;
  }
  .f-order\@sm\:11 {
    order: 11;
  }
  .f-order\@sm\:12 {
    order: 12;
  }
  .f-order\@sm\:13 {
    order: 13;
  }
  .f-flex\@sm\:fluid {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .f-flex\@sm\:fixed {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .f-direction\@sm\:r {
    flex-direction: row !important;
  }
  .f-direction\@sm\:rr {
    flex-direction: row-reverse !important;
  }
  .f-direction\@sm\:c {
    flex-direction: column !important;
  }
  .f-direction\@sm\:cr {
    flex-direction: column-reverse !important;
  }
  .f-wrap\@sm\:nw {
    flex-wrap: nowrap !important;
  }
  .f-wrap\@sm\:w {
    flex-wrap: wrap !important;
  }
  .f-wrap\@sm\:r {
    flex-wrap: revert !important;
  }
  .f-wrap\@sm\:wr {
    flex-wrap: wrap-reverse !important;
  }
  .f-row.f-nogap\@sm {
    margin-right: 0;
    margin-left: 0;
  }
  .f-row.f-nogap\@sm > [class*='f-col'] {
    padding-right: 0;
    padding-left: 0;
  }
  [class*='f-col'].f-nogap\@sm {
    padding-right: 0;
    padding-left: 0;
  }
  .f-align\@sm\:l {
    justify-content: flex-start !important;
  }
  .f-align\@sm\:c {
    justify-content: center !important;
  }
  .f-align\@sm\:r {
    justify-content: flex-end !important;
  }
  .f-align\@sm\:j {
    justify-content: space-between !important;
  }
  .f-align\@sm\:a {
    justify-content: space-around !important;
  }
  .f-align\@sm\:e {
    justify-content: space-evenly !important;
  }
  .f-align\@sm\:t {
    align-items: flex-start !important;
  }
  .f-align\@sm\:m {
    align-items: center !important;
  }
  .f-align\@sm\:b {
    align-items: flex-end !important;
  }
  .f-align\@sm\:s {
    align-items: stretch !important;
  }
  .f-col-align\@sm\:t {
    align-self: flex-start !important;
  }
  .f-col-align\@sm\:m {
    align-self: center !important;
  }
  .f-col-align\@sm\:b {
    align-self: flex-end !important;
  }
  .f-col-align\@sm\:s {
    align-self: stretch !important;
  }
}

/* stylelint-disable-next-line media-query-no-invalid */
@media (min-width: 768px) {
  .f-col\@md {
    flex-basis: 0;
  }
  .f-col\@md\:auto {
    flex-basis: auto;
  }
  .f-col\@md\:1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .f-col\@md\:2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .f-col\@md\:3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .f-col\@md\:4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .f-col\@md\:5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .f-col\@md\:6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .f-col\@md\:7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .f-col\@md\:8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .f-col\@md\:9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .f-col\@md\:10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .f-col\@md\:11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .f-col\@md\:12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .f-offset\@md\:0 {
    margin-left: 0%;
  }
  .f-offset\@md\:1 {
    margin-left: 8.33333%;
  }
  .f-offset\@md\:2 {
    margin-left: 16.66667%;
  }
  .f-offset\@md\:3 {
    margin-left: 25%;
  }
  .f-offset\@md\:4 {
    margin-left: 33.33333%;
  }
  .f-offset\@md\:5 {
    margin-left: 41.66667%;
  }
  .f-offset\@md\:6 {
    margin-left: 50%;
  }
  .f-offset\@md\:7 {
    margin-left: 58.33333%;
  }
  .f-offset\@md\:8 {
    margin-left: 66.66667%;
  }
  .f-offset\@md\:9 {
    margin-left: 75%;
  }
  .f-offset\@md\:10 {
    margin-left: 83.33333%;
  }
  .f-offset\@md\:11 {
    margin-left: 91.66667%;
  }
  .f-offset\@md\:12 {
    margin-left: 100%;
  }
  .f-order\@md\:-1 {
    order: -1;
  }
  .f-order\@md\:0 {
    order: 0;
  }
  .f-order\@md\:1 {
    order: 1;
  }
  .f-order\@md\:2 {
    order: 2;
  }
  .f-order\@md\:3 {
    order: 3;
  }
  .f-order\@md\:4 {
    order: 4;
  }
  .f-order\@md\:5 {
    order: 5;
  }
  .f-order\@md\:6 {
    order: 6;
  }
  .f-order\@md\:7 {
    order: 7;
  }
  .f-order\@md\:8 {
    order: 8;
  }
  .f-order\@md\:9 {
    order: 9;
  }
  .f-order\@md\:10 {
    order: 10;
  }
  .f-order\@md\:11 {
    order: 11;
  }
  .f-order\@md\:12 {
    order: 12;
  }
  .f-order\@md\:13 {
    order: 13;
  }
  .f-flex\@md\:fluid {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .f-flex\@md\:fixed {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .f-direction\@md\:r {
    flex-direction: row !important;
  }
  .f-direction\@md\:rr {
    flex-direction: row-reverse !important;
  }
  .f-direction\@md\:c {
    flex-direction: column !important;
  }
  .f-direction\@md\:cr {
    flex-direction: column-reverse !important;
  }
  .f-wrap\@md\:nw {
    flex-wrap: nowrap !important;
  }
  .f-wrap\@md\:w {
    flex-wrap: wrap !important;
  }
  .f-wrap\@md\:r {
    flex-wrap: revert !important;
  }
  .f-wrap\@md\:wr {
    flex-wrap: wrap-reverse !important;
  }
  .f-row.f-nogap\@md {
    margin-right: 0;
    margin-left: 0;
  }
  .f-row.f-nogap\@md > [class*='f-col'] {
    padding-right: 0;
    padding-left: 0;
  }
  [class*='f-col'].f-nogap\@md {
    padding-right: 0;
    padding-left: 0;
  }
  .f-align\@md\:l {
    justify-content: flex-start !important;
  }
  .f-align\@md\:c {
    justify-content: center !important;
  }
  .f-align\@md\:r {
    justify-content: flex-end !important;
  }
  .f-align\@md\:j {
    justify-content: space-between !important;
  }
  .f-align\@md\:a {
    justify-content: space-around !important;
  }
  .f-align\@md\:e {
    justify-content: space-evenly !important;
  }
  .f-align\@md\:t {
    align-items: flex-start !important;
  }
  .f-align\@md\:m {
    align-items: center !important;
  }
  .f-align\@md\:b {
    align-items: flex-end !important;
  }
  .f-align\@md\:s {
    align-items: stretch !important;
  }
  .f-col-align\@md\:t {
    align-self: flex-start !important;
  }
  .f-col-align\@md\:m {
    align-self: center !important;
  }
  .f-col-align\@md\:b {
    align-self: flex-end !important;
  }
  .f-col-align\@md\:s {
    align-self: stretch !important;
  }
}

/* stylelint-disable-next-line media-query-no-invalid */
@media (min-width: 1024px) {
  .f-col\@lg {
    flex-basis: 0;
  }
  .f-col\@lg\:auto {
    flex-basis: auto;
  }
  .f-col\@lg\:1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .f-col\@lg\:2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .f-col\@lg\:3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .f-col\@lg\:4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .f-col\@lg\:5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .f-col\@lg\:6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .f-col\@lg\:7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .f-col\@lg\:8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .f-col\@lg\:9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .f-col\@lg\:10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .f-col\@lg\:11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .f-col\@lg\:12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .f-offset\@lg\:0 {
    margin-left: 0%;
  }
  .f-offset\@lg\:1 {
    margin-left: 8.33333%;
  }
  .f-offset\@lg\:2 {
    margin-left: 16.66667%;
  }
  .f-offset\@lg\:3 {
    margin-left: 25%;
  }
  .f-offset\@lg\:4 {
    margin-left: 33.33333%;
  }
  .f-offset\@lg\:5 {
    margin-left: 41.66667%;
  }
  .f-offset\@lg\:6 {
    margin-left: 50%;
  }
  .f-offset\@lg\:7 {
    margin-left: 58.33333%;
  }
  .f-offset\@lg\:8 {
    margin-left: 66.66667%;
  }
  .f-offset\@lg\:9 {
    margin-left: 75%;
  }
  .f-offset\@lg\:10 {
    margin-left: 83.33333%;
  }
  .f-offset\@lg\:11 {
    margin-left: 91.66667%;
  }
  .f-offset\@lg\:12 {
    margin-left: 100%;
  }
  .f-order\@lg\:-1 {
    order: -1;
  }
  .f-order\@lg\:0 {
    order: 0;
  }
  .f-order\@lg\:1 {
    order: 1;
  }
  .f-order\@lg\:2 {
    order: 2;
  }
  .f-order\@lg\:3 {
    order: 3;
  }
  .f-order\@lg\:4 {
    order: 4;
  }
  .f-order\@lg\:5 {
    order: 5;
  }
  .f-order\@lg\:6 {
    order: 6;
  }
  .f-order\@lg\:7 {
    order: 7;
  }
  .f-order\@lg\:8 {
    order: 8;
  }
  .f-order\@lg\:9 {
    order: 9;
  }
  .f-order\@lg\:10 {
    order: 10;
  }
  .f-order\@lg\:11 {
    order: 11;
  }
  .f-order\@lg\:12 {
    order: 12;
  }
  .f-order\@lg\:13 {
    order: 13;
  }
  .f-flex\@lg\:fluid {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .f-flex\@lg\:fixed {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .f-direction\@lg\:r {
    flex-direction: row !important;
  }
  .f-direction\@lg\:rr {
    flex-direction: row-reverse !important;
  }
  .f-direction\@lg\:c {
    flex-direction: column !important;
  }
  .f-direction\@lg\:cr {
    flex-direction: column-reverse !important;
  }
  .f-wrap\@lg\:nw {
    flex-wrap: nowrap !important;
  }
  .f-wrap\@lg\:w {
    flex-wrap: wrap !important;
  }
  .f-wrap\@lg\:r {
    flex-wrap: revert !important;
  }
  .f-wrap\@lg\:wr {
    flex-wrap: wrap-reverse !important;
  }
  .f-row.f-nogap\@lg {
    margin-right: 0;
    margin-left: 0;
  }
  .f-row.f-nogap\@lg > [class*='f-col'] {
    padding-right: 0;
    padding-left: 0;
  }
  [class*='f-col'].f-nogap\@lg {
    padding-right: 0;
    padding-left: 0;
  }
  .f-align\@lg\:l {
    justify-content: flex-start !important;
  }
  .f-align\@lg\:c {
    justify-content: center !important;
  }
  .f-align\@lg\:r {
    justify-content: flex-end !important;
  }
  .f-align\@lg\:j {
    justify-content: space-between !important;
  }
  .f-align\@lg\:a {
    justify-content: space-around !important;
  }
  .f-align\@lg\:e {
    justify-content: space-evenly !important;
  }
  .f-align\@lg\:t {
    align-items: flex-start !important;
  }
  .f-align\@lg\:m {
    align-items: center !important;
  }
  .f-align\@lg\:b {
    align-items: flex-end !important;
  }
  .f-align\@lg\:s {
    align-items: stretch !important;
  }
  .f-col-align\@lg\:t {
    align-self: flex-start !important;
  }
  .f-col-align\@lg\:m {
    align-self: center !important;
  }
  .f-col-align\@lg\:b {
    align-self: flex-end !important;
  }
  .f-col-align\@lg\:s {
    align-self: stretch !important;
  }
}

/* stylelint-disable-next-line media-query-no-invalid */
@media (min-width: 1440px) {
  .f-col\@xl {
    flex-basis: 0;
  }
  .f-col\@xl\:auto {
    flex-basis: auto;
  }
  .f-col\@xl\:1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .f-col\@xl\:2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .f-col\@xl\:3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .f-col\@xl\:4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .f-col\@xl\:5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .f-col\@xl\:6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .f-col\@xl\:7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .f-col\@xl\:8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .f-col\@xl\:9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .f-col\@xl\:10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .f-col\@xl\:11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .f-col\@xl\:12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .f-offset\@xl\:0 {
    margin-left: 0%;
  }
  .f-offset\@xl\:1 {
    margin-left: 8.33333%;
  }
  .f-offset\@xl\:2 {
    margin-left: 16.66667%;
  }
  .f-offset\@xl\:3 {
    margin-left: 25%;
  }
  .f-offset\@xl\:4 {
    margin-left: 33.33333%;
  }
  .f-offset\@xl\:5 {
    margin-left: 41.66667%;
  }
  .f-offset\@xl\:6 {
    margin-left: 50%;
  }
  .f-offset\@xl\:7 {
    margin-left: 58.33333%;
  }
  .f-offset\@xl\:8 {
    margin-left: 66.66667%;
  }
  .f-offset\@xl\:9 {
    margin-left: 75%;
  }
  .f-offset\@xl\:10 {
    margin-left: 83.33333%;
  }
  .f-offset\@xl\:11 {
    margin-left: 91.66667%;
  }
  .f-offset\@xl\:12 {
    margin-left: 100%;
  }
  .f-order\@xl\:-1 {
    order: -1;
  }
  .f-order\@xl\:0 {
    order: 0;
  }
  .f-order\@xl\:1 {
    order: 1;
  }
  .f-order\@xl\:2 {
    order: 2;
  }
  .f-order\@xl\:3 {
    order: 3;
  }
  .f-order\@xl\:4 {
    order: 4;
  }
  .f-order\@xl\:5 {
    order: 5;
  }
  .f-order\@xl\:6 {
    order: 6;
  }
  .f-order\@xl\:7 {
    order: 7;
  }
  .f-order\@xl\:8 {
    order: 8;
  }
  .f-order\@xl\:9 {
    order: 9;
  }
  .f-order\@xl\:10 {
    order: 10;
  }
  .f-order\@xl\:11 {
    order: 11;
  }
  .f-order\@xl\:12 {
    order: 12;
  }
  .f-order\@xl\:13 {
    order: 13;
  }
  .f-flex\@xl\:fluid {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .f-flex\@xl\:fixed {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .f-direction\@xl\:r {
    flex-direction: row !important;
  }
  .f-direction\@xl\:rr {
    flex-direction: row-reverse !important;
  }
  .f-direction\@xl\:c {
    flex-direction: column !important;
  }
  .f-direction\@xl\:cr {
    flex-direction: column-reverse !important;
  }
  .f-wrap\@xl\:nw {
    flex-wrap: nowrap !important;
  }
  .f-wrap\@xl\:w {
    flex-wrap: wrap !important;
  }
  .f-wrap\@xl\:r {
    flex-wrap: revert !important;
  }
  .f-wrap\@xl\:wr {
    flex-wrap: wrap-reverse !important;
  }
  .f-row.f-nogap\@xl {
    margin-right: 0;
    margin-left: 0;
  }
  .f-row.f-nogap\@xl > [class*='f-col'] {
    padding-right: 0;
    padding-left: 0;
  }
  [class*='f-col'].f-nogap\@xl {
    padding-right: 0;
    padding-left: 0;
  }
  .f-align\@xl\:l {
    justify-content: flex-start !important;
  }
  .f-align\@xl\:c {
    justify-content: center !important;
  }
  .f-align\@xl\:r {
    justify-content: flex-end !important;
  }
  .f-align\@xl\:j {
    justify-content: space-between !important;
  }
  .f-align\@xl\:a {
    justify-content: space-around !important;
  }
  .f-align\@xl\:e {
    justify-content: space-evenly !important;
  }
  .f-align\@xl\:t {
    align-items: flex-start !important;
  }
  .f-align\@xl\:m {
    align-items: center !important;
  }
  .f-align\@xl\:b {
    align-items: flex-end !important;
  }
  .f-align\@xl\:s {
    align-items: stretch !important;
  }
  .f-col-align\@xl\:t {
    align-self: flex-start !important;
  }
  .f-col-align\@xl\:m {
    align-self: center !important;
  }
  .f-col-align\@xl\:b {
    align-self: flex-end !important;
  }
  .f-col-align\@xl\:s {
    align-self: stretch !important;
  }
}

/* stylelint-disable-next-line media-query-no-invalid */
@media (min-width: 1920px) {
  .f-col\@max {
    flex-basis: 0;
  }
  .f-col\@max\:auto {
    flex-basis: auto;
  }
  .f-col\@max\:1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .f-col\@max\:2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .f-col\@max\:3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .f-col\@max\:4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .f-col\@max\:5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .f-col\@max\:6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .f-col\@max\:7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .f-col\@max\:8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .f-col\@max\:9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .f-col\@max\:10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .f-col\@max\:11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .f-col\@max\:12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .f-offset\@max\:0 {
    margin-left: 0%;
  }
  .f-offset\@max\:1 {
    margin-left: 8.33333%;
  }
  .f-offset\@max\:2 {
    margin-left: 16.66667%;
  }
  .f-offset\@max\:3 {
    margin-left: 25%;
  }
  .f-offset\@max\:4 {
    margin-left: 33.33333%;
  }
  .f-offset\@max\:5 {
    margin-left: 41.66667%;
  }
  .f-offset\@max\:6 {
    margin-left: 50%;
  }
  .f-offset\@max\:7 {
    margin-left: 58.33333%;
  }
  .f-offset\@max\:8 {
    margin-left: 66.66667%;
  }
  .f-offset\@max\:9 {
    margin-left: 75%;
  }
  .f-offset\@max\:10 {
    margin-left: 83.33333%;
  }
  .f-offset\@max\:11 {
    margin-left: 91.66667%;
  }
  .f-offset\@max\:12 {
    margin-left: 100%;
  }
  .f-order\@max\:-1 {
    order: -1;
  }
  .f-order\@max\:0 {
    order: 0;
  }
  .f-order\@max\:1 {
    order: 1;
  }
  .f-order\@max\:2 {
    order: 2;
  }
  .f-order\@max\:3 {
    order: 3;
  }
  .f-order\@max\:4 {
    order: 4;
  }
  .f-order\@max\:5 {
    order: 5;
  }
  .f-order\@max\:6 {
    order: 6;
  }
  .f-order\@max\:7 {
    order: 7;
  }
  .f-order\@max\:8 {
    order: 8;
  }
  .f-order\@max\:9 {
    order: 9;
  }
  .f-order\@max\:10 {
    order: 10;
  }
  .f-order\@max\:11 {
    order: 11;
  }
  .f-order\@max\:12 {
    order: 12;
  }
  .f-order\@max\:13 {
    order: 13;
  }
  .f-flex\@max\:fluid {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .f-flex\@max\:fixed {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .f-direction\@max\:r {
    flex-direction: row !important;
  }
  .f-direction\@max\:rr {
    flex-direction: row-reverse !important;
  }
  .f-direction\@max\:c {
    flex-direction: column !important;
  }
  .f-direction\@max\:cr {
    flex-direction: column-reverse !important;
  }
  .f-wrap\@max\:nw {
    flex-wrap: nowrap !important;
  }
  .f-wrap\@max\:w {
    flex-wrap: wrap !important;
  }
  .f-wrap\@max\:r {
    flex-wrap: revert !important;
  }
  .f-wrap\@max\:wr {
    flex-wrap: wrap-reverse !important;
  }
  .f-row.f-nogap\@max {
    margin-right: 0;
    margin-left: 0;
  }
  .f-row.f-nogap\@max > [class*='f-col'] {
    padding-right: 0;
    padding-left: 0;
  }
  [class*='f-col'].f-nogap\@max {
    padding-right: 0;
    padding-left: 0;
  }
  .f-align\@max\:l {
    justify-content: flex-start !important;
  }
  .f-align\@max\:c {
    justify-content: center !important;
  }
  .f-align\@max\:r {
    justify-content: flex-end !important;
  }
  .f-align\@max\:j {
    justify-content: space-between !important;
  }
  .f-align\@max\:a {
    justify-content: space-around !important;
  }
  .f-align\@max\:e {
    justify-content: space-evenly !important;
  }
  .f-align\@max\:t {
    align-items: flex-start !important;
  }
  .f-align\@max\:m {
    align-items: center !important;
  }
  .f-align\@max\:b {
    align-items: flex-end !important;
  }
  .f-align\@max\:s {
    align-items: stretch !important;
  }
  .f-col-align\@max\:t {
    align-self: flex-start !important;
  }
  .f-col-align\@max\:m {
    align-self: center !important;
  }
  .f-col-align\@max\:b {
    align-self: flex-end !important;
  }
  .f-col-align\@max\:s {
    align-self: stretch !important;
  }
}

html,
body {
  --header-height: 56px;
  overflow-x: hidden;
  overflow-y: auto;
  color: #464646;
  scroll-behavior: smooth;
}

@media (min-width: 1024px) {
  html,
  body {
    --header-height: 72px;
  }
}

a {
  transition: color 0.24s ease;
}

@media (min-width: 1024px) {
  a:hover {
    color: #d93d28;
  }
}

label {
  cursor: pointer;
}

.h1 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
}

@media (min-width: 1024px) {
  .h1 {
    font-size: 2.0625rem;
  }
}

@media (min-width: 1440px) {
  .h1 {
    font-size: 2.625rem;
    line-height: 1.42857;
  }
}

.h2 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
}

@media (min-width: 1024px) {
  .h2 {
    font-size: 1.625rem;
  }
}

@media (min-width: 1440px) {
  .h2 {
    font-size: 2rem;
    line-height: 1.375;
  }
}

.h3 {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.44444;
}

@media (min-width: 1024px) {
  .h3 {
    font-size: 1.4375rem;
  }
}

@media (min-width: 1440px) {
  .h3 {
    font-size: 1.75rem;
    line-height: 1.5;
  }
}

.h4 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

@media (min-width: 1024px) {
  .h4 {
    font-size: 1.25rem;
  }
}

@media (min-width: 1440px) {
  .h4 {
    font-size: 1.5rem;
    line-height: 1.66667;
  }
}

.h5 {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857;
}

@media (min-width: 1024px) {
  .h5 {
    font-size: 1.125rem;
  }
}

@media (min-width: 1440px) {
  .h5 {
    font-size: 1.375rem;
    line-height: 1.54545;
  }
}

.h6 {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
}

@media (min-width: 1024px) {
  .h6 {
    font-size: 0.9375rem;
  }
}

@media (min-width: 1440px) {
  .h6 {
    font-size: 1.125rem;
    line-height: 1.44444;
  }
}

.paragraph-main {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

.paragraph-second {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857;
  margin: 0;
}

.main-wrapper {
  position: relative;
  padding-top: 3.0625rem;
}

@media (min-width: 1024px) {
  .main-wrapper {
    padding-top: 8.375rem;
  }
}

.container {
  margin: auto;
  width: 100%;
  max-width: 96.25rem;
}

.container.sz-sm {
  max-width: 56.25rem;
}

.container.sz-smd {
  max-width: 62.5rem;
}

@media (min-width: 1440px) {
  .container.sz-smd {
    max-width: 66.25rem;
  }
}

.container.sz-md {
  max-width: 72.5rem;
}

@media (min-width: 1440px) {
  .container.sz-md {
    max-width: 81.25rem;
  }
}

.page-container {
  --headline-x-pos: 3.75rem;
  position: relative;
  background-color: #f5f5f5;
}

@media (min-width: 1024px) {
  .page-container {
    --headline-x-pos: 6.25rem;
  }
}

.page-container.over-content {
  --padding-top-pos: 4.625rem;
}

@media (min-width: 768px) {
  .page-container.over-content {
    --padding-top-pos: 6.875rem;
  }
}

.page-container.over-content .section-content {
  padding-top: 0;
  min-height: 50vh;
}

@media (min-width: 768px) {
  .page-container.over-content .section-content {
    min-height: 80vh;
  }
}

@media (min-width: 1024px) {
  .page-container.over-content .section-content {
    min-height: 45.5rem;
  }
}

.page-container.over-content .section-headline {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.page-container.over-content .over-padding {
  padding-top: var(--padding-top-pos);
}

.page-container.over-content .w-full {
  padding-top: var(--padding-top-pos);
  padding-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .page-container.over-content .w-full {
    padding-bottom: 2.25rem;
  }
}

.page-container .over-banner {
  margin-top: calc(var(--headline-x-pos) * -1);
  padding-top: 0;
}

.section-banner {
  position: relative;
  z-index: 2;
  margin: calc(var(--headline-x-pos) * -1) auto 0;
}

.section-banner .object-fit::before {
  padding-top: 50%;
}

.section-banner ~ .section-headline {
  margin-top: -1.875rem;
}

@media (min-width: 768px) {
  .section-banner ~ .section-headline {
    margin: calc(var(--headline-x-pos) * -1) auto 0;
  }
}

.section-headline {
  position: relative;
  z-index: 3;
  margin: calc(var(--headline-x-pos) * -1) auto 0;
  width: calc(100% - 3rem);
  max-width: 58.75rem;
  text-align: center;
  color: #fff;
}

.section-headline_title {
  position: relative;
}

.section-headline_title::after {
  content: '';
  position: absolute;
  top: calc(100% + 0.75rem);
  left: 50%;
  width: 5.375rem;
  height: 0.25rem;
  background-color: #fff;
  transform: translateX(-50%);
}

.section-headline_item {
  position: relative;
  padding: 1.5rem 1.5rem 3rem;
  background-color: #464646;
}

@media (min-width: 1024px) {
  .section-headline_item {
    padding: 3rem 1.5rem 4.125rem;
  }
}

.section-headline_item.mark-title {
  padding: 1.25rem;
}

@media (min-width: 768px) {
  .section-headline_item.mark-title {
    padding: 1.5rem 2rem;
  }
}

.section-headline_item.mark-title .section-headline_title {
  margin-bottom: 1rem;
  text-align: left;
}

.section-headline_item.mark-title .section-headline_title::after {
  content: none;
}

.section-headline_item .mark {
  top: -0.875rem;
}

@media (min-width: 1024px) {
  .section-headline_item .mark {
    --clip-height: 1.25rem;
    top: -1.25rem;
    padding: 0.5rem 2.8125rem 0.5rem 0.625rem;
    min-width: 8.125rem;
    font-size: 1rem;
  }
}

.section-headline_txt {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: left;
}

.section-headline .white-box {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  color: #464646;
}

.section-headline--white .section-headline_title {
  color: #464646;
}

.section-headline--white .section-headline_title::after {
  background-color: #d93d28;
}

.section-headline--white .section-headline_item {
  background-color: #fff;
}

.section-headline.sz-sm {
  padding: 0 1.5rem;
  width: 100%;
  max-width: 56.25rem;
}

@media (min-width: 1024px) {
  .section-headline.sz-sm {
    padding: 0 1.875rem;
    max-width: 56.25rem;
  }
}

@media (min-width: 1440px) {
  .section-headline.sz-sm {
    padding: 0 3.75rem;
  }
}

.section-content {
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding: 1.875rem 0 3.75rem;
}

@media (min-width: 1024px) {
  .section-content {
    padding: 3.75rem 0 5.25rem;
  }
}

.section-content::after {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 40rem;
  background: linear-gradient(180deg, #f5f5f5 0%, #eaeaea 100%);
  pointer-events: none;
}

.section-content .hr {
  margin: 2.25rem 0;
  border: thin solid #ccc;
}

.white-box {
  overflow: hidden;
  padding: 1.5rem;
  background-color: #fff;
  box-shadow: 2px 2px 30px 0 rgba(70, 70, 70, 0.04);
}

.white-box:not(:last-child) {
  margin-bottom: 1.5rem;
}

@media (min-width: 1024px) {
  .white-box:not(:last-child) {
    margin-bottom: 3rem;
  }
}

.white-box_head {
  position: relative;
  padding: 1.25rem;
  line-height: 137.5%;
  text-align: center;
  background-color: #fff;
  box-shadow: -2px 0 20px 0 rgba(70, 70, 70, 0.06);
}

@media (min-width: 1024px) {
  .white-box {
    padding: 3.125rem 5rem;
  }
}

@media (min-width: 1440px) {
  .white-box {
    padding: 3.75rem 7.5rem;
  }
}

.white-box--gray100 {
  background-color: #e6e6e6;
}

.quest-box {
  max-width: 43.75rem;
}

.quest-box.indent-box {
  max-width: 41.75rem;
}

.indent-box {
  margin-top: 0.5rem;
  margin-left: 2.25rem;
}

.indent-box .quest-box {
  max-width: 41.5rem;
}

.subquest-box {
  margin-top: 2.25rem;
}

.subquest-box:first-child {
  margin-top: 3.75rem;
}

.subquest-limit {
  max-width: 56.25rem;
}

.js-field-ctrl:not(.is-show) ~ [data-field-hide],
.js-field-expand:not(.is-show) ~ [data-field-hide] {
  display: none;
}

.js-field-ctrl:not(.is-show) [data-field-hide],
.js-field-expand:not(.is-show) [data-field-hide] {
  display: none;
}

.clamp, .clamp-1, .clamp-2, .clamp-3, .clamp-4, .clamp-5, .clamp-6 {
  --line-height: 1.5;
  position: relative;
  display: block;
  display: -webkit-box;
  overflow: hidden;
  max-width: 100%;
  line-height: var(--line-height);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.clamp, .clamp-1 {
  display: block;
  max-width: 100%;
  white-space: nowrap;
}

.clamp-2 {
  -webkit-line-clamp: 2;
  max-height: calc(1em * 1.6 * 2);
  max-height: calc(1em * var(--line-height) * 2);
}

.clamp-3 {
  -webkit-line-clamp: 3;
  max-height: calc(1em * 1.6 * 3);
  max-height: calc(1em * var(--line-height) * 3);
}

.clamp-4 {
  -webkit-line-clamp: 4;
  max-height: calc(1em * 1.6 * 4);
  max-height: calc(1em * var(--line-height) * 4);
}

.clamp-5 {
  -webkit-line-clamp: 5;
  max-height: calc(1em * 1.6 * 5);
  max-height: calc(1em * var(--line-height) * 5);
}

.clamp-6 {
  -webkit-line-clamp: 6;
  max-height: calc(1em * 1.6 * 6);
  max-height: calc(1em * var(--line-height) * 6);
}

/* object-fit */
.object-fit, .object-fit--contain {
  position: relative;
  display: block;
  overflow: hidden;
}

.object-fit::before, .object-fit--contain::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.object-fit img, .object-fit--contain img,
.object-fit .picture,
.object-fit--contain .picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  object-position: center center;
  background-position: center center;
}

.object-fit img,
.object-fit .picture {
  object-fit: cover;
  background-size: cover;
}

.object-fit--contain {
  background-position: center center;
  background-size: contain;
}

.object-fit--contain img,
.object-fit--contain .picture {
  object-fit: contain;
  background-size: contain;
}

.lazy {
  opacity: 0;
  transition: 0.24s opacity;
}

.lazy.loaded {
  opacity: 1;
}

.d-none {
  display: none !important;
}

@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
}

.d-md-none {
  display: block;
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
}

@media (min-width: 1440px) {
  .d-xl-block {
    display: block !important;
  }
}

.d-xl-none {
  display: block !important;
}

@media (min-width: 1440px) {
  .d-xl-none {
    display: none !important;
  }
}

.show-for-sr {
  position: absolute !important;
  overflow: hidden !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
}

.is-locked {
  cursor: no-drop !important;
  color: #ccc !important;
  box-shadow: none !important;
}

.dividing {
  position: relative;
  width: 0.125rem;
  height: 5rem;
  background-color: #d93d28;
  transform: rotate(40deg);
  pointer-events: none;
}

@media (min-width: 1024px) {
  .dividing {
    height: 9.375rem;
  }
}

@media (min-width: 1440px) {
  .dividing {
    width: 0.1875rem;
    height: 11.25rem;
  }
}

@media (min-width: 1920px) {
  .dividing {
    width: 0.25rem;
    height: 17.625rem;
  }
}

.d-flex {
  display: flex;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.position-relative {
  position: relative;
}

.w-100 {
  width: 100%;
}

.w-150 {
  width: 150%;
}

.js-fixed-nav.is-fixed [data-fixed-nav] {
  position: fixed;
  z-index: 100;
  right: 0;
  left: 0;
  background-color: #fff;
  box-shadow: -2px 0 20px 0 rgba(70, 70, 70, 0.06);
}

.header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  background: #fff;
  box-shadow: 2px 2px 20px 0 rgba(70, 70, 70, 0.15);
}

.header_top {
  position: relative;
  z-index: 2;
  display: none;
  background-color: #f5f5f5;
}

@media (min-width: 1024px) {
  .header_top {
    display: block;
  }
}

.header_top .header_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  padding-left: 4.625rem;
  max-width: 120rem;
}

@media (min-width: 1024px) {
  .header_top .header_inner {
    margin: 0 auto;
    padding-right: 1.5rem;
    max-width: 106.75rem;
    transition: 0.24s ease;
  }
}

.header_function {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_function .btn {
  padding: 0.75rem 1.25rem;
  min-width: 0;
  min-height: 0;
  line-height: 1.44;
}

.header_function .select {
  font-size: 1.125rem;
}

@media (min-width: 1024px) {
  .header .space {
    min-width: 6.75rem;
  }
}

.header_bottom {
  position: relative;
  z-index: 1;
}

.header_bottom .header_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
}

@media (min-width: 1024px) {
  .header_bottom .header_inner {
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;
    max-width: 106.75rem;
  }
}

.header_bottom.is-open .header_switch::before {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.header_bottom.is-open .header_switch::after {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.header_bottom.is-open .header_expand {
  opacity: 1;
  pointer-events: all;
}

.header_bottom.is-open .header_expand_inner {
  transform: translateX(0);
}

@media (min-width: 1024px) {
  .header_logo {
    display: block;
    width: 10rem;
    max-height: 2.5rem;
    transition: 0.24s ease;
    flex-shrink: 0;
  }
}

@media (min-width: 1440px) {
  .header_logo {
    width: 13.75rem;
  }
}

.header_nav {
  margin-bottom: 4.5rem;
}

@media (min-width: 1024px) {
  .header_nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
  }
}

.header_nav_item {
  position: relative;
}

@media (min-width: 1440px) {
  .header_nav_item:not(:last-child) {
    margin-right: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .header_nav_item:hover .header_nav_link:not(.is-locked) .txt::after {
    width: 2rem;
  }
  .header_nav_item:hover .header_subnav {
    opacity: 1;
    transform: translate(-50%, 0);
    pointer-events: all;
  }
}

.header_nav_item.is-active .header_nav_link > .be-icon {
  transform: rotate(180deg);
}

.header_nav_item.is-active .header_subnav {
  margin-bottom: 1rem;
  max-height: 31.25rem;
}

.header_nav_link {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 0.75rem;
  font-size: 1.125rem;
  border-bottom: solid 0.0625rem #ccc;
}

@media (min-width: 1024px) {
  .header_nav_link {
    padding: 1.6875rem 1.25rem;
    border: 0;
    transition: 0.24s ease;
  }
}

.header_nav_link .txt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media (min-width: 1024px) {
  .header_nav_link .txt {
    position: relative;
  }
  .header_nav_link .txt::after {
    content: '';
    position: absolute;
    bottom: -0.4375rem;
    right: 0;
    display: block;
    width: 0;
    height: 0.125rem;
    background-color: #d93d28;
    transition: 0.24s ease-out;
  }
}

.header_nav_link .be-icon {
  font-size: 1.5rem;
}

@media (min-width: 1024px) {
  .header_nav_link .be-icon {
    display: none;
  }
}

.header_subnav {
  overflow: hidden;
  max-height: 0;
  transition: 0.36s all;
}

@media (min-width: 1024px) {
  .header_subnav {
    position: absolute;
    top: 100%;
    left: 50%;
    overflow: auto;
    width: 11.875rem;
    max-height: none;
    background-color: #fff;
    opacity: 0;
    box-shadow: 0 2px 5px 0 rgba(70, 70, 70, 0.1);
    transform: translate(-50%, -1.25rem);
    pointer-events: none;
  }
}

.header_subnav_link {
  display: flex;
  align-items: center;
  padding: 0.625rem 0.75rem;
  color: #808080;
}

@media (min-width: 1024px) {
  .header_subnav_link {
    padding: 0.875rem 1.625rem;
  }
}

.header_subnav_link .be-icon {
  display: inline-flex;
  margin-right: .5rem;
  font-size: 1.5rem;
}

.header_mfunction {
  margin-top: auto;
  text-align: center;
}

@media (min-width: 1024px) {
  .header_mfunction {
    display: none;
  }
}

.header .login [class*="btn"] .be-icon {
  margin-right: 0;
  margin-left: 0.5rem;
}

.header .guide {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
}

.header .guide_link {
  margin-right: 1rem;
  padding-right: 1rem;
  border-right: 0.0625rem solid #464646;
}

.header .language {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .header .language {
    margin-right: 1.5rem;
    font-size: 1.125rem;
    transition: 0.24s ease-out;
  }
  .header .language:hover {
    color: #d93d28;
  }
}

@media (min-width: 1024px) {
  .header .language .select {
    padding-right: 0;
  }
}

.header .language_select {
  -webkit-appearance: none;
  appearance: none;
}

@media (min-width: 1024px) {
  .header .language {
    margin-top: 0;
  }
}

.header_expand {
  --hd-height: 3.0625rem;
  position: fixed;
  z-index: 1000;
  top: var(--hd-height);
  left: 0;
  width: 100vw;
  height: calc(100vh - var(--hd-height));
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: 0.24s opacity;
  pointer-events: none;
}

@media (min-width: 1024px) {
  .header_expand {
    position: relative;
    top: auto;
    height: auto;
    background-color: transparent;
    opacity: 1;
    pointer-events: all;
  }
}

.header_expand_inner {
  display: flex;
  align-items: initial;
  justify-content: flex-start;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: auto;
  padding: 1.5rem;
  max-width: 100vw;
  height: 100%;
  background: url("img/common/nav-bg.svg") left bottom no-repeat #f5f5f5;
  background-size: contain;
  transform: translateX(1.875rem);
  transition: 0.24s transform;
}

@media (min-width: 576px) {
  .header_expand_inner {
    max-width: 23.4375rem;
  }
}

@media (min-width: 1024px) {
  .header_expand_inner {
    overflow: visible;
    padding: 0;
    max-width: none;
    background: transparent;
    transform: none;
  }
}

.header_switch {
  --switch-padding: 0.5rem;
  position: relative;
  padding: 0.375rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: transparent;
  border: 0;
}

.header_switch::before, .header_switch::after {
  content: '';
  position: absolute;
  left: 0;
  display: block;
  width: 0.875rem;
  height: 0.125rem;
  background-color: #464646;
  transition: 0.24s all;
}

.header_switch::before {
  top: var(--switch-padding);
  left: 0.375rem;
}

.header_switch::after {
  top: calc(var(--switch-padding) * 2);
  left: 0.375rem;
}

@media (min-width: 1024px) {
  .header_switch {
    display: none;
  }
}

.header .userinfo {
  display: block;
  margin-bottom: 2.25rem;
  padding: 0.625rem;
  border-bottom: solid 0.0625rem #ccc;
}

@media (min-width: 1024px) {
  .header .userinfo {
    display: none;
  }
}

.header .userinfo_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .userinfo_title {
  font-size: 1.125rem;
}

.header .userinfo .be-icon {
  font-size: 1.5rem;
  color: #d93d28;
}

.header .userinfo_body {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
}

.header .userinfo_level {
  margin-right: 0.5rem;
  font-size: 0.875rem;
  color: #808080;
}

@media (min-width: 1024px) {
  .header .userinfo_level {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #464646;
  }
}

.header .userinfo_period {
  font-size: 0.75rem;
  color: #ccc;
}

@media (min-width: 1024px) {
  .header .userinfo_period {
    font-size: 0.875rem;
  }
}

.header .userinfo_nav_link {
  display: block;
  padding: 0.625rem;
  padding: 0.875rem 1.625rem;
  font-size: 1rem;
}

.header .userinfo_nav_link:not(:hover) {
  color: #808080;
}

.header .userinfo_nav_link.highlight {
  font-weight: 500;
  color: #d93d28;
}

.header .userinfo_box {
  position: relative;
  z-index: 5;
}

@media (min-width: 1024px) {
  .header .userinfo_box {
    transition: 0.24s ease;
  }
  .header .userinfo_box:hover {
    color: #d93d28;
  }
  .header .userinfo_box:hover .userinfo_panel {
    opacity: 1;
    transform: translate(0%, 0);
    pointer-events: all;
  }
}

.header .userinfo_panel {
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;
  width: 13.5rem;
  text-align: left;
  background-color: #fff;
  opacity: 0;
  box-shadow: 0 2px 5px 0 rgba(70, 70, 70, 0.1);
  transform: translate(0%, -1.25rem);
  transition: 0.24s ease-out;
  pointer-events: none;
}

.header .userinfo_panel .userinfo_body {
  position: relative;
  display: block;
  margin: 0;
  padding: 0.875rem 2rem;
  background-color: #f5f5f5;
}

.header .userinfo_panel .userinfo_body::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -0.25rem;
  display: block;
  width: 9.375rem;
  height: 0.25rem;
  background-color: #d93d28;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 0.25rem), calc(100% - 0.25rem) 100%, 0 100%);
}

@media (min-width: 1024px) {
  .header.is-collapse .header_top .header_inner {
    padding-left: 3.75rem;
  }
  .header.is-collapse .header_logo {
    width: 10rem;
  }
  .header.is-collapse .header_nav_link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .header.is-collapse .userinfo_box {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}

.footer {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857;
}

.footer_top {
  background-color: #f5f5f5;
}

.footer_top .container {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

@media (min-width: 768px) {
  .footer_top .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.footer_brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .footer_brand {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .footer_brand_title {
    display: none;
  }
}

@media (min-width: 1440px) {
  .footer_brand_logo {
    width: 13.75rem;
  }
}

@media (min-width: 768px) {
  .footer_content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.footer_info:not(:last-child) {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 0.0625rem solid #ccc;
}

@media (min-width: 768px) {
  .footer_info:not(:last-child) {
    margin-bottom: 0;
    margin-right: 3.125rem;
    padding-bottom: 0;
    border: 0;
  }
}

@media (min-width: 1440px) {
  .footer_info:not(:last-child) {
    margin-right: 12.5rem;
  }
}

.footer_info_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.4;
}

.footer_info_item:not(:last-child) {
  margin-bottom: 0.75rem;
}

@media (min-width: 768px) {
  .footer_info_item {
    justify-content: flex-start;
  }
}

.footer .info_title {
  margin: 0;
  color: #808080;
}

@media (min-width: 768px) {
  .footer .info_text {
    margin-left: 0.75rem;
  }
}

.footer_bottom {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  line-height: 1.4;
  color: #f5f5f5;
  background-color: #464646;
}

@media (min-width: 768px) {
  .footer_bottom {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
}

.footer_copyright {
  display: flex;
  flex-direction: column-reverse;
}

@media (min-width: 768px) {
  .footer_copyright {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
}

.footer_terms {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid #808080;
}

@media (min-width: 768px) {
  .footer_terms {
    flex-direction: row;
    margin: 0;
    padding: 0;
    border: 0;
  }
}

.footer_terms_item {
  --pos-right: 2rem;
  position: relative;
  color: inherit;
}

@media (min-width: 1440px) {
  .footer_terms_item {
    --pos-right: 3rem;
  }
}

.footer_terms_item:not(:last-child) {
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .footer_terms_item:not(:last-child) {
    margin-bottom: 0;
    margin-right: var(--pos-right);
  }
  .footer_terms_item:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    right: calc(var(--pos-right) / 2 * -1);
    display: block;
    width: 0.0625rem;
    height: 100%;
    background-color: #f5f5f5;
    transform: translateY(-50%);
  }
}

.cookie_box {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  padding: 0.9375rem 0;
  width: 100%;
  color: #fff;
  background-color: #464646;
  opacity: 0;
  transform: translateY(100%);
  transition: 0.72s;
  pointer-events: none;
}

@media (min-width: 768px) {
  .cookie_box {
    padding: 1.5rem 0;
  }
}

.cookie_box .cookie_btn {
  margin-top: 0.625rem;
  font-size: 0.875rem;
}

@media (min-width: 768px) {
  .cookie_box .cookie_btn {
    margin-top: 0;
    margin-left: 0.625rem;
    font-size: 1rem;
  }
}

@media (min-width: 1440px) {
  .cookie_box .cookie_btn {
    font-size: 1.125rem;
  }
}

.cookie_box .link {
  margin: 0 0.1875rem;
}

.cookie_box.is-show {
  opacity: 1;
  transform: translateY(0%);
  pointer-events: all;
}

@media (min-width: 768px) {
  .cookie_info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.cookie_title {
  margin: 0;
  font-size: 0.875rem;
}

@media (min-width: 768px) {
  .cookie_title {
    font-size: 1rem;
  }
}

@media (min-width: 1440px) {
  .cookie_title {
    font-size: 1.125rem;
  }
}

.cont-loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.cont-loading_block {
  height: 31.25rem;
}

.cont-loading_icon {
  width: 3.75rem;
}

@media (min-width: 768px) {
  .cont-loading_icon {
    width: 6.25rem;
  }
}

.cont-loading_title {
  margin-top: 0.5rem;
}

.infoitem {
  --clr-white: #fff;
  --clr-dark: #464646;
  padding: 1.5rem 1rem;
  color: var(--clr-white);
  background-color: var(--clr-dark);
  border: solid 1px var(--clr-dark);
  box-shadow: 2px 2px 20px 0 rgba(70, 70, 70, 0);
}

.infoitem .be-icon {
  display: flex;
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
  color: inherit;
}

@media (min-width: 1440px) {
  .infoitem .be-icon {
    margin-bottom: 0.75rem;
    font-size: 3.75rem;
  }
}

.infoitem_title {
  position: relative;
  font-weight: 600;
  color: inherit;
}

.infoitem_title::before, .infoitem_title::after {
  content: '';
  position: absolute;
  top: calc(100% + 0.5rem);
  left: -2rem;
  display: block;
  height: 0.625rem;
  background-color: var(--clr-white);
}

@media (min-width: 1440px) {
  .infoitem_title::before, .infoitem_title::after {
    top: calc(100% + 1.5rem);
    height: 1.125rem;
  }
}

.infoitem_title::after {
  width: 7.875rem;
  transition: 0.24s cubic-bezier(0.25, 1, 0.5, 1);
}

.infoitem_title::before {
  width: 0;
  transition: 0.48s cubic-bezier(0.25, 1, 0.5, 1);
  transition-delay: 0.6s;
}

.infoitem_info {
  overflow: hidden;
  margin: 1.625rem 0 0;
}

@media (min-width: 1440px) {
  .infoitem_info {
    margin-top: 4.125rem;
    max-height: 0;
    font-size: 1.375rem;
  }
}

.main-banner {
  position: relative;
  z-index: 2;
  padding: 1.25rem 0;
  height: 9.375rem;
  background-color: #f5f5f5;
}

@media (min-width: 1024px) {
  .main-banner {
    height: 12.5rem;
  }
}

.main-banner::before {
  padding: 0;
}

.wave-mask {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(217, 61, 40, 0.85);
}

.wave-mask::before, .wave-mask::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  display: block;
  width: 14.0625rem;
  height: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

@media (min-width: 1024px) {
  .wave-mask::before, .wave-mask::after {
    width: 28.125rem;
  }
}

.wave-mask::before {
  left: 0;
  background-image: url("img/common/wave-left.svg");
}

.wave-mask::after {
  right: 0;
  background-image: url("img/common/wave-right.svg");
}

.btn,
.btn-outline, .btn-text, .btn-icon {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.03em;
  white-space: nowrap;
  border: 1px solid transparent;
  outline: none;
  text-shadow: none;
  transition: 0.36s ease-out;
  box-sizing: border-box;
  user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  background-image: none;
  cursor: pointer;
  transition-property: color, background-color, border-color, box-shadow;
}

@media (min-width: 1024px) {
  .btn,
  .btn-outline, .btn-text, .btn-icon {
    font-size: 1.125rem;
  }
}

.btn[disabled],
.btn-outline[disabled], .btn-text[disabled], .btn-icon[disabled] {
  color: #ccc;
  border-color: #e6e6e6;
}

.icon-append,
.icon-prepend {
  font-size: 24px;
  color: inherit;
}

.btn,
.btn-outline {
  --icon-size: 20px;
  padding: 0.6875rem 1.5rem;
  min-width: 6.25rem;
  min-height: 2.5rem;
  font-size: 1rem;
  line-height: calc(24 / 18);
  border-radius: 0.125rem;
}

@media (min-width: 768px) {
  .btn,
  .btn-outline {
    min-width: 8.75rem;
    min-height: 2.75rem;
    font-size: 1.125rem;
  }
}

.btn.w-full,
.btn-outline.w-full {
  width: 100%;
}

.btn.sz-lg,
.btn-outline.sz-lg {
  min-width: 8.75rem;
}

@media (min-width: 768px) {
  .btn.sz-lg,
  .btn-outline.sz-lg {
    min-width: 13.75rem;
  }
}

.btn {
  color: #fff;
  background-color: #808080;
}

@media (min-width: 1024px) {
  .btn:hover {
    background-color: #464646;
  }
}

.btn.clr-primary {
  color: #fff;
  background-color: #d93d28;
  border-color: #d93d28;
}

@media (min-width: 1024px) {
  .btn.clr-primary:hover {
    background-color: #bf1600;
    border-color: #bf1600;
  }
}

.btn.clr-primary[disabled] {
  background-color: #ccc;
  border-color: #ccc;
}

.btn.clr-dark {
  color: #fff;
  background-color: #464646;
  border-color: #464646;
}

@media (min-width: 1024px) {
  .btn.clr-dark:hover {
    background-color: #333;
    border-color: #333;
  }
}

.btn.clr-dark[disabled] {
  background-color: #ccc;
  border-color: #ccc;
}

.btn-outline {
  color: #464646;
  background: #fff;
  border-color: #808080;
}

@media (min-width: 1024px) {
  .btn-outline:hover {
    color: #333;
    background: #f5f5f5;
    border-color: #333;
  }
}

.btn-outline.clr-white {
  color: #fff;
  background: transparent;
  border-color: #fff;
}

@media (min-width: 1024px) {
  .btn-outline.clr-white:hover {
    color: #464646;
    background: #fff;
    border-color: #fff;
  }
}

.btn-text {
  padding: 0.75rem 1.25rem;
  color: initial;
  background: transparent;
  border: 0.0625rem solid transparent;
}

@media (min-width: 1024px) {
  .btn-text:hover {
    color: #d93d28;
  }
}

.btn-icon {
  background: transparent;
}

.btn-icon[disabled] {
  color: #999;
  background: transparent;
  border-color: transparent;
}

@media (min-width: 1024px) {
  .btn-icon:hover {
    color: #d93d28;
  }
}

.btn-group {
  display: flex;
  justify-content: center;
  gap: 0.75rem;
}

@media (min-width: 1024px) {
  .btn-group {
    gap: 1.5rem;
  }
}

.btn-group [class*='btn'] {
  flex: 1;
  max-width: 13.75rem;
}

.icon-left {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.icon-left .be-icon {
  flex-shrink: 0;
  margin-right: 0.625rem;
  font-size: 1.5rem;
}

.icon-left .btn_txt {
  flex: 1;
}

.icon-right .be-icon {
  flex-shrink: 0;
  margin-left: 0.625rem;
  font-size: 1.5rem;
}

.icon-right .btn_txt {
  flex: 1;
}

.text-link {
  text-decoration: underline;
  color: #464646;
}

.text-link:hover, .text-link.is\:hover {
  text-decoration: underline;
}

.link-space {
  margin: 0 3px;
}

@media (min-width: 1024px) {
  .w-limit-460 {
    margin: 0 auto;
    max-width: 28.75rem;
  }
}

.card-article {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-article .embed::before {
  padding-top: 66.66667%;
}

.card-article .card_txt {
  position: relative;
  z-index: 1;
  flex: 1;
  margin: -1.3125rem auto 0;
  padding: 1rem;
  width: calc(100% - 1.25rem);
  background-color: #fff;
  box-shadow: 2px 2px 30px 0 rgba(70, 70, 70, 0.04);
}

@media (min-width: 1440px) {
  .card-article .card_txt {
    margin-top: -3.25rem;
    width: calc(100% - 2.25rem);
  }
}

.card-article .mark {
  top: 0;
  left: 0;
  color: #fff;
}

@media (min-width: 1024px) {
  .card-article.hover .embed_media {
    transition: transform 0.36s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  .card-article.hover:hover.mask::after {
    opacity: 1;
  }
  .card-article.hover:hover .embed_media {
    transform: scale(1.1);
  }
}

.card-info {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 2px 2px 30px 0 rgba(70, 70, 70, 0.04);
}

@media (min-width: 768px) {
  .card-info {
    padding: 2.25rem 1.25rem;
  }
}

.card-info .card_icon {
  display: flex;
  margin-bottom: 0.75rem;
  font-size: 3rem;
}

.card-info .card_title {
  margin: 0 0 0.75rem;
  font-size: 1.125rem;
}

.card-info .card_info {
  margin: 0 0 2.25rem;
}

.card-info .card_btn {
  margin-top: auto;
}

.card-result {
  border: 0.0625rem solid #ccc;
}

.card-result .card_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .75rem 1.5rem;
  background-color: #f5f5f5;
}

.card-result .card_head_title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  line-height: 1.44444;
}

@media (min-width: 1440px) {
  .card-result .card_head_title {
    font-size: 1.125rem;
  }
}

.card-result .card_head_note {
  margin-left: .75rem;
  padding-left: .75rem;
  border-left: 1px solid #464646;
  flex-shrink: 0;
}

.card-result .card_body {
  padding: 0 1.5rem;
}

.card-result .list_item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: .75rem 0;
  border-bottom: 1px solid #f5f5f5;
}

.card-result .list_item .txt:last-child {
  margin-left: 1rem;
  min-width: 3rem;
  flex-shrink: 0;
  text-align: right;
}

.row-cards {
  margin: 0;
  padding: 0;
  list-style: none;
}

.row-cards_li {
  display: flex;
  flex-direction: column;
  padding: 2.25rem 1rem;
}

@media (min-width: 768px) {
  .row-cards_li {
    flex-direction: row;
  }
}

.row-cards_li:not(:last-of-type) {
  border-bottom: 0.0625rem solid #ccc;
}

.row-cards_img {
  margin-bottom: 0.625rem;
  width: 100%;
}

@media (min-width: 768px) {
  .row-cards_img {
    margin-bottom: 0;
    margin-right: 1.5rem;
    width: 33.03965%;
  }
}

.row-cards_img.object-fit::before {
  padding-top: 66.66667%;
}

.row-cards_info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 768px) {
  .row-cards_info {
    width: 64.31718%;
  }
}

.row-cards_title {
  margin: 0 0 0.75rem;
  font-size: 1.125rem;
  font-weight: 500;
}

.row-cards_txt {
  margin: 0 0 1.5rem;
}

.row-cards_bottom {
  align-items: flex-start;
  flex-direction: column;
  margin-top: auto;
}

@media (min-width: 768px) {
  .row-cards_bottom {
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
  }
}

.row-cards_annotation {
  margin: 0 0 0.625rem;
  font-size: 0.875rem;
  color: #999;
}

@media (min-width: 768px) {
  .row-cards_annotation {
    margin: 0 0.625rem 0 0;
  }
}

.row-cards .btn-outline {
  width: 100%;
}

@media (min-width: 768px) {
  .row-cards .btn-outline {
    width: auto;
  }
}

.swiper-cards.swiper-container {
  overflow: initial;
}

.swiper-cards .swiper-wrapper {
  padding-bottom: 1.25rem;
}

.swiper-cards .swiper-slide {
  height: auto;
}

.swiper-cards.has-mark .swiper-slide {
  padding-top: 0.875rem;
  padding-left: 0.5rem;
}

.swiper-cards .swiper-button-prev,
.swiper-cards .swiper-button-next {
  display: none;
}

@media (min-width: 1024px) {
  .swiper-cards .swiper-button-prev,
  .swiper-cards .swiper-button-next {
    display: flex;
  }
}

.embed {
  position: relative;
  z-index: 1;
  display: block;
  overflow: hidden;
  padding: 0;
  width: 100%;
  background-color: inherit;
}

.embed::before {
  content: '';
  position: relative;
  z-index: 1;
  display: block;
  padding-top: 56.25%;
  width: 100%;
}

.embed_txt {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  padding: 8px;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.33333;
}

.embed_media {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0;
}

.embed iframe {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed.mask::after {
  content: '';
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(60, 58, 58, 0) 60%, rgba(0, 0, 0, 0.54) 100%);
  opacity: 0;
  transition: 0.45s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-property: transform, opacity;
  pointer-events: none;
}

.embed.hover {
  cursor: pointer;
}

.embed.hover .embed_media {
  transition: transform 0.45s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.embed.hover:hover.mask::after {
  opacity: 1;
}

.embed.hover:hover .embed_media {
  transform: scale(1.1);
}

@media (min-width: 768px) {
  .embed_txt {
    padding: 16px;
    font-size: 16px;
  }
}

.picture .img {
  display: block;
  width: 100%;
}

.form {
  --state-color: #464646;
  --icon-padding: 1rem;
}

.form .f-row:not(:last-child) {
  margin-bottom: 1rem;
}

.form_grp {
  margin-bottom: 1rem;
}

.form_grp.icon-right {
  --icon-padding: 3.125rem;
}

.form_grp.icon-right [class*='btn'] {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  padding: 0.6875rem 1rem;
}

.form_grp.icon-right .be-icon {
  margin-left: 0;
}

.form.bigger-grp .form_grp {
  margin-bottom: 1.5rem;
}

.form_opt {
  position: relative;
}

.form_label {
  display: block;
  margin-bottom: 0.75rem;
  font-weight: 500;
  line-height: 1.44444;
}

@media (min-width: 1440px) {
  .form_label {
    font-size: 1.125rem;
  }
}

.form_readonly {
  margin: 0;
}

.form_note {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin-top: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #999;
}

.form_note .be-icon {
  font-size: 1.25rem;
}

.form_note .checkbox_title {
  color: #464646;
}

.form_note .checkbox_label::after {
  content: none;
}

.form_note .checkbox_checkmark {
  margin-top: 4px;
}

.form_error {
  display: none;
  align-items: baseline;
  justify-content: flex-start;
  margin-top: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: var(--state-color);
}

.form_error .be-icon {
  margin-right: 0.5rem;
  font-size: 1.25rem;
}

.form_valid {
  align-items: baseline;
  justify-content: flex-start;
  margin-top: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #464646;
}

.form_valid .be-icon {
  margin-right: 0.5rem;
  font-size: 1.25rem;
}

.form_ctrl_item:not(:first-child) {
  margin-top: 2rem;
}

.form_ctrl .txt {
  margin: 0 0 0.75rem;
}

.form .is-danger {
  --state-color: #d93d28;
}

.form .is-danger .radio_label,
.form .is-danger .checkbox_label {
  --state-color: #d93d28;
}

.form .is-danger .form_error {
  display: inline-flex;
}

.form[data-type='general'] .form_grp:not(:last-child) {
  margin-bottom: 2rem;
}

.form .gap-big {
  --gap-big: 0.75;
  margin-right: calc(var(--gap-big) * -1rem);
  margin-left: calc(var(--gap-big) * -1rem);
}

@media (min-width: 1024px) {
  .form .gap-big {
    --gap-big: 3.125;
  }
}

.form .gap-big > .form_grp {
  padding-right: calc(var(--gap-big) * 1rem);
  padding-left: calc(var(--gap-big) * 1rem);
}

.flex_head .checkbox_label {
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .flex_head {
    display: flex;
    justify-content: space-between;
  }
}

.flex_input-btn .form-input {
  margin-bottom: 0.625rem;
  width: 100%;
}

@media (min-width: 768px) {
  .flex_input-btn .form-input {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .flex_input-btn {
    display: flex;
    flex-wrap: nowrap;
    gap: 0.625rem 1.25rem;
  }
}

.form-input, .form-select, .form-textarea {
  padding: 0.6875rem 1rem;
  padding-right: var(--icon-padding);
  width: 100%;
  background-color: #fff;
  border: 0.0625rem solid var(--state-color);
  border-radius: 0.125rem;
  transition: 0.14s all;
}

.form-input::placeholder, .form-select::placeholder, .form-textarea::placeholder {
  color: #999;
}

.form-input[disabled], .form-select[disabled], .form-textarea[disabled] {
  color: #ccc;
  border-color: #e6e6e6;
}

.form-input[disabled] + .be-icon, .form-select[disabled] + .be-icon, .form-textarea[disabled] + .be-icon {
  --state-color: #ccc;
}

.form-input[disabled]::placeholder, .form-select[disabled]::placeholder, .form-textarea[disabled]::placeholder {
  color: #ccc;
}

.form-input:focus, .form-select:focus, .form-textarea:focus, .is\:focus.form-input, .is\:focus.form-select, .is\:focus.form-textarea {
  box-shadow: 0 0 0 3px rgba(70, 70, 70, 0.05);
}

.sty-underline {
  padding: 0.6875rem 0;
  padding-right: var(--icon-padding);
  border: 0;
  border-bottom: 0.0625rem solid var(--state-color);
  border-radius: 0;
  transition: 0.36s;
}

.sty-underline::placeholder {
  color: #999;
}

.sty-underline:focus, .is\:focus.sty-underline {
  box-shadow: 0 1px 0 0 #464646;
}

[type="number"] {
  /* Firefox */
  -moz-appearance: textfield;
}

[type="number"]::-webkit-outer-spin-button, [type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-select {
  -webkit-appearance: none;
  appearance: none;
}

.form-select ~ .be-icon {
  pointer-events: none;
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 1rem;
  font-size: 1.5rem;
  color: var(--state-color);
  transform: translateY(-50%);
}

.form-select.sty-underline ~ .be-icon {
  right: 0;
}

.select {
  padding-right: 1.875rem;
  background-color: transparent;
  border: 0;
}

.select-box {
  position: relative;
}

.select-box .be-icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  font-size: 1.5rem;
  transform: translateY(-50%);
  pointer-events: none;
}

.select-box .be-icon:first-child {
  left: 0;
}

.select-box .be-icon:first-child + .select {
  padding-left: 2rem;
}

.select-box .be-icon:last-child {
  right: 0;
}

.form-radio {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
}

.form-radio[disabled] + .radio_label {
  --state-color: #e6e6e6;
  cursor: default;
  color: #ccc;
}

.form-radio[disabled] + .radio_label .radio_checkmark {
  border-color: var(--state-color);
}

.form-radio[disabled] + .radio_label::after {
  background-color: var(--state-color);
}

.form-radio:not([disabled]):not(:checked) + .radio_label {
  color: #999;
}

.form-radio:not([disabled]):not(:checked) + .radio_label::after {
  background-color: #f5f5f5;
}

.form-radio:checked + .radio_label {
  --state-color: #464646;
}

.form-radio:checked + .radio_label .radio_checkmark::after {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.radio_grp {
  position: relative;
}

.radio_grp .form-radio:not(:checked) + .radio_label .form-input {
  pointer-events: none;
  border-color: #ccc;
}

.radio_grp .form-radio:not(:checked) + .radio_label .form-input::placeholder {
  color: #ccc;
}

.radio_label {
  --state-color: #f5f5f5;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.6875rem 0;
}

.radio_checkmark {
  position: relative;
  display: inline-block;
  margin: 3px;
  width: 1.125rem;
  height: 1.125rem;
  background-color: #fff;
  border: 0.0625rem solid #464646;
  border-radius: 50%;
  flex-shrink: 0;
}

.radio_checkmark::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #d93d28;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.5);
  transition: 0.14s all;
}

.radio_title {
  margin-left: 0.5rem;
}

.form-checkbox {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
}

.form-checkbox[disabled] + .checkbox_label {
  --state-color: #e6e6e6;
  cursor: default;
  color: #ccc;
}

.form-checkbox[disabled] + .checkbox_label .checkbox_checkmark {
  border-color: var(--state-color);
}

.form-checkbox[disabled] + .checkbox_label::after {
  background-color: var(--state-color);
}

.form-checkbox:not([disabled]):not(:checked) + .checkbox_label {
  color: #999;
}

.form-checkbox:not([disabled]):not(:checked) + .checkbox_label::after {
  background-color: #f5f5f5;
}

.form-checkbox:checked + .checkbox_label {
  --state-color: #464646;
}

.form-checkbox:checked + .checkbox_label .checkbox_checkmark .be-icon {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.checkbox_grp {
  position: relative;
}

.checkbox_label {
  --state-color: #f5f5f5;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.6875rem 0;
}

.checkbox_checkmark {
  position: relative;
  display: inline-block;
  margin: 3px;
  width: 1.125rem;
  height: 1.125rem;
  background-color: #fff;
  border: 0.0625rem solid #464646;
  border-radius: 0.125rem;
  flex-shrink: 0;
}

.checkbox_checkmark .be-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 1.25rem;
  color: #d93d28;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.5);
  transition: 0.14s all;
}

.checkbox_title {
  margin-left: 0.5rem;
}

.gap-1 {
  gap: 0.5rem;
}

.gap-row-1 {
  row-gap: 0.5rem;
}

.gap-col-1 {
  column-gap: 0.5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-row-2 {
  row-gap: 1rem;
}

.gap-col-2 {
  column-gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}

.gap-row-3 {
  row-gap: 1.5rem;
}

.gap-col-3 {
  column-gap: 1.5rem;
}

.gap-4 {
  gap: 2rem;
}

.gap-row-4 {
  row-gap: 2rem;
}

.gap-col-4 {
  column-gap: 2rem;
}

.gap-5 {
  gap: 2.5rem;
}

.gap-row-5 {
  row-gap: 2.5rem;
}

.gap-col-5 {
  column-gap: 2.5rem;
}

.gap-6 {
  gap: 3rem;
}

.gap-row-6 {
  row-gap: 3rem;
}

.gap-col-6 {
  column-gap: 3rem;
}

.gap-7 {
  gap: 3.5rem;
}

.gap-row-7 {
  row-gap: 3.5rem;
}

.gap-col-7 {
  column-gap: 3.5rem;
}

.gap-8 {
  gap: 4rem;
}

.gap-row-8 {
  row-gap: 4rem;
}

.gap-col-8 {
  column-gap: 4rem;
}

.input-wrap_percentage {
  position: relative;
}

.input-wrap_percentage::after {
  content: url("../img/common/percent.svg");
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 25px;
  height: 25px;
  transform: translateY(-50%);
}

.input-wrap_percentage > .form-input {
  padding-right: 33px;
}

.checkbox-input .form-input {
  max-width: 11.6875rem;
}

.radio-input {
  margin: 0 12px;
  max-width: 11.75rem;
}

.radio-input_grp {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.radio-input_radio .radio_label {
  align-items: center;
}

table th .checkbox_label,
table td .checkbox_label {
  align-items: center;
}

table .th-border-l,
table .td-border-l {
  position: relative;
}

table .th-border-l::before,
table .td-border-l::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -0.75px;
  width: 1.5px;
  height: calc(100% - 32px);
  background-color: #f5f5f5;
  transform: translateY(-50%);
}

table.first-row-left tbody td {
  font-size: 1rem;
}

table.first-row-left tbody td:first-child {
  padding-left: 1.5rem;
  text-align: left;
}

table.first-row-left tr:first-child th:first-child {
  text-align: left;
}

table.table th {
  padding: 12px;
  font-size: .875rem;
  font-weight: 400;
  background-color: #f5f5f5;
}

@media (min-width: 1024px) {
  table.table th {
    padding-top: 36px;
    padding-bottom: 36px;
    font-size: 1.125rem;
  }
}

table.table th:not(:last-child) {
  border-right: 1px solid #ccc;
}

table.table tbody tr:not(:last-child) td {
  border-bottom: 1px solid #ccc;
}

table.table tbody td {
  padding: 12px;
  font-size: .875rem;
}

@media (min-width: 1024px) {
  table.table tbody td {
    font-size: 1rem;
  }
}

table.table tbody td:not(:last-child) {
  border-right: 1px solid #ccc;
}

table.table tbody td:not(:first-child) {
  font-weight: bold;
}

table.table-fix {
  table-layout: fixed;
}

.response-icon {
  margin: 0 auto;
  max-width: 18.75rem;
}

.breadcrumb {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.875rem;
  color: #fff;
}

.breadcrumb_item {
  position: relative;
  display: flex;
}

.breadcrumb_item:not(:first-child) {
  margin-left: 0.5rem;
}

.breadcrumb_item:not(:first-child)::before {
  content: '-';
  margin-right: 0.5rem;
}

.breadcrumb_item:last-of-type {
  display: block;
}

.breadcrumb_link {
  text-decoration: underline;
  white-space: nowrap;
}

.breadcrumb_link:hover, .breadcrumb_link.is\:hover {
  text-decoration: underline;
  color: #464646;
}

.breadcrumb.clr-dark {
  color: #464646;
}

.modal {
  position: fixed;
  z-index: -1;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.3s opacity, 0.3s z-index 0s;
  pointer-events: none;
}

.modal.is-active {
  z-index: 9999;
  opacity: 1;
  transition: 0.3s opacity;
  pointer-events: all;
}

.modal.is-active .modal_inner {
  transform: translate(-50%, -50%) translateZ(0) scale(1);
}

.modal_inner {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  padding: 1.5rem;
  width: calc(100% - 40px);
  max-width: 43.75rem;
  background-color: #fff;
  border-radius: 0.125rem;
  transform: translate(-50%, -50%) translateZ(0) scale(0.95);
  transition: transform 0.36s;
}

@media (min-width: 768px) {
  .modal_inner {
    padding: 3rem 3.75rem;
  }
}

.modal_inner.sz-lg {
  max-width: 73.75rem;
}

@media (min-width: 1440px) {
  .modal_inner.sz-lg {
    padding-right: 8.625rem;
    padding-left: 8.625rem;
  }
}

.modal_title {
  margin-bottom: 1rem;
  padding: 0 1.5rem;
}

@media (min-width: 768px) {
  .modal_title {
    margin-bottom: 1.5rem;
  }
}

.modal_note {
  color: #808080;
}

.modal .btn-group {
  margin: 1.5rem auto 0;
  max-width: 28.75rem;
}

@media (min-width: 768px) {
  .modal .btn-group {
    margin-top: 2rem;
    gap: 1.25rem;
  }
}

.modal_close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0;
  font-size: 2.25rem;
  background-color: transparent;
  border: 0;
  transition: 0.36s ease-out;
}

@media (min-width: 1024px) {
  .modal_close {
    top: 1.5rem;
    right: 1.5rem;
    font-size: 2.5rem;
  }
  .modal_close:hover {
    color: #d93d28;
  }
}

.modal_close .be-icon {
  display: flex;
}

.modal_overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal .scroll-box {
  scrollbar-color: #d93d28 #f5f5f5;
  scrollbar-width: thin;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 60vh;
}

.modal .scroll-box::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.modal .scroll-box::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.modal .scroll-box::-webkit-scrollbar-thumb {
  background: #d93d28;
  border-radius: 4px;
}

.modal .scroll-box::-webkit-scrollbar-thumb:hover {
  background: #af301f;
}

.modal .scroll-box::-ms-scrollbar {
  width: 7px;
}

@media (min-width: 1024px) {
  .modal .scroll-box {
    max-height: 50vh;
  }
}

.tooltip {
  position: relative;
}

.tooltip_txt {
  position: absolute;
  z-index: 1;
  top: 100%;
  left: calc(50% + 6px);
  margin: 0;
  padding: 5px 10px;
  width: 120px;
  font-size: .875rem;
  text-align: center;
  white-space: initial;
  color: #fff;
  background-color: #333;
  border-radius: 6px;
  opacity: 0;
  transform: translate(-50%, -5px);
  transition: 0.36s all;
  pointer-events: none;
}

.tooltip_txt::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-style: solid;
  border-width: 5px;
  border-color: transparent transparent #333;
}

.tooltip:hover .tooltip_txt {
  opacity: 1;
  transform: translate(-50%, 0);
}

.toast {
  position: fixed;
  z-index: -1;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.3s opacity, 0.3s z-index 0s;
  pointer-events: none;
}

.toast.is-active {
  z-index: 9999;
  opacity: 1;
  transition: 0.3s opacity;
  pointer-events: all;
}

.toast.is-active .toast_inner {
  transform: translate(-50%, -50%) translateZ(0) scale(1);
}

.toast_inner {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  padding: 1.5rem 2rem;
  width: calc(100% - 80px);
  max-width: 23rem;
  text-align: center;
  background-color: #fff;
  border-radius: 0.125rem;
  transform: translate(-50%, -50%) translateZ(0) scale(0.95);
  transition: transform 0.36s;
}

.toast_icon {
  display: inline-flex;
  font-size: 2.5rem;
}

@media (min-width: 768px) {
  .toast_icon {
    font-size: 3.75rem;
  }
}

.toast_title {
  margin-top: 1rem;
}

.toast_note {
  color: #808080;
}

.toast_overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.progress-step {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% - 60px);
  height: 8px;
  background-color: #f5f5f5;
  border-radius: 2px;
}

.progress-step_wrap {
  width: 100%;
}

.progress-step_progress {
  width: 0;
  height: 100%;
  background-color: #d93d28;
  border-radius: 2px;
}

.progress-step_num {
  position: absolute;
  top: 50%;
  right: -3.75rem;
  transform: translateY(-50%);
}

.progress-step_labels {
  position: relative;
  margin-top: 1.25rem;
  width: 100%;
  width: calc(100% - 60px);
}

.progress-step_label {
  position: absolute;
  color: #ccc;
}

.progress-step_label.is-active {
  color: #464646;
}

.progress {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 768px) {
  .progress {
    flex-direction: row;
  }
}

.progress_progress {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0.5rem;
  background-color: #f5f5f5;
  border-radius: 0.125rem;
}

.progress_bar {
  display: block;
  height: 100%;
  background-color: #999;
}

.progress_info {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5rem;
  width: 100%;
}

@media (min-width: 768px) {
  .progress_info {
    margin-top: 0;
    padding-right: 2rem;
    padding-left: 0.75rem;
    width: auto;
  }
}

.progress_num {
  min-width: 6.25rem;
  font-size: 0.875rem;
  text-align: right;
  white-space: nowrap;
  color: #999;
}

@media (min-width: 768px) {
  .progress_num {
    min-width: 9.375rem;
    font-size: 1.125rem;
  }
}

.progress_icon {
  display: none;
  font-size: 1.25rem;
  color: #d93d28;
}

@media (min-width: 768px) {
  .progress_icon {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 1.5rem;
    transform: translateY(-50%);
  }
}

.progress.is-selected .progress_bar {
  background-color: #d93d28;
}

.progress.is-selected .progress_num {
  font-weight: 500;
  color: #464646;
}

.progress.is-selected .progress_icon {
  display: block;
}

.step {
  flex-shrink: 0;
}

.step_wrap {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.step_linebetween {
  content: '';
  position: relative;
  margin: 0 0.5rem;
  width: 100%;
  max-width: 194px;
}

@media (min-width: 1024px) {
  .step_linebetween {
    margin: 0 1.5rem;
  }
}

.step_linebetween::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background-color: #f5f5f5;
}

.step_linebetween.active::before {
  background-color: #464646;
}

.step_num {
  margin: 0 auto 0.5rem;
  padding: 0.2rem 0.45rem 2px;
  width: fit-content;
  font-size: 1.125rem;
  border: 0.0625rem solid #464646;
  border-radius: 0.125rem;
}

@media (min-width: 768px) {
  .step_num {
    font-size: 1.375rem;
  }
}

.step_title {
  color: #ccc;
}

@media (min-width: 768px) {
  .step_title {
    font-size: 1.125rem;
  }
}

.step.is-active .step_num {
  font-family: 'Noto Serif HK', serif;
  color: #fff;
  background-color: #464646;
}

.step.is-active .step_title {
  color: #464646;
}

.number-list {
  padding-left: 1rem;
}

.number-list .highlight {
  color: #d93d28;
}

.list-note {
  margin: 0;
  margin-bottom: 1.5rem;
  padding: 0;
  list-style-type: none;
  color: #999;
}

.list-note_item {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin-bottom: 0.25rem;
}

.list-note_item:last-child {
  margin-bottom: 1.5rem;
}

.list-note_item .be-icon {
  margin-right: 0.25rem;
}

.list-dot {
  margin: 0;
  margin-bottom: 1.5rem;
  padding: 0;
  list-style-type: none;
  color: #464646;
}

.list-dot_item {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin-bottom: 0.25rem;
}

.list-dot_item:last-child {
  margin-bottom: 1.5rem;
}

.list-dot_item .be-icon {
  margin-right: 0.25rem;
}

@media (min-width: 768px) {
  .news_link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

@media (min-width: 1024px) {
  .news_link:hover .be-icon {
    transform: translateX(0.625rem);
  }
}

.news-list.is-locked {
  pointer-events: none;
  color: inherit !important;
}

.news-list.is-locked .news_title .be-icon {
  display: none;
}

.news-list_item {
  padding: 1rem 0.625rem;
  border-bottom: 0.0625rem solid #ccc;
}

@media (min-width: 1024px) {
  .news-list_item {
    padding: 1.5rem 1rem;
  }
}

.news-list_item.page-list .news_title {
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  font-size: 1rem;
}

@media (min-width: 768px) {
  .news-list_item.page-list .news_title {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-size: 1.125rem;
  }
}

.news-list_item.page-list .news_title .be-icon {
  position: absolute;
  bottom: -0.3125rem;
  right: 0;
}

@media (min-width: 768px) {
  .news-list_item.page-list .news_title .be-icon {
    position: initial;
    margin-left: 1.5rem;
  }
}

.news-list_item.page-list .news_date {
  margin-top: 0.5rem;
  margin-right: auto;
  font-size: 0.875rem;
  color: #999;
}

@media (min-width: 768px) {
  .news-list_item.page-list .news_date {
    margin-top: 0;
    margin-right: initial;
    margin-left: auto;
    padding-left: 1.5rem;
  }
}

.news_tag {
  --clip-height: 0.75rem;
  display: inline-block;
  margin-bottom: 0.75rem;
  padding: 0.25rem 1.125rem 0.25rem 0.375rem;
  min-width: 7rem;
  font-size: 0.875rem;
  line-height: calc(20 / 14);
  color: #fff;
  background-color: #464646;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--clip-height)), calc(100% - var(--clip-height)) 100%, 0 100%);
}

.news_tag.highlight {
  background-color: #d93d28;
}

@media (min-width: 768px) {
  .news_tag {
    margin-bottom: 0;
    margin-right: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .news_tag {
    min-width: 8.125rem;
  }
}

.news_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
}

.news_title .be-icon {
  margin-left: 0.625rem;
  font-size: 1.75rem;
  transition: 0.36s all;
}

@media (min-width: 1440px) {
  .news_title .be-icon {
    font-size: 2rem;
  }
}

.file_list_item {
  padding: 1rem 0.625rem;
  border-bottom: 0.0625rem solid #ccc;
}

@media (min-width: 1024px) {
  .file_list_item {
    padding: 0.625rem 1rem;
  }
}

.file_info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.file_info [class*='btn'] {
  margin-top: 0.625rem;
  width: 100%;
}

@media (min-width: 768px) {
  .file_info [class*='btn'] {
    margin-top: 0;
    width: auto;
  }
}

@media (min-width: 768px) {
  .file_info {
    align-items: center;
    flex-wrap: nowrap;
  }
}

.file_type {
  display: flex;
  margin-right: 0.5rem;
  font-size: 2.625rem;
}

@media (min-width: 768px) {
  .file_type {
    margin-right: 1.5rem;
    font-size: 3.75rem;
  }
}

.file_title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  margin: 0;
  width: calc(100% - 3.125rem);
  font-size: 1rem;
}

@media (min-width: 768px) {
  .file_title {
    align-items: center;
    flex-direction: row;
    margin-right: 1.5rem;
    font-size: 1.125rem;
  }
}

.file_date {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #999;
}

@media (min-width: 768px) {
  .file_date {
    margin-top: 0;
    margin-right: initial;
    margin-left: auto;
    padding-left: 1.5rem;
  }
}

.filter-form {
  --filter-mx: 0.75rem;
  --filter-my: 0.375rem;
  margin: 0 calc(var(--filter-my) * -1) calc(var(--filter-mx) * -1);
}

@media (min-width: 768px) {
  .filter-form {
    --filter-mx: 0.75rem;
    --filter-my: var(--filter-mx);
  }
}

.filter-btn {
  display: inline-flex;
  align-items: flex-end;
  margin: 0 var(--filter-my) var(--filter-mx);
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #999;
  background-color: #fff;
  border: 0.0625rem solid #808080;
  border-radius: 0.125rem;
}

@media (min-width: 1024px) {
  .filter-btn {
    font-size: 1.125rem;
  }
  .filter-btn:hover {
    color: #fff;
    background-color: #464646;
    transition: 0.36s ease-out;
    transition-property: color, background-color, border-color, box-shadow;
  }
}

.filter-btn .be-icon {
  display: none;
  margin-left: 0.5rem;
  font-size: 1.5rem;
  color: #fff;
}

.filter-btn.is-active {
  color: #fff;
  background-color: #464646;
}

.filter-btn.is-active .be-icon {
  display: inline-block;
}

.mark {
  --clip-height: 0.875rem;
  position: absolute;
  z-index: 2;
  left: -0.5rem;
  display: inline-block;
  margin-bottom: 0.75rem;
  padding: 0.25rem 1.125rem 0.25rem 0.375rem;
  min-width: 6rem;
  font-size: 0.75rem;
  color: #464646;
  background-color: #fff;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--clip-height)), calc(100% - var(--clip-height)) 100%, 0 100%);
}

.mark.primary {
  background-color: #d93d28;
}

.mark.dark {
  background-color: #464646;
}

.mark.highlight {
  background-color: #d93d28;
}

@media (min-width: 768px) {
  .mark {
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .mark {
    min-width: 8.125rem;
    font-size: 0.875rem;
  }
}

.main-title {
  position: relative;
  margin: 0;
  margin-bottom: 1.5rem;
  padding-left: 12px;
}

.main-title::before {
  content: '';
  position: absolute;
  top: 0.1em;
  left: 0;
  width: 0.375rem;
  height: 1.45em;
  background-color: #464646;
  transform: translateX(-50%);
}

.main-title.color-subtitle::before {
  background-color: #999;
}

.main-title .form_error {
  margin-left: 0.5rem;
}

.indent-box .main-title::before {
  background-color: #999;
}

.badge {
  position: relative;
  display: inline-block;
  margin-left: 0.5rem;
  padding: 0.25rem 0.625rem;
  font-size: 0.875rem;
  line-height: inherit;
  color: #fff;
  background-color: #808080;
}

@media (min-width: 1024px) {
  .badge {
    top: calc(0.75em - 1rem);
    font-size: 1rem;
  }
}

.badge--outline {
  padding: 0.2rem 0.625rem;
  font-size: 0.75rem;
  font-weight: 400;
  color: #ccc;
  background-color: transparent;
  border: 1.5px solid #ccc;
  border-radius: 12px;
}

@media (min-width: 768px) {
  .badge--outline {
    font-size: 0.875rem;
  }
}

.title-deco-blockafter {
  position: relative;
}

.title-deco-blockafter::after {
  content: '';
  position: absolute;
  top: calc(100% + 0.75rem);
  left: 50%;
  width: 2.5rem;
  height: 0.25rem;
  background-color: #d93d28;
  transform: translateX(-50%);
}

.title-deco-blockafter--clr-dark::after {
  background-color: #464646;
}

.fz-span {
  font-size: 0.75rem;
}

@media (min-width: 768px) {
  .fz-span {
    font-size: 1rem;
  }
}

.fw-medium {
  font-weight: 500;
}

@media (min-width: 1024px) {
  .normal-title {
    font-size: 1.125rem;
  }
}

@use 'sass:map';
.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.ml-1 {
  margin-left: 0.5rem !important;
}

.mr-1 {
  margin-right: 0.5rem !important;
}

.mx-1 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.my-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.m-n1 {
  margin: -0.5rem !important;
}

.mt-n1 {
  margin-top: -0.5rem !important;
}

.mb-n1 {
  margin-bottom: -0.5rem !important;
}

.ml-n1 {
  margin-left: -0.5rem !important;
}

.mr-n1 {
  margin-right: -0.5rem !important;
}

.mx-n1 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.my-n1 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.ml-2 {
  margin-left: 1rem !important;
}

.mr-2 {
  margin-right: 1rem !important;
}

.mx-2 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.my-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-n2 {
  margin: -1rem !important;
}

.mt-n2 {
  margin-top: -1rem !important;
}

.mb-n2 {
  margin-bottom: -1rem !important;
}

.ml-n2 {
  margin-left: -1rem !important;
}

.mr-n2 {
  margin-right: -1rem !important;
}

.mx-n2 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.my-n2 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.ml-3 {
  margin-left: 1.5rem !important;
}

.mr-3 {
  margin-right: 1.5rem !important;
}

.mx-3 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.mt-n3 {
  margin-top: -1.5rem !important;
}

.mb-n3 {
  margin-bottom: -1.5rem !important;
}

.ml-n3 {
  margin-left: -1.5rem !important;
}

.mr-n3 {
  margin-right: -1.5rem !important;
}

.mx-n3 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.my-n3 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.m-4 {
  margin: 2.25rem !important;
}

.mt-4 {
  margin-top: 2.25rem !important;
}

.mb-4 {
  margin-bottom: 2.25rem !important;
}

.ml-4 {
  margin-left: 2.25rem !important;
}

.mr-4 {
  margin-right: 2.25rem !important;
}

.mx-4 {
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important;
}

.my-4 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.m-n4 {
  margin: -2.25rem !important;
}

.mt-n4 {
  margin-top: -2.25rem !important;
}

.mb-n4 {
  margin-bottom: -2.25rem !important;
}

.ml-n4 {
  margin-left: -2.25rem !important;
}

.mr-n4 {
  margin-right: -2.25rem !important;
}

.mx-n4 {
  margin-left: -2.25rem !important;
  margin-right: -2.25rem !important;
}

.my-n4 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.m-5 {
  margin: 2.5rem !important;
}

.mt-5 {
  margin-top: 2.5rem !important;
}

.mb-5 {
  margin-bottom: 2.5rem !important;
}

.ml-5 {
  margin-left: 2.5rem !important;
}

.mr-5 {
  margin-right: 2.5rem !important;
}

.mx-5 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.my-5 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.m-n5 {
  margin: -2.5rem !important;
}

.mt-n5 {
  margin-top: -2.5rem !important;
}

.mb-n5 {
  margin-bottom: -2.5rem !important;
}

.ml-n5 {
  margin-left: -2.5rem !important;
}

.mr-n5 {
  margin-right: -2.5rem !important;
}

.mx-n5 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.my-n5 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.p-auto {
  padding: auto !important;
}

.pt-auto {
  padding-top: auto !important;
}

.pb-auto {
  padding-bottom: auto !important;
}

.pl-auto {
  padding-left: auto !important;
}

.pr-auto {
  padding-right: auto !important;
}

.px-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}

.py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.pt-1 {
  padding-top: 0.5rem !important;
}

.pb-1 {
  padding-bottom: 0.5rem !important;
}

.pl-1 {
  padding-left: 0.5rem !important;
}

.pr-1 {
  padding-right: 0.5rem !important;
}

.px-1 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.py-1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.pt-2 {
  padding-top: 1rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.pl-2 {
  padding-left: 1rem !important;
}

.pr-2 {
  padding-right: 1rem !important;
}

.px-2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pl-3 {
  padding-left: 1.5rem !important;
}

.pr-3 {
  padding-right: 1.5rem !important;
}

.px-3 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-4 {
  padding: 2.25rem !important;
}

.pt-4 {
  padding-top: 2.25rem !important;
}

.pb-4 {
  padding-bottom: 2.25rem !important;
}

.pl-4 {
  padding-left: 2.25rem !important;
}

.pr-4 {
  padding-right: 2.25rem !important;
}

.px-4 {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.py-4 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.p-5 {
  padding: 2.5rem !important;
}

.pt-5 {
  padding-top: 2.5rem !important;
}

.pb-5 {
  padding-bottom: 2.5rem !important;
}

.pl-5 {
  padding-left: 2.5rem !important;
}

.pr-5 {
  padding-right: 2.5rem !important;
}

.px-5 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.py-5 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.color-white {
  color: #fff;
}

.color-dark {
  color: #464646;
}

.color-primary {
  color: #d93d28;
}

.color-gray-40 {
  color: #f5f5f5;
}

.color-gray-500 {
  color: #808080;
}

.clr-dark {
  color: #464646;
}

.clr-gray400 {
  color: #999;
}

.bg-gray-40 {
  background-color: #f5f5f5;
}

.bg-gray-500 {
  background-color: #808080;
}

.chart_wrap {
  margin-bottom: 3rem;
}

.chart_wrap.set-border {
  padding: 1.25rem 2.5rem;
  border: 0.0625rem solid #ccc;
}

@media (min-width: 1440px) {
  .chart_wrap.set-border {
    padding-right: 5.9375rem;
    padding-left: 5.9375rem;
  }
}

.chart_wrap.is-searching .point-active {
  pointer-events: all;
  opacity: 1;
}

.chart_wrap[data-range] .droppoint-chart_alert {
  display: block;
}

.chart_wrap[data-range] .point_info {
  display: none;
}

.chart_wrap[data-range] .point-active {
  opacity: 0;
}

.chart_wrap[data-range="min"] .point-active {
  pointer-events: all;
  left: -3.0625rem !important;
  opacity: 1;
}

.chart_wrap[data-range="max"] .point-active {
  left: calc(100% + 3.0625rem) !important;
  opacity: 1;
}

.chart_box {
  height: 28.125rem;
}

.chart_title {
  margin: 0 0 1.5rem;
  font-size: 1.5rem;
}

.chart_title .small {
  display: block;
  margin-top: .5rem;
  font-size: 1.125rem;
}

.legend {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  margin-top: 1.5rem;
  padding: 0;
}

.legend_item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: .75rem;
}

@media (min-width: 1200px) {
  .legend_item {
    font-size: .875rem;
  }
}

@media (min-width: 1440px) {
  .legend_item {
    font-size: 1rem;
  }
}

.legend_color {
  display: block;
  margin-top: 0.125rem;
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
}

.legend_label {
  margin-right: 0.5rem;
  font-size: inherit;
}

.legend_count {
  font-size: inherit;
  font-weight: 500;
}

.legend_percent {
  margin-left: 2px;
  font-size: inherit;
  color: #999;
}

.doughnut-chart_wrap {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.doughnut-chart_wrap .legend {
  align-items: flex-start;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
}

.doughnut-chart_wrap .legend_item:not(:last-child) {
  margin-bottom: .5rem;
}

.doughnut-chart_item {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  padding: 0 0.375rem;
  width: 20%;
  text-align: center;
}

.doughnut-chart_box {
  position: relative;
  margin: auto auto 0;
  max-width: 9.375rem;
  height: 9.375rem;
}

.doughnut-chart_sum {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 8.125rem;
  height: 8.125rem;
  background-color: #fff;
  border: 0.0625rem solid #464646;
  border-radius: 7.5rem;
  transform: translate(-50%, -50%);
}

.doughnut-chart_sum::after {
  content: '';
  position: absolute;
  display: block;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  border: 0.0625rem solid #ccc;
  border-radius: 50%;
}

.doughnut-chart_title {
  margin: 0 auto 0.75rem;
  padding: 0 0.625rem;
  min-height: 3rem;
}

.doughnut-chart_title .small {
  display: block;
}

.droppoint-chart {
  position: relative;
  margin: 7.5rem 6.125rem 2.5rem;
}

.droppoint-chart_point {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transform: translateY(-50%);
}

.droppoint-chart_line {
  left: 0;
  width: 100%;
  height: .5rem;
  background: linear-gradient(90deg, #D9D9D9 0%, #464646 104.6%);
}

.droppoint-chart_line::before, .droppoint-chart_line::after {
  content: '';
  position: absolute;
  top: 50%;
  display: block;
  width: 6.125rem;
  height: 3px;
  transform: translateY(-50%);
}

.droppoint-chart_line::before {
  right: 100%;
  background: url("img/p-l.svg") no-repeat left center;
  background-size: cover;
}

.droppoint-chart_line::after {
  left: 100%;
  background: url("img/p-r.svg") no-repeat right center;
  background-size: cover;
}

.droppoint-chart_search {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto 1.5rem;
  max-width: 37.5rem;
}

.droppoint-chart_search .form_grp {
  margin: 0;
  margin-right: 1.25rem;
  width: 27.5rem;
}

.droppoint-chart_alert {
  position: absolute;
  bottom: 100%;
  left: 50%;
  display: none;
  margin-bottom: 55px;
  padding: .5rem .625rem;
  text-align: center;
  color: #d93d28;
  background-color: rgba(217, 61, 40, 0.1);
  transform: translateX(-50%);
}

.droppoint-chart .point:not(.point-active) {
  position: absolute;
  top: 0;
  text-align: center;
  transform: translate(0%, -50%);
}

.droppoint-chart .point:not(.point-active):nth-child(2) {
  left: 0%;
}

.droppoint-chart .point:not(.point-active):nth-child(2) .point_title::after {
  left: 0%;
  transform: none;
}

.droppoint-chart .point:not(.point-active):nth-child(3) {
  left: 25%;
  transform: translate(-50%, -50%);
}

.droppoint-chart .point:not(.point-active):nth-child(4) {
  left: 50%;
  transform: translate(-50%, -50%);
}

.droppoint-chart .point:not(.point-active):nth-child(5) {
  left: 75%;
  transform: translate(-50%, -50%);
}

.droppoint-chart .point:not(.point-active):nth-child(6) {
  right: 0;
}

.droppoint-chart .point:last-child {
  text-align: right;
}

.droppoint-chart .point:last-child .point_title::after {
  left: 100%;
  transform: none;
}

.droppoint-chart .point_title {
  position: relative;
  margin-bottom: 2.375rem;
}

.droppoint-chart .point_title::after {
  content: '';
  position: absolute;
  top: calc(100% + .75rem);
  left: 50%;
  display: block;
  width: .125rem;
  height: .875rem;
  background-color: #464646;
  box-shadow: 2px 2px 30px 0 #464646;
  transform: translateX(-50%);
}

.droppoint-chart .point-active {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 0;
  opacity: 0;
  transform: translateY(-50%);
  pointer-events: none;
}

.droppoint-chart .point-active::after {
  content: '';
  position: relative;
  display: block;
  width: 1rem;
  height: 1rem;
  background-color: #d93d28;
  border-radius: 1rem;
  transform: translateX(-50%);
  animation: 1.5s wave infinite cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.droppoint-chart .point-active .point_info {
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 2.8125rem);
  left: 0;
  padding: .25rem .625rem;
  color: #fff;
  background-color: #d93d28;
  transform: translateX(-50%);
}

.droppoint-chart .point-active .point_info::after {
  content: '';
  position: absolute;
  z-index: 0;
  top: 100%;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 0;
  border-color: #d93d28 transparent transparent transparent;
  transform: translateX(-50%);
}

.droppoint-chart .point-active .point_title {
  margin-bottom: 0;
  font-weight: 700;
  text-transform: uppercase;
}

.droppoint-chart .point-active .point_title::after {
  content: none;
}

@keyframes wave {
  0% {
    box-shadow: 0 0 0 0 rgba(217, 61, 40, 0.8);
  }
  100% {
    box-shadow: 0 0 0 7px rgba(217, 61, 40, 0);
  }
}

.pagination-nav {
  margin-top: 2.25rem;
}

.pagination-ul {
  align-items: center;
  justify-content: center;
  padding: 0;
}

.pagination-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.1875rem;
  padding: 0.25rem 0.5rem;
  width: 1.875rem;
  height: 1.875rem;
  border: 0.125rem solid transparent;
  transition: 0.36s;
  cursor: pointer;
}

@media (min-width: 768px) {
  .pagination-item {
    margin: 0 0.3125rem;
    width: 2.5rem;
    height: 2.5rem;
  }
}

.pagination-item:hover {
  border: 0.125rem solid #808080;
  border-radius: 0.125rem;
}

.pagination-item:hover .pagination-link {
  color: #999;
}

.pagination-item:hover.next, .pagination-item:hover.previous {
  border: 0.125rem solid transparent;
}

.pagination-item.next .pagination-link, .pagination-item.previous .pagination-link {
  color: #464646;
}

.pagination-item.next {
  margin-left: 0.3125rem;
}

@media (min-width: 768px) {
  .pagination-item.next {
    margin-left: 1.375rem;
  }
}

.pagination-item.previous {
  margin-right: 0.3125rem;
}

@media (min-width: 768px) {
  .pagination-item.previous {
    margin-right: 1.375rem;
  }
}

.pagination-item.current {
  position: relative;
  background: #464646;
  border-radius: 0.125rem;
  pointer-events: none;
}

.pagination-item.current::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 1.25rem;
  height: 0.125rem;
  transform: translateX(-50%);
}

.pagination-item.current .pagination-link {
  color: #fff;
}

.pagination-item.disabled {
  pointer-events: none;
  background: none;
}

.pagination-item.disabled .pagination-link {
  opacity: 0.5;
}

.pagination-item.ellipsis {
  pointer-events: none;
}

.pagination-item .be-icon {
  font-size: 1.875rem;
}

@media (min-width: 768px) {
  .pagination-item .be-icon {
    font-size: 2.5rem;
  }
}

.pagination-item .be-icon-ellipsis {
  color: #808080;
}

.pagination-link {
  font-size: 1.125rem;
  font-family: 'Noto Serif HK', serif;
  font-weight: 600;
  color: #999;
  transition: 0.5s;
}

@media (min-width: 768px) {
  .pagination-link {
    font-size: 1.375rem;
  }
}

.tab-box {
  --tab-margin: 0.75rem;
  position: relative;
  background-color: #fff;
}

.tab_list {
  display: flex;
  align-items: center;
  overflow-x: auto;
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 1024px) {
  .tab_list {
    padding-top: 1.5rem;
  }
}

.tab_item {
  position: relative;
  margin: 0 calc(var(--tab-margin) * 0.5);
  white-space: nowrap;
}

@media (min-width: 768px) {
  .tab_item {
    margin: 0 var(--tab-margin);
  }
}

.tab_item:first-of-type {
  margin-left: auto;
}

.tab_item:last-of-type {
  margin-right: auto;
}

.tab_a {
  display: inline-block;
  padding: 0.5rem 0.625rem 0.875rem;
  font-size: 1.125rem;
  text-align: center;
  color: #808080;
}

@media (min-width: 768px) {
  .tab_a {
    padding: 0.5rem 1.5rem 0.875rem;
    min-width: 9.75rem;
  }
}

.tab_a.is-active {
  position: relative;
  font-weight: 500;
  color: #464646;
}

.tab_a.is-active::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 5rem;
  height: 0.375rem;
  background: #464646;
  transform: translateX(-50%);
}

.tab_panel {
  display: none;
}

.tab_panel.is-active {
  display: block;
}

.tab\:line .tab_item:not(:last-child)::after {
  content: '';
  position: absolute;
  top: .625rem;
  right: 0;
  display: block;
  width: .0625rem;
  height: 1.75rem;
  background-color: #ccc;
}

/*
    $h: height,
    $lh: line-height,
    $bw: border-width,
    $fs: font-size
*/
[disabled],
.is\:off {
  background: #fff;
  cursor: default;
  pointer-events: none;
}

.header_nav, .header_subnav, .header .userinfo_nav, .card-result .list, .news-list, .file_list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.html-editor h1,
.html-editor h2,
.html-editor h3,
.html-editor h4,
.html-editor h5,
.html-editor h6 {
  position: relative;
  margin-bottom: rem-calc(15);
  padding-top: rem-calc(10);
}

.html-editor h1 {
  font-size: 1.5em;
}

@media (min-width: 768px) {
  .html-editor h1 {
    font-size: 3em;
  }
}

.html-editor h1.h4 {
  font-size: 1.5625em !important;
}

.html-editor h2 {
  font-size: 1.75em;
}

.html-editor h3 {
  font-size: 1.5em;
}

.html-editor h4 {
  font-size: 1.25em;
}

.html-editor h5 {
  font-size: 1em;
}

.html-editor h6 {
  font-size: 1em;
}

.html-editor strong {
  font-weight: 500;
}

.html-editor p {
  margin: 0 0 0.75rem;
  font-size: 100%;
  line-height: 150%;
}

.html-editor a {
  text-decoration: underline;
}

.html-editor iframe {
  margin-bottom: rem-calc(20);
  max-width: 100%;
}

.html-editor .table-scroll {
  overflow-x: auto;
}

.html-editor .table-scroll table {
  min-width: 500px;
  white-space: nowrap;
}

.html-editor table {
  word-break: break-all;
}

.html-editor table tr:not(:last-of-type) {
  border-bottom: 1px solid #ccc;
}

.html-editor table th {
  font-weight: 400;
  background-color: #f5f5f5;
}

.html-editor table th,
.html-editor table td {
  padding: 1.4375rem 0.75rem;
}

.html-editor table th:not(:last-of-type),
.html-editor table td:not(:last-of-type) {
  border-right: 1px solid #ccc;
}

.html-editor ul, .html-editor ol {
  margin-left: calc(1em + 4px);
  padding: 0;
}

.html-editor ul li, .html-editor ol li {
  margin: 0;
}

.html-editor ul {
  list-style: none;
}

.html-editor ul > li {
  position: relative;
}

.html-editor ul > li::before {
  content: '';
  position: absolute;
  top: 1px;
  left: calc(-1em - 4px);
  display: inline-block;
  width: 1em;
  height: 1.6em;
  background: url("img/common/dot.svg") no-repeat center;
  background-size: 1em;
}

.html-editor li {
  width: 100%;
  line-height: 160%;
}

.html-editor a {
  color: #d93d28;
}

.html-editor p,
.html-editor option,
.html-editor span,
.html-editor button,
.html-editor a,
.html-editor li,
.html-editor input {
  font-size: inherit;
  font-weight: inherit;
}

.html-editor tbody,
.html-editor tfoot,
.html-editor thead {
  background-color: white;
}

.html-editor tbody tr:nth-child(even) {
  background-color: white;
}

.html-editor--exception h1::before, .html-editor--exception h1::after,
.html-editor--exception h2::before,
.html-editor--exception h2::after,
.html-editor--exception h3::before,
.html-editor--exception h3::after,
.html-editor--exception h4::before,
.html-editor--exception h4::after,
.html-editor--exception h5::before,
.html-editor--exception h5::after,
.html-editor--exception h6::before,
.html-editor--exception h6::after {
  content: none;
}

.html-editor--exception img {
  max-width: 100%;
}

.html-editor--exception::before, .html-editor--exception::after {
  content: none !important;
}

[lang*='ja'] {
  font-family: 'Noto Sans JP', sans-serif;
}

[lang*='ja'] .serif-lang-title {
  font-family: 'Noto Serif HK', serif;
}

@media (min-width: 1024px) {
  [lang*='ja'] .header_nav_link {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

@media (min-width: 75rem) {
  [lang*='ja'] .header_nav_link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

[lang*='ja'] .footer .info_title {
  min-width: 6.25rem;
}

[lang*='zh'] {
  font-family: 'Noto Sans TC', serif;
}

[lang*='zh'] .serif-lang-title,
[lang*='zh'] .serif-title {
  font-family: 'Noto Serif HK', serif;
}
