html,
body {
	--header-height: 56px;

	overflow-x: hidden;
	overflow-y: auto;
	color: $dark;
	scroll-behavior: smooth;

	@include bp-lg {
		--header-height: 72px;
	}
}

a {
	transition: color $ms-faster ease;

	@include bp-lg {
		&:hover {
			color: $primary;
		}
	}
}

label {
	cursor: pointer;
}

.h1 {
	font-size: remy(24);
	font-weight: 700;
	line-height: (36 / 24);

	@include bp-lg {
		font-size: remy(33);
	}

	@include bp-xl {
		font-size: remy(42);
		line-height: (60 / 42);
	}
}

.h2 {
	font-size: remy(20);
	font-weight: 600;
	line-height: (30 / 20);

	@include bp-lg {
		font-size: remy(26);
	}

	@include bp-xl {
		font-size: remy(32);
		line-height: (44 / 32);
	}
}

.h3 {
	font-size: remy(18);
	font-weight: 400;
	line-height: (26 / 18);

	@include bp-lg {
		font-size: remy(23);
	}

	@include bp-xl {
		font-size: remy(28);
		line-height: (42 / 28);
	}
}

.h4 {
	font-size: remy(16);
	font-weight: 400;
	line-height: (24 / 16);

	@include bp-lg {
		font-size: remy(20);
	}

	@include bp-xl {
		font-size: remy(24);
		line-height: (40 / 24);
	}
}

.h5 {
	font-size: remy(14);
	font-weight: 400;
	line-height: (20 / 14);

	@include bp-lg {
		font-size: remy(18);
	}

	@include bp-xl {
		font-size: remy(22);
		line-height: (34 / 22);
	}
}

.h6 {
	font-size: remy(12);
	font-weight: 400;
	line-height: (18 / 12);

	@include bp-lg {
		font-size: remy(15);
	}

	@include bp-xl {
		font-size: remy(18);
		line-height: (26 / 18);
	}
}

.paragraph-main {
	@include body1;

	margin: 0;
}

.paragraph-second {
	@include body2;

	margin: 0;
}

// --------------------------------------------------------

.main-wrapper {
	position: relative;
	padding-top: 3.0625rem;

	@include bp-lg {
		padding-top: 8.375rem;
	}
}

.container {
	margin: auto;
	width: 100%;
	max-width: 96.25rem;

	&.sz-sm {
		max-width: 56.25rem;
	}

	&.sz-smd {
		max-width: 62.5rem;

		@include bp-xl {
			max-width: 66.25rem;
		}
	}

	&.sz-md {
		max-width: remy(1160);

		@include bp-xl {
			max-width: remy(1300);
		}
	}
}

// 區塊設定
.page-container {
	--headline-x-pos: 3.75rem;

	position: relative;
	background-color: $gray-40;

	@include bp-lg {
		--headline-x-pos: 6.25rem;
	}

	&.over-content {
		--padding-top-pos: 4.625rem;

		@include bp-md {
			--padding-top-pos: 6.875rem;
		}

		.section-content {
			padding-top: 0;
			min-height: 50vh;

			@include bp-md {
				min-height: 80vh;
			}

			@include bp-lg {
				min-height: 45.5rem;
			}
		}

		.section-headline {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}

		.over-padding {
			padding-top: var(--padding-top-pos);
		}

		.w-full {
			padding-top: var(--padding-top-pos);
			padding-bottom: 1.5rem;

			@include bp-md {
				padding-bottom: 2.25rem;
			}
		}
	}

	.over-banner {
		margin-top: calc(var(--headline-x-pos) * -1);
		padding-top: 0;
	}
}

.section-banner {
	position: relative;
	z-index: 2;
	margin: calc(var(--headline-x-pos) * -1) auto 0;

	.object-fit {
		&::before {
			padding-top: percentage(590 / 1180);
		}
	}

	~ .section-headline {
		margin-top: remy(-30);

		@include bp-md {
			margin: calc(var(--headline-x-pos) * -1) auto 0;
		}
	}
}

.section-headline {
	position: relative;
	z-index: 3;
	margin: calc(var(--headline-x-pos) * -1) auto 0;
	width: calc(100% - 3rem);
	max-width: 58.75rem;
	text-align: center;
	color: $white;

	&_title {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: calc(100% + 0.75rem);
			left: 50%;
			width: 5.375rem;
			height: 0.25rem;
			background-color: $white;
			transform: translateX(-50%);
		}
	}

	&_item {
		position: relative;
		padding: 1.5rem 1.5rem 3rem;
		background-color: $dark;

		@include bp-lg {
			padding: 3rem 1.5rem 4.125rem;
		}

		&.mark-title {
			padding: remy(20);

			@include bp-md {
				padding: remy(24) remy(32);
			}

			.section-headline_title {
				margin-bottom: remy(16);
				text-align: left;

				&::after {
					content: none;
				}
			}
		}

		.mark {
			top: -0.875rem;

			@include bp-lg {
				--clip-height: 1.25rem;

				top: -1.25rem;
				padding: 0.5rem 2.8125rem 0.5rem 0.625rem;
				min-width: 8.125rem;
				font-size: 1rem;
			}
		}
	}

	&_txt {
		margin: 0;
		font-size: remy(14);
		font-weight: 400;
		text-align: left;
	}

	.white-box {
		padding-top: 2.25rem;
		padding-bottom: 2.25rem;
		color: $dark;
	}

	&--white {
		.section-headline {
			&_title {
				color: $dark;

				&::after {
					background-color: $primary;
				}
			}

			&_item {
				background-color: $white;
			}
		}
	}

	&.sz-sm {
		padding: 0 1.5rem;
		width: 100%;
		max-width: 56.25rem;

		@include bp-lg {
			padding: 0 1.875rem;
			max-width: 56.25rem;
		}

		@include bp-xl {
			padding: 0 3.75rem;
		}
	}
}

.section-content {
	position: relative;
	z-index: 2;
	overflow: hidden;
	padding: 1.875rem 0 3.75rem;

	@include bp-lg {
		padding: 3.75rem 0 5.25rem;
	}

	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		bottom: 0;
		right: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 40rem;
		background: linear-gradient(180deg, $gray-40 0%, #eaeaea 100%);
		pointer-events: none;
	}

	.hr {
		margin: remy(36) 0;
		border: thin solid $gray-200;
	}
}

// 白底區塊
.white-box {
	overflow: hidden;
	padding: remy(map-get($container-gutters, xs));
	background-color: $white;
	box-shadow: 2px 2px 30px 0 rgba($dark, 0.04);

	&:not(:last-child) {
		margin-bottom: 1.5rem;

		@include bp-lg {
			margin-bottom: 3rem;
		}
	}

	&_head {
		position: relative;
		padding: 1.25rem;
		line-height: 137.5%;
		text-align: center;
		background-color: $white;
		box-shadow: -2px 0 20px 0 rgba($dark, 0.06);
	}

	@include bp-lg {
		padding: 3.125rem 5rem;
	}

	@include bp-xl {
		padding: 3.75rem 7.5rem;
	}

	&--gray100 {
		background-color: $gray-100;
	}

	&--head {
	}
}

// 表單區塊
.quest-box {
	max-width: 43.75rem;

	&.indent-box {
		max-width: 41.75rem;
	}
}

.indent-box {
	margin-top: 0.5rem;
	margin-left: 2.25rem;

	.quest-box {
		max-width: 41.5rem;
	}
}

.subquest-box {
	margin-top: 2.25rem;

	&:first-child {
		margin-top: 3.75rem;
	}
}

.subquest-limit {
	max-width: 56.25rem;
}

// --------------------------------------------------------

.js-field-ctrl,
.js-field-expand {
	&:not(.is-show) ~ [data-field-hide] {
		display: none;
	}

	// Q17
	&:not(.is-show) [data-field-hide] {
		display: none;
	}
}

// --------------------------------------------------------
// 文字溢行/文字點點點
%clamp {
	--line-height: 1.5;

	position: relative;
	display: block;
	display: -webkit-box;
	overflow: hidden;
	max-width: 100%;
	line-height: var(--line-height);
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}

.clamp {
	&,
	&-1 {
		@extend %clamp;

		display: block;
		max-width: 100%;
		white-space: nowrap;
	}

	@function limit-height-get($i, $line-height) {
		@return calc(1em * #{$line-height} * #{$i});
	}

	@for $i from 2 through 6 {
		&-#{$i} {
			@extend %clamp;

			-webkit-line-clamp: $i;
			max-height: limit-height-get($i, 1.6);
			max-height: limit-height-get($i, var(--line-height));
		}
	}
}

// --------------------------------------------------------

/* object-fit */

// Fix the dimensions of an image and scale
// Default size: 1:1
// How to change size
// $imgWidth: // img width
// $imgHeight: // img height
// .object-fit:before{padding-top: round($imgHeight / $imgWidth * 100%)}
// round: round the number rounding
%object-fit-standard {
	position: relative;
	display: block; // Fixed <picture> bug
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		padding-top: 100%;
	}

	img,
	.picture {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100%;
		object-position: center center;
		background-position: center center;
	}
}

%object-fit-cover {
	img,
	.picture {
		object-fit: cover;
		background-size: cover;
	}
}

%object-fit-contain {
	background-position: center center;
	background-size: contain;

	img,
	.picture {
		object-fit: contain;
		background-size: contain;
	}
}

.object-fit {
	@extend %object-fit-standard;
	@extend %object-fit-cover;

	// 沒有內層(before)
	// &--wrap {
	// 	@extend %object-fit-standard;
	// 	@extend %object-fit-cover;
	// 	&::before {
	// 		display: none;
	// 	}
	// }
	// 應對不同比例的照片
	&--contain {
		@extend %object-fit-standard;
		@extend %object-fit-contain;
	}
}

// --------------------------------------------------------

.lazy {
	opacity: 0;
	transition: $ms-faster opacity;

	&.loaded {
		opacity: 1;
	}
}

// --------------------------------------------------------

.d-none {
	display: none !important;
}

.d-md-block {
	@include bp-md {
		display: block !important;
	}
}

.d-md-none {
	display: block;

	@include bp-md {
		display: none !important;
	}
}

.d-xl-block {
	@include bp-xl {
		display: block !important;
	}
}

.d-xl-none {
	display: block !important;

	@include bp-xl {
		display: none !important;
	}
}

.show-for-sr {
	position: absolute !important;
	overflow: hidden !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	white-space: nowrap !important;
	border: 0 !important;
	clip: rect(0, 0, 0, 0) !important;
}

.is-locked {
	cursor: no-drop !important;
	color: $gray-200 !important;
	box-shadow: none !important;
}

// --------------------------------------------------------

.dividing {
	position: relative;
	width: 0.125rem;
	height: 5rem;
	background-color: $primary;
	transform: rotate(40deg);
	pointer-events: none;

	@include bp-lg {
		height: 9.375rem;
	}

	@include bp-xl {
		width: 0.1875rem;
		height: 11.25rem;
	}

	@include bp-max {
		width: 0.25rem;
		height: 17.625rem;
	}
}

// --------------------------------------------------------
// flex
.d-flex {
	display: flex;
}

.flex-nowrap {
	flex-wrap: nowrap;
}

.position-relative {
	position: relative;
}

.w-100 {
	width: 100%;
}

.w-150 {
	width: 150%;
}

// ----------------------------------------------------------
.js-fixed-nav {
	&.is-fixed {
		[data-fixed-nav] {
			position: fixed;
			z-index: 100;
			right: 0;
			left: 0;
			background-color: $white;
			box-shadow: $box-shadow;
		}
	}
}
