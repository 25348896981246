.modal {
	$root: &;

	position: fixed;
	z-index: -1;
	inset: 0;
	display: block;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: 0.3s opacity, 0.3s z-index 0s;
	pointer-events: none;

	// open / close
	&.is-active {
		z-index: 9999;
		opacity: 1;
		transition: 0.3s opacity;
		pointer-events: all;

		#{$root}_inner {
			transform: translate(-50%, -50%) translateZ(0) scale(1);
		}
	}

	// Inner
	&_inner {
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;
		padding: 1.5rem;
		width: calc(100% - 40px);
		max-width: 43.75rem;
		background-color: $white;
		border-radius: 0.125rem;
		transform: translate(-50%, -50%) translateZ(0) scale(0.95);
		transition: transform $ms-fast;

		@include bp-md {
			padding: 3rem 3.75rem;
		}

		// 尺寸
		// &.sz-sm {}

		&.sz-lg {
			max-width: 73.75rem;

			@include bp-xl {
				padding-right: 8.625rem;
				padding-left: 8.625rem;
			}
		}
	}

	// Cont
	&_title {
		margin-bottom: 1rem;
		padding: 0 1.5rem;

		@include bp-md {
			margin-bottom: 1.5rem;
		}
	}

	&_note {
		color: $gray-500;
	}

	.btn-group {
		margin: 1.5rem auto 0;
		max-width: 28.75rem;

		@include bp-md {
			margin-top: 2rem;
			gap: 1.25rem;
		}
	}

	// Close
	&_close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		padding: 0;
		font-size: 2.25rem;
		background-color: transparent;
		border: 0;
		transition: $ms-fast ease-out;

		@include bp-lg {
			top: 1.5rem;
			right: 1.5rem;
			font-size: 2.5rem;

			&:hover {
				color: $primary;
			}
		}

		.be-icon {
			display: flex;
		}
	}

	// Overlay
	&_overlay {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($black, 0.6);
	}

	.scroll-box {
		@include custom-scroll-bar;

		overflow-x: hidden;
		overflow-y: auto;
		max-height: 60vh;

		@include bp-lg {
			max-height: 50vh;
		}
	}
}
