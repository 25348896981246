* {
	&,
	&::before,
	&::after {
		box-sizing: border-box;
	}
}

html {
	font-size: convert($font-size);
	font-family: 'Noto Sans TC', serif;
	font-weight: 400;
	line-height: (24 / 16);
	color: $text-color;
	text-size-adjust: 100%;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	-webkit-tap-highlight-color: black(0);
	-webkit-text-size-adjust: 100%;

	@include font-smoothing;
}

:root {
	@include tab-size(4);

	@each $key, $value in $vi-colours {
		--vi-#{$key}: #{$value};
	}

	@each $key, $value in $status-colours {
		--is-#{$key}: #{$value};
	}
}

@at-root {
	@viewport {
		width: device-width;
	}
}

body {
	margin: 0;
}

[tabindex='-1']:focus {
	outline: 0;
}

blockquote,
caption,
dd,
div,
dt,
h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
th,
td {
	word-wrap: break-word;
	color: inherit;
}

.firefox pre {
	white-space: pre-line !important;
	-moz-hyphens: auto;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
	display: block;
}

mark,
output {
	display: inline-block;
}

summary {
	display: block;
	cursor: pointer;
}

audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: baseline;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

img {
	max-width: 100%;
	height: auto;
	border: 0;
	vertical-align: baseline;
}

svg {
	overflow: hidden;
	vertical-align: middle;
}

figure {
	margin: 0 0 remy($font-size);
}

iframe {
	border: none;
}

xmp,
plaintext,
listing {
	font-family: inherit;
	white-space: normal;
}

[hidden],
template {
	display: none;
}

fieldset {
	padding: 0;
}

// Typography
// ----------------------------------------------------------------------
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0;
}

ol,
ul,
dl {
	margin-top: 0.625em;
	margin-bottom: 1.25em;

	@include head-tail-clear;
}

a {
	text-decoration: none;
	color: inherit;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;

	&:active,
	&:hover,
	&:focus {
		text-decoration: none;
		outline: 0;
	}
}

abbr,
acronym {
	font-variant: normal;
	cursor: help;

	&[title] {
		border-bottom: 0;
	}
}

address,
cite,
dfn,
i,
u {
	font-style: normal;
	text-decoration: none;
}

[lang^='zh'] {
	em {
		font-style: normal;
	}
}

blockquote,
q {
	quotes: none;
}

hr {
	height: 0;
}

pre {
	overflow: auto;
}

b,
strong {
	font-weight: 600;
}

small {
	font-size: 87.5%;
}

sup,
sub {
	font-size: 75%;
	line-height: 0;
}

// Table
// ----------------------------------------------------------------------
table {
	width: 100%;
	text-align: left;
	color: inherit;
	border-color: transparent;
	border-collapse: collapse;
	border-spacing: 0;
	font: inherit;
}

table,
td,
th {
	table-layout: auto;
}

td,
th {
	padding: 0;
	outline: 0;
	vertical-align: inherit;
}

caption,
center,
th,
td {
	text-align: inherit;
}

// Forms
// ----------------------------------------------------------------------
label {
	display: inline-block;
}

button,
input,
optgroup,
select,
textarea {
	margin: 0;
	color: inherit;
	font: inherit;
}

button,
input {
	overflow: visible;
}

input,
select {
	vertical-align: middle;
}

button,
select {
	text-transform: none;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
	outline: 0;

	@include appearance(button);

	cursor: pointer;

	&:-moz-focusring {
		outline: 0;
	}
}

[disabled],
.is\:off {
	@extend %disabled;
}

/* stylelint-disable-next-line no-duplicate-selectors */
button,
input {
	&::-moz-focus-inner {
		padding: 0;
		border: 0;
	}
}

input {
	line-height: normal;

	&[type='checkbox'],
	&[type='radio'] {
		padding: 0;
	}

	&[type='number'] {
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			height: auto;
		}
	}

	&[type='search'] {
		@include appearance(textfield);

		&::-webkit-search-cancel-button,
		&::-webkit-search-decoration {
			@include appearance(none);
		}
	}

	&[type='date'],
	&[type='time'],
	&[type='datetime-local'],
	&[type='month'] {
		@include appearance(listbox);
	}
}

::-webkit-file-upload-button {
	font: inherit;

	@include appearance(button);
}

legend {
	padding: 0;
	border: none;
}

textarea {
	overflow: auto;
	resize: vertical;
}

:focus {
	outline: 0;
}
